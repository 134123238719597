import * as React from "react";

import { Button } from "reactstrap";
import { Link } from "@mui/icons-material";
import { StringUtil } from "../../utils/string.util";
import { TreeItem } from "./hierarchy";
import { getI18nLocaleString } from "../../i18n";
import namespaceList from "../../i18n/namespaceList";

interface HierarchyWidget {
    children: Array<HierarchyWidget | { item: HierarchyWidget }>;
    options: any;
    type: string;
    updatedAt: string;
    createdAt: string;
    _id: string;
}

function updateLinkedWidgetIds(items: Array<HierarchyWidget | { item: HierarchyWidget }>, linkedWidgetIds: string[], targetWidgetId: string) {
    for (const hierarchyWidget of items) {
        const item: HierarchyWidget = "item" in hierarchyWidget ? hierarchyWidget.item : hierarchyWidget;

        const itemWidgetId = item.options.customWidgetId || item._id;
        // link widgets
        if (linkedWidgetIds.some((linkedWidgetId) => linkedWidgetId === itemWidgetId)) {
            let itemLinkedWidgetIds = item.options.linkedWidgetIds;
            if (!itemLinkedWidgetIds) {
                itemLinkedWidgetIds = [];
                item.options.linkedWidgetIds = itemLinkedWidgetIds;
            }
            if (!itemLinkedWidgetIds.some((itemLinkedWidgetId: string) => itemLinkedWidgetId === targetWidgetId)) {
                itemLinkedWidgetIds.push(targetWidgetId);
            }
        }
        // Unlink widgets
        if (item.options?.linkedWidgetIds?.some((linkedWidgetId: string) => linkedWidgetId === targetWidgetId) && !linkedWidgetIds.some((linkedWidgetId) => linkedWidgetId === itemWidgetId)) {
            item.options.linkedWidgetIds = item.options.linkedWidgetIds.filter((linkedWidgetId: string) => linkedWidgetId !== targetWidgetId);
        }
        if (item.children?.length) {
            updateLinkedWidgetIds(item.children, linkedWidgetIds, targetWidgetId);
        }
    }
}

/**
 * This method will make sure when linking widgetA to widgetB, that widgetB will also be linked to widgetA.
 * And when unlinking widgetA from widgetB, it will also unlink widgetB from widgetA.
 */
export function applyLinkedWidgetsOfEditedItem(editedItem: any, treeData0: Array<TreeItem<any>> | null) {
    if (editedItem.options?.linkedWidgetIds) {
        let targetWidgetId = editedItem.options.customWidgetId || editedItem._id;
        if (!targetWidgetId) {
            // If the widget has not been saved yet there is no _id configured. In that case we create a random customId
            targetWidgetId = editedItem.options.customWidgetId = `${editedItem.type}-${StringUtil.generateRandomString("xxxxxxxx-xxxx")}`;
        }
        const allLinkedWidgetIds = editedItem.options.linkedWidgetIds;

        for (const item of treeData0 || []) {
            if (item.children?.length) {
                updateLinkedWidgetIds(item.children as Array<HierarchyWidget | { item: HierarchyWidget }>, allLinkedWidgetIds, targetWidgetId);
            }
        }
    }
}

function getLinkedWidgetIdClassName(widgetId: string) {
    return StringUtil.convertToDocumentQuerySelectable(`linked-widget-${widgetId}`) || "";
}

export function LinkedToWidgetIndicator(props: { node: any }) {
    const { node } = props;

    const currentWidgetId = node?.item?.options.customWidgetId || node?.item?._id;
    const linkedWidgetIds = node?.item?.options?.linkedWidgetIds;

    const toggleLinkedWidgetHighlight = (addHighlight: boolean) => {
        const classListMethod = addHighlight ? "add" : "remove";
        linkedWidgetIds.forEach((linkedWidgetId: string) => {
            document.querySelector(`.${getLinkedWidgetIdClassName(linkedWidgetId) || ""}`)?.classList[classListMethod]("highlight-linked-widget");
        });
    };

    const handleMouseEnter = () => toggleLinkedWidgetHighlight(true);
    const handleMouseLeave = () => toggleLinkedWidgetHighlight(false);

    return (
        <Button
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => undefined}
            outline
            className={`hierarchy btn-template ${getLinkedWidgetIdClassName(currentWidgetId)}`}
            size="sm"
            color="info"
        >
            <Link />
            <span className="mcms-tooltip top">
                {getI18nLocaleString(namespaceList.admin, "linkedToWidgets") + " " + linkedWidgetIds?.filter((linkedWidgetId: string) => !!linkedWidgetId?.trim())?.join(", ")}
            </span>
        </Button>
    );
}
