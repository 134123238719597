import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as moment from "moment";

import { Button, Input, Label } from "reactstrap";
import { CMSAware, globalApiContext } from "../../containers/CmsProvider";
import { ChromePicker, CirclePicker } from "react-color";
import { InputSpecColor, InputSpecRadio, InputSpecSelect, InputSpecSimple, WidgetOptions } from "../../form-specs";
import { Site, SiteApi, ThemeApi, WithId } from "@maxxton/cms-api";
import { getI18nLocaleString, getI18nLocaleStringFromParams, wrapProps } from "../../i18n";

import { CMSProvidedProperties } from "../../containers/cmsProvider.types";
import { DATE_FORMAT } from "../../utils/constants";
import { GenericInputProps } from "./input.types";
import { PermissionProps } from "../../containers/PermissionsProvider";
import Select from "react-select";
import { SelectOption } from "../../form-specs/formSpec.types";
import { SingleDatePicker } from "react-dates";
import { cancelable } from "../../promise";
import { debounce } from "lodash";
import { getCMSOptions } from "../../plugins/settings";
import { isEqual } from "../utils";
import namespaceList from "../../i18n/namespaceList";

const defaultValueByType = {
    text: "",
    directText: "",
    paragraph: "",
    seo: "",
    icons: "",
    animate: "",
    colorFromSelect: "",
    range: 0,
    email: "",
    password: "",
    textarea: "",
    select: "$undefined",
    checkbox: false,
    // eslint-disable-next-line id-blacklist
    number: 0,
    button: "Button",
    date: "",
    datetime: "",
    radio: "",
    radioImage: "",
    color: "#000000",
    dynamicData: [],
    file: "",
};

interface SimpleInputState<E, P extends keyof E> {
    isFetching: boolean;
    optionList?: Array<SelectOption<E[P]>>;
    radioOptions?: Array<SelectOption<E[P]>> | string[];
    item?: E;
    colorValue?: string;
    themeId?: string;
    defaultLocaleCode?: string;
    inpTextValue: string;
    inpTextBool: boolean;
    prevStatTxt: any;
    showColorPicker: boolean;
    cmsColors: string[];
    // eslint-disable-next-line @typescript-eslint/ban-types
    theme: object;
    validate?: (id: string, isValid: boolean) => void;
    visible: boolean;
    dateHasFocus: boolean;
    singleDatePickerDate?: moment.Moment | null;
    dateTime?: moment.Moment | null;
    bankAccountNumber: string;
    currentColor?: string;
}

function isSelectSpec<E, P extends keyof E>(spec: InputSpecSimple<E, P>): spec is InputSpecSelect<E, P> {
    return spec.type === "select";
}

function isRadioSpec<E, P extends keyof E>(spec: InputSpecSimple<E, P>): spec is InputSpecRadio<E, P> {
    return spec.type === "radio" || spec.type === "radioImage" || spec.type === "radioForms";
}

function isTextInputSpec<E, P extends keyof E>(spec: InputSpecSimple<E, P>): boolean {
    return spec.type === "text";
}

function isDirectTextInputSpec<E, P extends keyof E>(spec: InputSpecSimple<E, P>): boolean {
    return spec.type === "directText";
}

function isTextAreaInputSpec<E, P extends keyof E>(spec: InputSpecSimple<E, P>): boolean {
    return spec.type === "textarea";
}

function isDualColorInputSpec<E, P extends keyof E>(spec: InputSpecSimple<E, P>): boolean {
    return spec.type === "dual-color";
}

type SimpleInputBaseProps<E, P extends keyof E> = GenericInputProps<E, P, InputSpecSimple<E, P>> & PermissionProps & CMSProvidedProperties;

export class SimpleInputBase<E, P extends keyof E> extends React.PureComponent<SimpleInputBaseProps<E, P>, SimpleInputState<E, P>> {
    private EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    private TELEPHONE_NUMBER_REGEX = /^[0-9-]+$/;
    private PASSWORD_REGEX = /^.{12,64}$/;
    constructor(props: SimpleInputBaseProps<E, P>) {
        super(props);
        this.state = {
            isFetching: false,
            inpTextValue: "",
            inpTextBool: false,
            prevStatTxt: undefined,
            showColorPicker: false,
            cmsColors: [],
            theme: {},
            visible: false,
            dateHasFocus: false,
            bankAccountNumber: "",
            currentColor: isDualColorInputSpec(props.spec) ? String(props.value) : "",
        };
    }
    // Function to update the range slider's selected portion color
    private updateSliderBackground = (slider: HTMLInputElement) => {
        const min = Number(slider.min);
        const max = Number(slider.max);
        const value = Number(slider.value);
        const percentage = min === max ? 100 : ((value - min) / (max - min)) * 100;

        const gradient = `linear-gradient(to right, #3f51b5 ${percentage}%, #d3d3d3 ${percentage}%)`;
        if (slider.style.background !== gradient) {
            slider.style.background = gradient;
        }
    };

    public componentDidMount() {
        const { spec, item, context, root, site, value } = this.props;
        const apiContext = context || globalApiContext();
        const rangeSliders = document.querySelectorAll(".range-slider-input") as NodeListOf<HTMLInputElement>;
        rangeSliders.forEach((rangeSlider) => {
            this.updateSliderBackground(rangeSlider);
        });
        // putting below if condition as getCMSOptions is getting called
        // for each type of input field rendered by generic "simple" input component
        if (isDualColorInputSpec(spec)) {
            const [pluginOptionsProm, cancelPluginOptions] = cancelable(getCMSOptions(apiContext.cmsApi));
            this.cancelPluginOptionsObtain = cancelPluginOptions;
            pluginOptionsProm.then((cmsOption: any) => {
                if (cmsOption.theme?._id) {
                    this.setThemeColors(cmsOption.theme._id);
                }
            });
        }
        if (isSelectSpec(spec)) {
            const selectSpec: InputSpecSelect<E, P> = spec;
            if (selectSpec.optionList) {
                if (Array.isArray(selectSpec.optionList)) {
                    this.handleSelectChange(selectSpec.optionList, this.props);
                } else {
                    const [optionsProm, cancelOptions] = cancelable(selectSpec.optionList(item, selectSpec));
                    this.cancelOptions = cancelOptions;
                    this.setState(() => ({ isFetching: true }));
                    optionsProm.then((optionList: Array<SelectOption<E[P]>>) => {
                        this.setState(() => ({ isFetching: false, optionList }));
                    });
                }
            }
        } else if (isRadioSpec(spec)) {
            const radioSpec: InputSpecRadio<E, P> = spec;
            if (Array.isArray(radioSpec.radioOptions)) {
                this.setState(() => ({ radioOptions: radioSpec.radioOptions }));
            }
        }
        if (spec.variable === "friendlyUrl" && (Array.isArray(item) || "home" in item)) {
            const array = window.location.href.indexOf("/webmanager/site/edit/") ? window.location.href.split("/") : [];
            const siteId = array.length > 0 ? array[array.length - 1].replace("#", "") : "";
            if (siteId && siteId.length === 24) {
                const [siteProm, cancelSite] = cancelable<(Site & WithId) | null>(SiteApi.findById({ id: siteId, projection: { sitemap: 0 } }));
                this.cancelSiteObtain = cancelSite;
                siteProm.then((site) => {
                    this.setState({ defaultLocaleCode: site!.locale.code });
                });
            }
        }
        if (spec?.variable === "authorStructuredSeo" && !value && root?.enableDyanmicOgTags) {
            this.props.onChange(site?.name, spec);
        }
        if (spec.type === "datetime") {
            this.setState({ dateTime: value ? moment(value) : moment(new Date()) });
        }
        if (spec?.variable === "bankAccountNumber") {
            this.setState({ bankAccountNumber: value ? String(value) : "" });
        }
    }
    public componentDidUpdate(prevProps: any) {
        const { spec, value } = this.props;
        if (value !== prevProps.value) {
            const rangeSliders = document.querySelectorAll(".range-slider-input") as NodeListOf<HTMLInputElement>;
            rangeSliders.forEach((rangeSlider) => {
                this.updateSliderBackground(rangeSlider);
            });
        }
        if (isDualColorInputSpec(spec) && prevProps.value !== value) {
            const newColor = typeof value === "string" && value === "default" ? "" : String(value);
            this.setState({ currentColor: newColor });
        }
    }
    public UNSAFE_componentWillReceiveProps(nextProps: GenericInputProps<E, P, InputSpecSimple<E, P>>) {
        const { spec, context, currentLocale, root } = this.props;
        const { useAsConfirmPassword, type, variable, value, options } = spec;
        if (isSelectSpec(nextProps.spec) && Array.isArray(nextProps.spec.optionList) && !isEqual(this.state.item, nextProps.item)) {
            if (value === "1" && type === "select" && !this.props.enabled && variable === "language") {
                this.props.onChange(1, this.props.spec);
            }
            this.handleSelectChange(nextProps.spec.optionList, nextProps);
        }
        if ((useAsConfirmPassword || type === "password") && nextProps.spec.passwordVisibility !== this.state.visible) {
            this.setState({ visible: !this.state.visible });
        }
        if (type === "date") {
            const locale = options?.useCustomLocale && options?.localeForDatePicker?.split(":")[1];
            if (options?.useCustomLocale && options?.localeForDatePicker && `${currentLocale?.name || context?.currentLocale?.name}:${locale}` === options?.localeForDatePicker) {
                () => import(`moment/locale/${locale}`);
            }
        }
        if (!isEqual(nextProps.root, root) && nextProps.root[spec.variable]) {
            this.setState({ inpTextBool: false });
        }
    }

    public componentWillUnmount() {
        this.cancelOptions();
        this.cancelPluginOptionsObtain();
        this.cancelThemesObtain();
        this.cancelSiteObtain();
    }

    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const { spec, mode, enabled, label, tabLocale, item, localeCode, onChange } = this.props;
        const { optionList, isFetching, radioOptions, defaultLocaleCode, inpTextBool, inpTextValue, dateTime, dateHasFocus, bankAccountNumber } = this.state;
        const { context, currentLocale } = this.props;
        let { value } = this.props;
        let valueProp: { value: any } | { checked: boolean };
        const placeholder =
            (spec.placeholder && getI18nLocaleStringFromParams(spec.placeholder)) ||
            (spec.type === "select" ? getI18nLocaleString(namespaceList.admin, "defaultPlaceholder") : "") ||
            (spec.type === "email" ? "example@gmail.com" : "");
        if (value === undefined) {
            if (spec.default !== undefined) {
                value = spec.default;
                if (spec.options?.useAsUrlField) {
                    onChange(spec.default, spec);
                }
            } else {
                if (!(spec.type in defaultValueByType)) {
                    throw new Error(`No default value defined for ${spec.type}`);
                }
                value = (defaultValueByType as any)[spec.type];
            }
        }
        let valuePropForDate: moment.Moment | null = null;
        let valuePropForDateTime: moment.Moment | null = null;
        let selected: any = null;
        switch (spec.type) {
            case "checkbox":
                valueProp = { checked: (value as any) as boolean };
                break;
            case "icons":
                valueProp = { value: value ? (value as any).toString() : defaultValueByType.icons };
                break;
            case "date":
                valueProp = { value: value ? (value as any).toString().substr(0, 10) : defaultValueByType.date };
                valuePropForDate = value ? moment(value) : null;
                break;
            case "radioImage":
            case "radio":
                selected = radioOptions && (radioOptions as Array<SelectOption<E[P]>>).find((option) => option.value === value);
                valueProp = { value };
                break;
            case "datetime":
                if (value) {
                    valueProp = { value: moment(value).format(DATE_FORMAT.DATE_TIME_HOURS_MINUTES_SHORT) };
                    valuePropForDateTime = value ? moment(value) : null;
                } else {
                    valueProp = { value: defaultValueByType.datetime };
                }
                break;
            case "textarea":
            case "text":
                valueProp = { value: inpTextBool ? inpTextValue : spec.variable === "bankAccountNumber" && value ? "********" + bankAccountNumber.slice(-4) : value };
                break;
            case "select":
                valueProp = { value: spec?.value || value };
                break;
            default:
                valueProp = { value };
        }
        if (value === null && spec.type === "select") {
            valueProp = { value: defaultValueByType.select };
        }
        if (spec.variable === "friendlyUrl" && Array.isArray(item) && item.some((i) => i.friendlyUrl === "" || i.friendlyUrl === undefined) && this.props.friendlyUrl) {
            if (!value) {
                valueProp = {
                    value: `${localeCode === defaultLocaleCode ? "" : "/" + localeCode}${this.props.friendlyUrl}`,
                };
            }
        }
        let staticOptions = [<option key="$undefined" value="$undefined" style={{ display: "none" }} />];
        let selectHasSelection;
        if ("value" in valueProp && spec.type === "select" && spec.enableReadOnly && !!valueProp) {
            selectHasSelection = valueProp?.value !== "$undefined";
        }
        if (!spec.default) {
            staticOptions = [
                <option key={"$undefined"} value={"$undefined"} disabled={typeof spec.required === "string" ? spec.required === "true" : spec.required}>
                    {placeholder}
                </option>,
            ];
        }
        if (spec.type === "paragraph") {
            return <p>{label}</p>;
        } else if (spec.type === "seo") {
            if (value !== ("" as any) && value !== undefined) {
                const charCount = ((value as any).length / (spec.characterCounts as number)) * 100;
                const seoHealth = charCount > 100 ? "count-danger" : charCount < 70 ? "count-warning" : "";
                const renderSeoHealthLabel = (charCount: number) => {
                    if (charCount > 100) {
                        return <span className="seo-helper">{getI18nLocaleString(namespaceList.admin, "seoHealthMaximum")}</span>;
                    }
                    if (charCount > 70 && charCount < 84) {
                        return <span className="seo-helper">{getI18nLocaleString(namespaceList.admin, "seoHealthDefault")}</span>;
                    }
                    if (charCount < 30) {
                        return <span className="seo-helper">{getI18nLocaleString(namespaceList.admin, "seoHealthMinimum")}</span>;
                    }
                    if (charCount > 84) {
                        return <FontAwesome name="check"></FontAwesome>;
                    }
                };
                return (
                    <div className={`${spec.variable?.toString()}-inner`}>
                        <div>{(value as unknown) as string}</div>
                        <div className={`seo-counter ${seoHealth}`}>
                            <span>{(spec.characterCounts as number) - (value as any).length}</span>
                            {renderSeoHealthLabel(charCount)}
                        </div>
                    </div>
                );
            }
            return null;
        } else if (spec.type === "icons") {
            return <FontAwesome name={`${this.props.value || ""}`} size="4x" />;
        } else if (spec.type === "animate") {
            return <h3 className={`${this.props.value} animated`}>{getI18nLocaleString(namespaceList.admin, "animatedPreview")}</h3>;
        } else if (spec.type === "colorFromSelect") {
            return <div className={`${this.props.value}`}>{getI18nLocaleString(namespaceList.admin, "Color Preview")}</div>;
        } else if (spec.type === "radio") {
            return (
                <div className="radio-wrapper">
                    {radioOptions &&
                        (radioOptions as Array<SelectOption<E[P]>>).map((option, ind) => (
                            <div key={ind}>
                                <Input
                                    id={(option as any).value}
                                    key={(option as any).value}
                                    readOnly={mode === "readonly"}
                                    disabled={!enabled || isFetching}
                                    name={spec.variable as string}
                                    required
                                    // eslint-disable-next-line react/jsx-no-bind
                                    onBlur={(event) => this.validateInput(event.target.value)}
                                    type={spec.type as any}
                                    onChange={this.handleInputChange}
                                    checked={selected ? selected.value === (option as any).value : false}
                                />
                                <label htmlFor={(option as any).value}>{getI18nLocaleStringFromParams((option as any).label)}</label>
                            </div>
                        ))}
                </div>
            );
        } else if (spec.type === "radioImage") {
            return (
                <div className="radio-wrapper row">
                    {radioOptions &&
                        (radioOptions as Array<SelectOption<E[P]>>)?.map((option) => (
                            <div className="radio-wrapper__item col-sm-6 col-lg-4" key={(option as any).value}>
                                <Input
                                    className="radio-wrapper__item--value"
                                    id={(option as any).value}
                                    key={(option as any).value}
                                    readOnly={mode === "readonly"}
                                    disabled={!enabled || isFetching}
                                    name={spec.variable as string}
                                    required
                                    type={spec.type as any}
                                    onChange={this.handleInputChange}
                                    checked={selected ? selected.value === (option as any).value : false}
                                    hidden={true}
                                />
                                {option.image && (
                                    <div
                                        className="radio-wrapper__image-select"
                                        style={{ border: selected && selected.value === (option as any).value ? "2px solid rgb(0, 105, 217)" : "none", width: "55px" }}
                                    >
                                        <img loading="lazy" width="50px" height="50px" src={(option as any).image} />
                                    </div>
                                )}
                                {option.element && (
                                    // eslint-disable-next-line max-len
                                    <div
                                        className={`radio-wrapper__item-select ${selected && selected.value === (option as any).value ? "selected" : ""}`}
                                        onClick={this.handleImageClick.bind(this, (option as any).value)}
                                    >
                                        <div className="radio-wrapper__item-select-svg">{typeof option.element === "function" ? option.element() : option.element}</div>
                                        <div className="radio-wrapper__item-select-text">{getI18nLocaleStringFromParams(option.label)}</div>
                                    </div>
                                )}
                            </div>
                        ))}
                </div>
            );
        } else if (spec.type === "radioForms") {
            return (
                <div className="radio-wrapper">
                    {radioOptions &&
                        (radioOptions as string[]).map((option) => (
                            <div key={option}>
                                <Input
                                    id={option}
                                    key={option}
                                    readOnly={mode === "readonly"}
                                    disabled={!enabled || isFetching}
                                    name={spec.variable as string}
                                    required
                                    type={"radio"}
                                    onChange={this.handleInputChange}
                                    {...valueProp}
                                />
                                <label htmlFor={option}>{option}</label>
                            </div>
                        ))}
                </div>
            );
        } else if (spec.type === "file") {
            return (
                <div className="attachment-wrapper">
                    <div className="attachment-wrapper__item">
                        <Input
                            className="attachment-wrapper__item--value"
                            id={spec.options?.fieldId}
                            accept="image/jpeg, image/jpg, application/pdf"
                            name="document"
                            type="file"
                            onChange={this.handleInputChange}
                        />
                    </div>
                </div>
            );
        } else if (spec.type === "date") {
            const locale = spec.options?.useCustomLocale && spec.options?.localeForDatePicker?.split(":")[1];
            if (spec.options?.useCustomLocale && spec.options?.localeForDatePicker && `${currentLocale?.name || context?.currentLocale.name}:${locale}` === spec.options?.localeForDatePicker) {
                moment.locale(locale);
            }
            return (
                <div className={dateHasFocus ? "focused SingleDatePicker_wrap" : "SingleDatePicker_wrap"}>
                    <SingleDatePicker
                        date={valuePropForDate}
                        id={spec.variable as string}
                        disabled={!enabled || isFetching || mode === "readonly"}
                        required={typeof spec.required === "string" ? spec.required === "true" : spec.required}
                        onDateChange={(day) => this.handleDateChange(day, true)}
                        displayFormat={DATE_FORMAT.DISPLAY}
                        placeholder={`${getI18nLocaleString(namespaceList.admin, "inputDate")}`}
                        focused={dateHasFocus}
                        initialVisibleMonth={() => (valuePropForDate ? valuePropForDate : spec?.maxDate ? moment(spec?.maxDate) : moment())}
                        onFocusChange={this.handleDateFocusChange}
                        numberOfMonths={1}
                        isOutsideRange={(day) => this.isOutsideRange(day, spec.minDate, spec.maxDate)}
                        renderMonthElement={this.renderMonthElementForDate}
                        hideKeyboardShortcutsPanel={true}
                        navPrev={<button hidden={true}></button>}
                        navNext={<button hidden={true}></button>}
                        customInputIcon={valuePropForDate && <FontAwesome name="close" className="close-icon" onClick={() => this.handleClearDate()} />}
                    />
                </div>
            );
        } else if (spec.type === "datetime") {
            return (
                <div className={dateHasFocus ? "focused SingleDatePicker_wrap" : "SingleDatePicker_wrap"}>
                    <SingleDatePicker
                        id={spec.variable as string}
                        date={dateTime || valuePropForDateTime}
                        onDateChange={this.handleDateTimeChange}
                        focused={dateHasFocus}
                        onFocusChange={this.handleDateFocusChange}
                        numberOfMonths={1}
                        isOutsideRange={() => false}
                        renderMonthElement={this.renderMonthElementForDateTime}
                        displayFormat={DATE_FORMAT.DATE_TIME_HOURS_MINUTES_SHORT}
                        customInputIcon={valuePropForDateTime && <FontAwesome name="close" className="close-icon" onClick={() => this.handleClearDate(true)} />}
                    />
                </div>
            );
        } else if (spec.type === "range") {
            return (
                <div className="range-slider-container">
                    <Input
                        id={spec.variable as string}
                        readOnly={mode === "readonly"}
                        className="range-slider-input"
                        disabled={!enabled || isFetching}
                        name={spec.variable as string}
                        required
                        type="range"
                        onChange={this.handleInputChange}
                        {...valueProp}
                        min={0}
                        max={100}
                        step={spec.step || 1}
                        defaultValue={0}
                    />
                    <Input
                        id={spec.variable as string}
                        className="number-input"
                        readOnly={mode === "readonly"}
                        disabled={!enabled || isFetching}
                        name={spec.variable as string}
                        required
                        type="number"
                        onChange={this.handleInputChange}
                        {...valueProp}
                        min={0}
                        max={100}
                        step={spec.step || 1}
                        defaultValue={0}
                    />
                </div>
            );
        } else if (spec.type === "button") {
            return (
                <Button
                    id={spec.variable as string}
                    disabled={!enabled || isFetching}
                    name={spec.variable as string}
                    type={spec.type as any}
                    onClick={this.handleButtonClick}
                    color="primary"
                    className="publish"
                >
                    {label}
                </Button>
            );
        } else if (isDualColorInputSpec(spec)) {
            let colors = (value as any) as string;
            if (colors.indexOf("theme-") > -1 && colors.indexOf("rgba") === -1 && colors.indexOf("#") === -1) {
                let slicedColor = "";
                const newColArr = colors.split("-");
                let newOpacity;
                let newColor: string;
                if (newColArr.indexOf("opacity") > -1) {
                    newOpacity = newColArr[newColArr.length - 1];
                    newColor = newColArr[2];
                } else {
                    newColor = newColArr[1];
                }
                Object.keys(this.state.theme).forEach((key: string) => {
                    if (newColor === key) {
                        slicedColor = (this.state.theme as any)[key];
                    }
                });
                colors = this.hexToRGBA(slicedColor, newOpacity) as any;
            }
            return (
                <div className="color-palette">
                    <div onClick={this.handleShowColorPicker} className={"color-palette-input"} style={{ height: "2.8rem", backgroundColor: this.state.currentColor || "" }} />
                    {this.state.showColorPicker && (
                        <div className="color-picker">
                            <div className="position-relative">
                                <div className="close-color-picker" style={{ position: "fixed", top: "0px", right: "0px", bottom: "0px", left: "0px" }} onClick={this.handleShowColorPicker} />
                                <ChromePicker color={colors} onChange={this.handleDualColorChangeComplete} />
                                <div className="theme-color-picker-wrapper">
                                    <div className="theme-color-picker">
                                        <Label>{getI18nLocaleString(namespaceList.admin, "themeColors")}</Label>
                                        {/* CirclePicker will listen the cms-setting theme.*/}
                                        <CirclePicker colors={this.state.cmsColors} circleSize={28} circleSpacing={15} onChange={this.handleDualColorChangeComplete} />
                                    </div>
                                    <div className="reset-button">
                                        <Button onClick={this.resetColor}>{getI18nLocaleString(namespaceList.admin, "resetColorToDefault")}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            );
        } else if (spec.type === "color") {
            return (
                <div>
                    <div
                        // eslint-disable-next-line max-len
                        className={`color-picker-input ${value && ((value as any).indexOf("rgba") === -1 || (value as any).indexOf("#") === -1) ? value : ""}`}
                        style={{ height: "2.8rem", background: value && ((value as any).indexOf("rgba") > -1 || (value as any).indexOf("#") > -1) ? (value as any) : undefined }}
                        onClick={this.handleShowColorPicker}
                    />
                    <div>
                        {this.state.showColorPicker && (
                            <div>
                                <div className="close-color-picker-div" style={{ position: "fixed", top: "0px", right: "0px", bottom: "0px", left: "0px" }} onClick={this.handleShowColorPicker} />
                                <ChromePicker color={value as any} onChangeComplete={this.handleColorChangeComplete} />
                            </div>
                        )}
                    </div>
                </div>
            );
        }
        const handleOnChange = () => {
            if (isDirectTextInputSpec(spec)) {
                return this.handleDirectInputText;
            } else if (isTextInputSpec(spec) || isTextAreaInputSpec(spec)) {
                return this.handleInputText;
            }
            return this.handleInputChange;
        };

        let maxLength;
        if (spec.type === "number") {
            maxLength = (spec as any).maximumNumberLength;
        } else if (spec.type === "text" && spec.enableMaxCharacterLength) {
            maxLength = (spec as any).maxCharacterLength;
        }
        if (spec.type === "select" && (spec as InputSpecSelect<E, P>)?.useReactSelect && optionList?.length) {
            const selectSpec: InputSpecSelect<E, P> = spec as InputSpecSelect<E, P>;
            const value = this.props.value && (selectSpec.priorityCountryList ? [...selectSpec.priorityCountryList, ...optionList] : optionList).find((option) => option.value === this.props.value);
            const groupedOptions = [
                {
                    label: "PrioCountryList",
                    options: (spec as InputSpecSelect<E, P>).priorityCountryList,
                },
                {
                    label: "NonPrioCountryList",
                    options: optionList.map((option) => ({ value: option.value, label: option.label })),
                },
            ];

            const formatGroupLabel = (hiddenLabels: string[] = []) => (data: any) => (hiddenLabels.includes(data.label) ? null : <hr />);

            return (
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    value={value}
                    isDisabled={mode === "readonly" || spec.enableReadOnly}
                    required={typeof spec.required === "string" ? spec.required === "true" : spec.required}
                    options={groupedOptions}
                    isSearchable={true}
                    isClearable={true}
                    multi={false}
                    onChange={this.handleReactSelectOnChange}
                    placeholder={getI18nLocaleStringFromParams(placeholder)}
                    inputId={spec.variable as string}
                    formatGroupLabel={formatGroupLabel(["PrioCountryList"])}
                />
            );
        }
        return (
            <Input
                id={spec.type === "checkbox" && tabLocale ? String(spec.variable) + tabLocale : (spec.variable as string)}
                readOnly={mode === "readonly" || spec.enableReadOnly}
                disabled={!enabled || isFetching || selectHasSelection}
                name={spec.variable as string}
                required={typeof spec.required === "string" ? spec.required === "true" : spec.required}
                min={spec.type === "number" ? (spec as any).minimumNumberRange : undefined}
                max={spec.type === "number" ? (spec as any).maximumNumberRange : undefined}
                minLength={spec.type === "number" ? (spec as any).minimumNumberLength : undefined}
                maxLength={maxLength}
                type={
                    spec.useAsOldPassword || spec.useAsConfirmPassword || spec.type === "password"
                        ? this.state.visible
                            ? "text"
                            : "password"
                        : spec.type === "number" &&
                          (spec?.options?.dynamicFieldId === "privatephone" ||
                              spec?.options?.dynamicFieldId === "privatePhone" ||
                              spec?.options?.dynamicFieldId === "mobilephone" ||
                              spec?.options?.dynamicFieldId === "mobilePhone")
                        ? "tel"
                        : (spec.type as any)
                }
                onChange={handleOnChange()}
                onFocus={this.handleOnFocus}
                // eslint-disable-next-line react/jsx-no-bind
                onBlur={(event) => this.validateInput(event.target.value)}
                {...valueProp}
                className={
                    spec.type === "checkbox"
                        ? "cmn-toggle cmn-toggle-round"
                        : spec.classname && spec.type === "select"
                        ? spec.classname(item)
                        : spec.variable === "bankAccountNumber" && !bankAccountNumber
                        ? "empty-account-number"
                        : ""
                }
                placeholder={getI18nLocaleStringFromParams(placeholder)}
            >
                {optionList &&
                    staticOptions.concat(
                        optionList.map(({ label: optionLabel, value: optionVal, key: optionKey }, index: number) => (
                            <option key={optionKey ?? (optionVal != null ? "" + optionVal : index)} value={"" + optionVal}>
                                {typeof optionLabel === "function" ? (optionLabel as any)() : getI18nLocaleStringFromParams(optionLabel)}
                            </option>
                        ))
                    )}
            </Input>
        );
    }

    private handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        const { spec } = this.props;
        if (isTextInputSpec(spec) && spec.variable === "bankAccountNumber") {
            event.target.value = this.state.bankAccountNumber;
        }
    };

    private validateInput = (value: string, inpTextBoolOverride?: boolean) => {
        const { spec, validate } = this.props;
        const isTelephoneField =
            spec?.options?.dynamicFieldId === "privatephone" ||
            spec?.options?.dynamicFieldId === "privatePhone" ||
            spec?.options?.dynamicFieldId === "mobilephone" ||
            spec?.options?.dynamicFieldId === "mobilePhone";
        if (validate) {
            if (spec.variable === "email") {
                validate(spec.variable as string, spec.required ? this.EMAIL_REGEX.test(value) : true);
            } else if (spec.type === "password") {
                validate(spec.variable as string, spec.required || value.length ? (!spec.disableValidation ? this.PASSWORD_REGEX.test(value) : !!value) : true);
            } else if (spec.type === "number" && isTelephoneField) {
                validate(spec.variable as string, isTelephoneField ? this.TELEPHONE_NUMBER_REGEX.test(value) : true);
            } else {
                switch (spec.type) {
                    case "email":
                        validate(spec.variable as string, spec.required ? this.EMAIL_REGEX.test(value) : true);
                        break;
                    default:
                        validate(spec.variable as string, spec.required ? !!value.trim() : true);
                }
            }
        }
        if (isTextInputSpec(spec) || isDirectTextInputSpec(spec) || isTextAreaInputSpec(spec)) {
            if (spec.variable === "bankAccountNumber" && !value.includes("*")) {
                this.setState({ bankAccountNumber: value });
            }
            this.handleBlur(inpTextBoolOverride);
        }
    };

    // If there is spec.minDate or spec.maxDate, use a custom validation. These values are set in date.ts (in the async toInputSpec method).
    private validateDate = (enteredDate: moment.Moment) => {
        const { spec, validate } = this.props;
        let enteredDateIsValid = true;

        if (validate) {
            if (spec.maxDate) {
                const maxDate: moment.Moment = moment(spec.maxDate, DATE_FORMAT.MXTS);
                enteredDateIsValid = enteredDateIsValid && enteredDate.isBefore(maxDate);
            }

            if (spec.minDate) {
                const minDate: moment.Moment = moment(spec.minDate, DATE_FORMAT.MXTS);
                enteredDateIsValid = enteredDateIsValid && enteredDate.isAfter(minDate);
            }

            // If the date is not required, but the user filled in a date, it still needs to adhere to the maxDate and minDate settings
            validate(spec.variable as string, spec.required ? !!enteredDate && enteredDateIsValid : !enteredDate || enteredDateIsValid);
        }
    };

    private handleClearDate = (isDateTime?: boolean) => {
        const { spec, onChange } = this.props;
        if (isDateTime) {
            this.setState({ dateTime: null });
        } else {
            this.setState({ singleDatePickerDate: null });
        }
        onChange("", spec);
    };

    private handleSelectChange = (options: Array<SelectOption<E[P]>>, props: any) => {
        this.setState({
            item: props.item,
            optionList: (options as Array<SelectOption<E[P]>>).filter((op) => {
                if (!op.visible) {
                    return true;
                }
                return op.visible(props.item);
            }),
        });
    };

    private handleImageClick = (value: any) => {
        const { spec } = this.props;
        this.props.onChange(value, spec);
    };

    private handleButtonClick = (event: any) => {
        const { spec, alerts } = this.props;
        this.props.spec.onClick!(this.props.item, event);
        if (spec.alert && alerts) {
            alerts.push(spec.alert);
        }
    };

    private cancelOptions: () => void = () => undefined;
    private cancelPluginOptionsObtain: () => void = () => undefined;
    private cancelSiteObtain: () => void = () => undefined;
    private cancelThemesObtain: () => void = () => undefined;

    private handleDateTimeChange = (date: moment.Moment, focused?: boolean) => {
        if (date) {
            const { dateTime } = this.state;
            const newDateTime = date ? date.clone() : moment();
            newDateTime.set({
                year: date.year(),
                month: date.month(),
                date: date.date(),
            });
            if (dateTime && !focused) {
                newDateTime.set({
                    hour: +dateTime.format("hh"),
                    minute: +dateTime.format("mm"),
                });
            }
            this.setState({ dateTime: newDateTime });
            this.handleDateChange(newDateTime, undefined, true, focused);
        }
    };

    private handleDateChange = (date: any, isValidDate?: boolean, isDateTimePicker?: boolean, focused?: boolean) => {
        const { spec, onChange, validate } = this.props;
        if (date) {
            const formattedDate = date?.format(DATE_FORMAT.ELASTIC);
            if (spec?.maxDate && date?.isAfter(moment(spec?.maxDate, DATE_FORMAT.ELASTIC))) {
                return this.setState({ singleDatePickerDate: null });
            }
            if (isValidDate) {
                this.validateDate(date);
            }

            if (formattedDate !== "Invalid date") {
                if (isDateTimePicker) {
                    this.setState({ dateTime: date, dateHasFocus: !!focused });
                    onChange(date.toString(), spec);
                } else {
                    this.setState({ dateTime: date, dateHasFocus: false });
                    onChange(formattedDate, spec);
                }
            }
        } else if (validate && spec?.maxDate) {
            validate(spec.variable as string, !spec.required);
        }
    };

    private useDatePickerForBirthDate = (day: moment.Moment, widgetOptions: WidgetOptions<any> | undefined) => {
        const currentDate = moment();
        if (widgetOptions?.useForBirthDate && widgetOptions?.minimumAge) {
            const dateOfBirth = currentDate.subtract(widgetOptions?.minimumAge, "years");
            return day >= dateOfBirth;
        } else if (widgetOptions?.useForFutureDates) {
            return day < currentDate;
        }
        return false;
    };

    private isOutsideRange = (day: moment.Moment, minDate?: string, maxDate?: string) => {
        const momentMinDate = moment(minDate, DATE_FORMAT.ELASTIC); // Convert spec.minDate to moment object
        const momentMaxDate = moment(maxDate, DATE_FORMAT.ELASTIC);
        const widgetOptions = this.props.spec.options;
        const setDateRangeBasedOnConfiguration = !widgetOptions?.useforBookingsModule && ((widgetOptions?.useForBirthDate && widgetOptions?.minimumAge) || widgetOptions?.useForFutureDates);

        if (setDateRangeBasedOnConfiguration) {
            return this.useDatePickerForBirthDate(day, widgetOptions);
        }
        if (minDate && maxDate) {
            return day.isBefore(momentMinDate) || day.isAfter(momentMaxDate);
        } else if (minDate) {
            return day.isBefore(momentMinDate);
        } else if (maxDate) {
            return day.isAfter(momentMaxDate);
        }
        return false; // If neither minDate nor maxDate is defined, all dates are valid.
    };

    private handleDateFocusChange = (arg: { focused: boolean | null }): void => {
        this.setState(() => ({ dateHasFocus: !!arg.focused }));
    };

    private renderMonthElementForDate = ({
        month,
        onMonthSelect,
        onYearSelect,
    }: {
        month: moment.Moment;
        onMonthSelect: (currentMonth: moment.Moment, newMonthVal: string) => void;
        onYearSelect: (currentMonth: moment.Moment, newMonthVal: string) => void;
    }) => {
        const { spec } = this.props;
        const minBookAge = spec?.maxDate ? moment(spec?.maxDate).year() : moment().add(10, "years").year();

        const years = [];
        for (let year = minBookAge; year >= moment().year() - 80; year--) {
            years.push(
                <option value={year} key={`year-${year}`}>
                    {year}
                </option>
            );
        }
        return (
            <div className="CalendarMonth_wrap">
                <select value={month.month()} onChange={(e) => onMonthSelect(month, e.target.value)}>
                    {moment.months().map((label, value) => (
                        <option value={value} key={value}>
                            {label}
                        </option>
                    ))}
                </select>
                <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
                    {years}
                </select>
            </div>
        );
    };

    private renderMonthElementForDateTime = ({ month }: { month: moment.Moment }) => {
        const { dateTime } = this.state;
        return (
            <div className="CalendarMonth_time">
                {/* This input allows the user to select the time */}
                <p className="calendar-month">{month.format(DATE_FORMAT.MONTH_YEAR)}</p>
                <input
                    type="time"
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={(e) => {
                        const newTime = e.target.value;
                        // Update the time part of the dateTime
                        if (dateTime) {
                            const newDateTime = dateTime.clone();
                            newDateTime.set({
                                hour: +newTime.split(":")[0],
                                minute: +newTime.split(":")[1],
                            });
                            this.setState({ dateTime: newDateTime });
                            this.handleDateTimeChange(newDateTime, true);
                        }
                    }}
                    value={dateTime ? dateTime.format(DATE_FORMAT.HOURS_MINUTES) : ""}
                />
            </div>
        );
    };

    private setThemeColors = async (themeIds: any) => {
        const [themesProm, cancelThemes] = cancelable(ThemeApi.findById({ id: themeIds }));
        this.cancelThemesObtain = cancelThemes;
        await themesProm.then((theme: any) => {
            if (theme) {
                const newColorArray: any = this.state.cmsColors;
                if (theme.brandAltColor) {
                    newColorArray.push(theme.brandAltColor);
                }
                if (theme.brandColor) {
                    newColorArray.push(theme.brandColor);
                }
                if (theme.ctaColor) {
                    newColorArray.push(theme.ctaColor);
                }
                if (theme.neutralAltColor) {
                    newColorArray.push(theme.neutralAltColor);
                }
                if (theme.neutralColor) {
                    newColorArray.push(theme.neutralColor);
                }
                this.setState({ cmsColors: newColorArray, theme });
            }
        });
    };

    private handleShowColorPicker = () => {
        const { enabled } = this.props;
        if (enabled) {
            this.setState({ showColorPicker: !this.state.showColorPicker });
        }
    };

    private handleColorChangeComplete = (color: any) => {
        const { spec } = this.props;
        this.props.onChange(color.hex, spec);
    };

    private handleDualColorChangeComplete = (color: any) => {
        const { spec } = this.props;
        const { useRgbaInsteadOfThemeVariable } = spec as InputSpecColor<E, P>;
        const theme = this.state.theme;
        let selectedColor = "";
        Object.keys(theme).forEach((key: string) => {
            const themeKeyValues = theme && (theme as any)[key];
            const lower = themeKeyValues && themeKeyValues.indexOf("#") > -1 ? themeKeyValues.toLowerCase() : "";
            if (lower && color.hex.toLowerCase() === lower && !key.toLowerCase().includes("button")) {
                selectedColor = key;
            }
        });
        let opacity = color.rgb.a;
        if (opacity < 1) {
            opacity = opacity * 100;
        }
        // eslint-disable-next-line max-len
        const selectedColorStr = `${color.rgb.a === 1 && selectedColor ? `theme-${selectedColor}` : color.rgb.a < 1 ? `opacity-theme-${selectedColor}--${opacity.toFixed(0)}` : ""}`;
        const colorStr = selectedColor.length > 0 && !useRgbaInsteadOfThemeVariable ? selectedColorStr : `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
        this.props.onChange(colorStr, spec);
        this.setState({ currentColor: colorStr });
    };

    private hexToRGBA = (colorHex: string, alpha?: string) => {
        const r = parseInt(colorHex.slice(1, 3), 16);
        const g = parseInt(colorHex.slice(3, 5), 16);
        const b = parseInt(colorHex.slice(5, 7), 16);
        const opacity = alpha && alpha.length ? +alpha / 100 : 1;
        if (alpha && alpha.length > 0 && +opacity < 1) {
            return "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";
        }
        return "rgba(" + r + ", " + g + ", " + b + ", " + "1" + ")";
    };

    private resetColor = () => {
        this.props.onChange("default", this.props.spec);
    };

    private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { spec, alerts } = this.props;
        const target = event.target;
        let rawValue: any;

        switch (target.type) {
            case "checkbox":
                rawValue = target.checked;
                break;

            case "radio":
                rawValue = target.id;
                break;

            case "number":
            case "range":
                rawValue = target.value;
                // Clamp the value between the min and max
                const minValue = target.min ? parseFloat(target.min) : -Infinity;
                const maxValue = target.max ? parseFloat(target.max) : Infinity;
                if (+rawValue > maxValue) {
                    rawValue = String(maxValue);
                } else if (+rawValue < minValue) {
                    rawValue = String(minValue);
                }
                // Update the slider track to show selected range color
                if (target.type === "range") {
                    const percentage = ((+rawValue - +target.min) / (+target.max - +target.min)) * 100;
                    target.style.setProperty("--value", `${percentage}%`);
                }
                break;

            case "file":
                const selectedFile = target?.files![0];
                let errorMessage = "";
                if (selectedFile) {
                    if (!selectedFile.type.includes("image/") && selectedFile.type !== "application/pdf") {
                        errorMessage = "File type not supported. Please select an image or PDF file.";
                    }

                    if (selectedFile.size > 15 * 1024 * 1024) {
                        errorMessage = "File size exceeds 15MB. Please select a smaller file.";
                    }
                }
                if (errorMessage) {
                    alerts.push({
                        color: "info",
                        message: errorMessage,
                    });
                    target.value = "";
                    break;
                }
                rawValue = selectedFile || target.value;
                break;

            default:
                rawValue = target.value;
        }

        // Validate the input value if needed
        this.validateInput(target.value);

        // Trigger the onChange callback with the updated value
        this.props.onChange(rawValue, spec);
    };

    private handleInputText = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        if (target && (target.value || target.value === "")) {
            this.setState({ inpTextValue: target.value, inpTextBool: true }, () => {
                const dynamicDebounceTime = target.value === "" ? 500 : 2000;
                const debouncedValidation = debounce(this.validateInput, dynamicDebounceTime);
                debouncedValidation(target.value, true);
            });
        }
    };

    // This handleDirect is the beginning of a series of improvements where direct inputs should be used less, this should happen
    // with focussed inputs during binding, please see: MCMS-3064
    private handleDirectInputText = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        if (target?.value || target?.value === "") {
            this.setState({ inpTextValue: target.value, inpTextBool: true }, () => {
                const dynamicDebounceTime = target.value === "" ? 500 : 2000;
                const debouncedValidation = debounce(this.validateInput, dynamicDebounceTime);
                debouncedValidation(target.value, true);
            });
        }
        // Direct input change for working inputs where direct change is needed
        this.props.onChange(target.value, this.props.spec);
    };

    private handleBlur = (inpTextBoolOverride?: boolean) => {
        const { spec, onChange } = this.props;
        const { inpTextValue, inpTextBool } = this.state;
        if (inpTextBool) {
            this.setState(() => {
                onChange(inpTextValue, spec);
                return { inpTextBool: inpTextBoolOverride || false, prevStatTxt: inpTextValue };
            });
        }
    };
    private handleReactSelectOnChange = (event: any) => {
        const { spec, onChange, validate } = this.props;
        const value = event?.value || null;

        onChange(value, spec);

        if (validate) {
            validate(spec.variable as string, spec.required ? !!value : true);
        }
    };
}

export const SimpleInput = wrapProps<GenericInputProps<any, any, InputSpecSimple<any, any>>>(CMSAware(SimpleInputBase));
