import * as React from "react";
import * as moment from "moment";

import {
    ConditionalSetDynamicFilterList,
    conditionalOperators,
    dynamicFilterList,
    getAccoKindList,
    getAccommodationTypesList,
    getAmentiesList,
    getContractsTypesList,
    getDaysList,
    getNumberCounts,
    getRateTypes,
    getRegionList,
    getReservationCategoryList,
    getReservationStatusSelectOptions,
    getReservationTypeSelectOptions,
    getResortActivityList,
    getResortList,
    getSubjectList,
    getUnitsList,
    logicalOperators,
    myEnvUserTypes,
    ownerTypeSelectOptions,
    paymentStatuses,
    transformInputToGroupItem,
} from "../utils/conditionalSet.util";
import { ConditionalSetSelect, ContentType } from "../../../components.enum";
import type { InputGroup, InputGroupItem } from "../inputGroup.types";
import { distributionChannelOptions, getAdminMxtsEnv } from "../../../../plugins/mxts";

import { Close } from "@mui/icons-material";
import { DATE_FORMAT } from "../../../../utils/constants";
import { Input } from "reactstrap";
import { MxtsApi } from "@maxxton/cms-mxts-api";
import Select from "../../multiselect-component";
import { SelectOption } from "../../../../form-specs/formSpec.types";
import { SingleDatePicker } from "react-dates";
import { getCountrySelectOptions } from "../../../../plugins/mxts/mxts.util";
import { getI18nLocaleString } from "../../../../i18n";
import namespaceList from "../../../../i18n/namespaceList";

export interface InputGroupCrudProps {
    updateInputGroup?: (id?: string, updatedInputGroupItem?: InputGroupItem) => void;
    createInputGroup?: (newInputGroup: InputGroupItem) => void;
    cancelCreateInputGroup?: () => void;
    deleteInputGroup?: (inputGroupItem: InputGroupItem) => void;
}

export interface InputGroupRowProps extends InputGroupCrudProps {
    inputGroupItem?: InputGroupItem;
    enabled?: boolean;
    selectedDynamicFilter?: SelectOption<string>;
    selectedCondition?: SelectOption<string>;
    selectedDynamicFilterData?: SelectOption<string>;
    selectedDynamicFilterMultiData?: Array<SelectOption<string>>;
    logicalOperators?: SelectOption<string>;
    inputGroup?: InputGroup;
}

// eslint-disable-next-line max-lines-per-function
export const InputGroupRow: React.FunctionComponent<InputGroupRowProps> = (props: InputGroupRowProps): React.ReactElement | null => {
    const { inputGroupItem, enabled, inputGroup } = props;
    const [selectedDynamicFilter, setSelectedDynamicFilter] = React.useState<SelectOption<string> | undefined>(props.selectedDynamicFilter || undefined);
    const [selectedConditionalOperator, setSelectedConditionalOperator] = React.useState<SelectOption<string> | undefined>(props.selectedCondition || undefined);
    const [selectedDynamicFilterData, setSelectedDynamicFilterData] = React.useState<SelectOption<string> | undefined>(props.selectedDynamicFilterData || undefined);
    const [selectedDynamicFilterMultiData, setSelectedDynamicFilterMultiData] = React.useState<Array<SelectOption<string>> | undefined>(props.selectedDynamicFilterMultiData || undefined);
    const [selectedLogicalOperators, setSelectedLogicalOperators] = React.useState<SelectOption<string> | undefined>(props.logicalOperators || undefined);
    const [resortList, setResortList] = React.useState<Array<SelectOption<string>>>([]);
    const [regionList, setRegionList] = React.useState<Array<SelectOption<string>>>([]);
    const [amentiesList, setAmentiesList] = React.useState<Array<SelectOption<string>>>([]);
    const [accoKindList, setAccoKindListList] = React.useState<Array<SelectOption<string>>>([]);
    const [accommodationTypesList, setAccommodationTypesList] = React.useState<Array<SelectOption<string>>>([]);
    const [unitsList, setUnitsList] = React.useState<Array<SelectOption<string>>>([]);
    const [distributionChannelList, setDistributionChannelList] = React.useState<Array<SelectOption<string>>>([]);
    const [countryList, setCountryList] = React.useState<Array<SelectOption<string>>>([]);
    const [reservationCategoryList, setReservationCategoryList] = React.useState<Array<SelectOption<string>>>([]);
    const [contractsTypesList, setContractsTypesList] = React.useState<Array<SelectOption<string>>>([]);
    const [resortActivityList, setResortActivityList] = React.useState<Array<SelectOption<string>>>([]);
    const [subjectList, setSubjectList] = React.useState<Array<SelectOption<string>>>([]);
    const [rateTypeList, setRateTypeList] = React.useState<Array<SelectOption<string>>>([]);
    const [date, setDate] = React.useState<moment.Moment | null>(null);
    const [focus, setFocus] = React.useState(false);
    React.useEffect(() => {
        async function fetchData() {
            if (selectedDynamicFilter?.value === ContentType.RESORT.toString() && !resortList.length) {
                setResortList(await getResortList());
            }
            if (selectedDynamicFilter?.value === ContentType.REGION.toString() && !regionList.length) {
                setRegionList(await getRegionList());
            }
            if (selectedDynamicFilter?.value === ContentType.AMENITY.toString() && !amentiesList.length) {
                setAmentiesList(await getAmentiesList());
            }
            if (selectedDynamicFilter?.value === ContentType.ACCOMMODATION_KIND.toString() && !accoKindList.length) {
                setAccoKindListList(await getAccoKindList());
            }
            if (selectedDynamicFilter?.value === ContentType.ACCOMMODATION_TYPE.toString() && !accommodationTypesList.length) {
                setAccommodationTypesList(await getAccommodationTypesList());
            }
            if (selectedDynamicFilter?.value === ContentType.UNIT.toString() && !unitsList.length) {
                setUnitsList(await getUnitsList());
            }
            if (selectedDynamicFilter?.value === ConditionalSetDynamicFilterList.DISTRIBUTION_CHANNEL && !distributionChannelList.length) {
                setDistributionChannelList(await distributionChannelOptions(MxtsApi));
            }
            if (selectedDynamicFilter?.value === ConditionalSetDynamicFilterList.CUSTOMER_COUNTRY && !countryList.length) {
                const env = await getAdminMxtsEnv();
                setCountryList((await getCountrySelectOptions(env, MxtsApi)).map((countryOption) => ({ ...countryOption, value: `${countryOption.value}` })));
            }
            if (selectedDynamicFilter?.value === ConditionalSetDynamicFilterList.RESERVATION_CATEGORY && !reservationCategoryList.length) {
                setReservationCategoryList(await getReservationCategoryList());
            }
            if (selectedDynamicFilter?.value === ConditionalSetDynamicFilterList.CONTRACTS_TYPE && !contractsTypesList.length) {
                setContractsTypesList(await getContractsTypesList());
            }
            if (selectedDynamicFilter?.value === ConditionalSetDynamicFilterList.RESORT_ACTIVITY && !resortActivityList.length) {
                setResortActivityList(await getResortActivityList());
            }
            if (selectedDynamicFilter?.value === ConditionalSetDynamicFilterList.RATE_TYPE && !rateTypeList.length) {
                setRateTypeList(await getRateTypes());
            }
            if (selectedDynamicFilter?.value === ConditionalSetDynamicFilterList.SUBJECT && !subjectList.length) {
                setSubjectList(await getSubjectList());
            }
        }
        fetchData();
        if (selectedDynamicFilter) {
            props.updateInputGroup?.(
                inputGroupItem?.id,
                transformInputToGroupItem(selectedLogicalOperators?.value, selectedDynamicFilter.value, selectedConditionalOperator?.value, selectedDynamicFilterData, selectedDynamicFilterMultiData)
            );
        }
    }, [
        accoKindList.length,
        accommodationTypesList.length,
        amentiesList.length,
        distributionChannelList.length,
        regionList.length,
        reservationCategoryList.length,
        contractsTypesList.length,
        resortActivityList.length,
        rateTypeList.length,
        resortList.length,
        selectedDynamicFilter?.value,
        unitsList.length,
        selectedConditionalOperator?.value,
        selectedDynamicFilterData,
        selectedDynamicFilterMultiData,
        selectedLogicalOperators?.value,
        selectedDynamicFilter,
        inputGroupItem?.id,
        subjectList.length,
    ]);

    let isDynamicFilterMultiData = false;
    switch (selectedDynamicFilter?.value) {
        case ContentType.RESORT.toString():
        case ContentType.ACCOMMODATION_KIND.toString():
        case ContentType.ACCOMMODATION_TYPE.toString():
        case ContentType.UNIT.toString():
        case ConditionalSetDynamicFilterList.CUSTOMER_COUNTRY.toString():
        case ConditionalSetDynamicFilterList.CONTRACTS_TYPE.toString():
            isDynamicFilterMultiData = true;
            break;
        default:
            isDynamicFilterMultiData = false;
    }

    const dynamicFilterOptions = (selectedFilter?: string) => {
        const dynamicFilter = selectedFilter;
        switch (dynamicFilter) {
            case ContentType.RESORT.toString():
                return resortList;
            case ContentType.REGION.toString():
                return regionList;
            case ContentType.AMENITY.toString():
                return amentiesList;
            case ContentType.ACCOMMODATION_KIND.toString():
                return accoKindList;
            case ContentType.ACCOMMODATION_TYPE.toString():
                return accommodationTypesList;
            case ContentType.UNIT.toString():
                return unitsList;
            case ConditionalSetDynamicFilterList.USER_TYPE:
                return myEnvUserTypes;
            case ConditionalSetDynamicFilterList.OWNER_TYPE:
                return ownerTypeSelectOptions;
            case ConditionalSetDynamicFilterList.CUSTOMER_COUNTRY:
                return countryList;
            case ConditionalSetDynamicFilterList.DISTRIBUTION_CHANNEL:
                return distributionChannelList;
            case ConditionalSetDynamicFilterList.RESERVATION_CATEGORY:
                return reservationCategoryList;
            case ConditionalSetDynamicFilterList.CONTRACTS_TYPE:
                return contractsTypesList;
            case ConditionalSetDynamicFilterList.RESORT_ACTIVITY:
                return resortActivityList;
            case ConditionalSetDynamicFilterList.SUBJECT:
                return subjectList;
            case ConditionalSetDynamicFilterList.RATE_TYPE:
                return rateTypeList;
            case ConditionalSetDynamicFilterList.PAYMENT_STATUS:
                return paymentStatuses;
            case ConditionalSetDynamicFilterList.RESERVATION_STATUS:
                return getReservationStatusSelectOptions();
            case ConditionalSetDynamicFilterList.RESERVATION_TYPE:
                return getReservationTypeSelectOptions();
            default:
                return [];
        }
    };

    function handleDate(date: moment.Moment | null) {
        const dateOption = {
            label: moment(date).format(DATE_FORMAT.MXTS),
            value: moment(date).format(DATE_FORMAT.MXTS),
        };
        setDate(date);
        return dateOption;
    }

    function handleInput(text: string) {
        const inputOption = {
            label: text,
            value: text,
        };
        return inputOption;
    }

    function handleDynamicFilterChange(value: SelectOption<string>) {
        setSelectedDynamicFilterData(undefined);
        setSelectedDynamicFilterMultiData(undefined);
        setSelectedDynamicFilter(value);
    }

    const isSelectedDate = selectedDynamicFilter?.value === ConditionalSetSelect.START_DATE || selectedDynamicFilter?.value === ConditionalSetSelect.END_DATE;
    const isSelectedInput = selectedDynamicFilter?.value === ConditionalSetDynamicFilterList.SPECIALCODE;
    const isSelectedArrivalDepartureDay =
        selectedDynamicFilter?.value === ConditionalSetSelect.ARRIVAL_DAY ||
        selectedDynamicFilter?.value === ConditionalSetSelect.DEPARTURE_DAY ||
        selectedDynamicFilter?.value === ConditionalSetDynamicFilterList.RESERVATION_DATE;

    const isSelectedNumbers = selectedDynamicFilter?.value === ConditionalSetDynamicFilterList.MIN_BATHROOM || selectedDynamicFilter?.value === ConditionalSetDynamicFilterList.MIN_BEDROOM;

    const getConditionalOperator = () => {
        let conditionalOperatorArray = conditionalOperators;
        if (!isSelectedDate) {
            conditionalOperatorArray = conditionalOperatorArray.filter((item) => !(item.value === ConditionalSetSelect.GREATER_THAN || item.value === ConditionalSetSelect.LESS_THAN));
        }
        if (!isSelectedArrivalDepartureDay) {
            conditionalOperatorArray = conditionalOperatorArray.filter((item) => !(item.value === ConditionalSetSelect.BEFORE || item.value === ConditionalSetSelect.AFTER));
        }
        if (isSelectedArrivalDepartureDay) {
            conditionalOperatorArray = conditionalOperatorArray.filter((item) => !(item.value === ConditionalSetSelect.EQUAL_TO || item.value === ConditionalSetSelect.NOT_EQUAL_TO));
        }
        return conditionalOperatorArray;
    };
    if (inputGroupItem) {
        return (
            <tr>
                <td className="logicalOperator">
                    {!!inputGroup?.length && (
                        <Select
                            name={"conditionalSet-logicalOperators"}
                            value={selectedLogicalOperators}
                            onChange={(value: SelectOption<string>) => setSelectedLogicalOperators(value)}
                            options={logicalOperators}
                            clearable={false}
                            multi={false}
                            disabled={!enabled}
                        />
                    )}
                </td>
                <td className="dynamicFilter">
                    <Select
                        name={"conditionalSet-dynamicFilter"}
                        value={selectedDynamicFilter}
                        onChange={(value: SelectOption<string>) => handleDynamicFilterChange(value)}
                        options={dynamicFilterList}
                        clearable={false}
                        multi={false}
                        disabled={!enabled}
                    />
                </td>
                <td className="conditionalOperator">
                    <Select
                        name={"conditionalSet-condition"}
                        value={selectedConditionalOperator}
                        onChange={(value: SelectOption<string>) => setSelectedConditionalOperator(value)}
                        options={getConditionalOperator()}
                        clearable={false}
                        multi={false}
                        disabled={!enabled}
                    />
                </td>
                <td className="conditionalSetData">
                    {isDynamicFilterMultiData ? (
                        <Select
                            name={"conditionalSet-data"}
                            value={selectedDynamicFilterMultiData}
                            onChange={(value: Array<SelectOption<string>>) => setSelectedDynamicFilterMultiData(value)}
                            options={dynamicFilterOptions(selectedDynamicFilter?.value)}
                            clearable={false}
                            multi={true}
                            disabled={!enabled}
                        />
                    ) : isSelectedDate && selectedDynamicFilter ? (
                        <SingleDatePicker
                            id={selectedDynamicFilter.value}
                            date={moment(selectedDynamicFilterData?.value)}
                            onDateChange={(date) => setSelectedDynamicFilterData(handleDate(date))}
                            focused={focus}
                            onFocusChange={({ focused }) => setFocus(focused)}
                            displayFormat={DATE_FORMAT.DATE_WITH_SHORT_MONTH}
                            showClearDate={false}
                            numberOfMonths={1}
                            hideKeyboardShortcutsPanel={true}
                            isOutsideRange={() => false}
                        />
                    ) : isSelectedInput ? (
                        <Input
                            disabled={!enabled}
                            id={inputGroupItem.id}
                            name={"conditionalSet-input"}
                            type="text"
                            value={selectedDynamicFilterData?.value}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedDynamicFilterData(handleInput(event.target.value))}
                        />
                    ) : (
                        <Select
                            name={"conditionalSet-data"}
                            value={selectedDynamicFilterData}
                            onChange={(value: SelectOption<string>) => setSelectedDynamicFilterData(value)}
                            options={isSelectedArrivalDepartureDay ? getDaysList() : isSelectedNumbers ? getNumberCounts() : dynamicFilterOptions(selectedDynamicFilter?.value)}
                            clearable={false}
                            multi={false}
                            disabled={!enabled}
                        />
                    )}
                </td>
                <td className="input-group__action-buttons">
                    <button
                        className="btn btn-cancel"
                        disabled={!enabled}
                        onClick={() => inputGroupItem && props.deleteInputGroup?.(inputGroupItem)}
                        title={getI18nLocaleString(namespaceList.admin, "cancel")}
                        type="button"
                    >
                        <Close />
                    </button>
                </td>
            </tr>
        );
    }
    return null;
};
