import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classnames from "classnames";

import { Button, Input, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { InputSpec, InputSpecTabbed, Tab } from "../../form-specs";
import { Locale, LocaleApi, WithId } from "@maxxton/cms-api";
import { getI18nLocaleString, wrapProps } from "../../i18n";

import { GenericInput } from "./input";
import { GenericInputProps } from "./input.types";
import { cancelable } from "../../promise";
import namespaceList from "../../i18n/namespaceList";

interface TabbedInputState<TE, TP extends keyof TE> {
    tabs: Array<Tab<TE[TP]>>;
    activeTab: number;
    value: TE[];
    locales: Array<Locale & WithId>;
    isCopy: boolean;
    copyMatrix?: Array<Array<{ checked: boolean; variable: keyof TE | undefined }>>;
    selectAll: boolean[];
    labels: any;
}

export type TabbedBaseProps<E extends { P: TE[] }, P extends keyof E, TE, TP extends keyof TE> = GenericInputProps<E, P, InputSpecTabbed<E, P, TE, TP>>;

export type TabbedProps<E extends { P: TE[] }, P extends keyof E, TE, TP extends keyof TE> = GenericInputProps<E, P, InputSpecTabbed<E, P, TE, TP>>;

class TabbedInputBase<E extends { P: TE[] }, P extends keyof E, TE, TP extends keyof TE> extends React.PureComponent<TabbedProps<E, P, TE, TP>, TabbedInputState<TE, TP>> {
    constructor(props: TabbedProps<E, P, TE, TP>) {
        super(props);
        this.state = {
            tabs: [],
            activeTab: 0,
            value: [],
            locales: [],
            isCopy: false,
            selectAll: [],
            labels: this.localizedTabsLabels(),
        };
    }

    public componentDidMount() {
        const { spec, item } = this.props;
        const [tabsProm, cancelTabs] = cancelable(spec.tabs(item));
        const [localesProm, cancelLocales] = cancelable(LocaleApi.find());
        this.cancelTabs = cancelTabs;
        this.cancelLocales = cancelLocales;
        tabsProm.then((tabs) => {
            this.updateValue(tabs, this.props);
        });
        localesProm.then((locales: any) => {
            this.setState(() => ({ locales }));
        });
    }

    public componentWillUnmount() {
        this.cancelTabs();
        this.cancelLocales();
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<TabbedProps<E, P, TE, TP>>): void {
        this.setLocalizedTabsPageWebContent();
        this.updateValue(this.state.tabs, nextProps);
    }
    private countFields = (tabIndex: number) => {
        const { tabContent } = this.props.spec;
        const { value } = this.state;
        let inputFieldCount = 0;
        let filledFieldCount = 0;

        tabContent.forEach((fieldSpec: InputSpec<TE, keyof TE>) => {
            if (fieldSpec) {
                inputFieldCount++;
                const fieldValue = value[tabIndex][fieldSpec.variable as keyof TE];

                if (fieldValue) {
                    filledFieldCount++;
                }
            }
        });

        return { inputFieldCount, filledFieldCount };
    };

    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const { spec, mode, root, initialItem, permission } = this.props;
        const { tabContent } = spec;
        const { tabs, activeTab, value, locales, isCopy } = this.state;
        if (!tabs || value.length !== tabs.length) {
            return <div />;
        }
        return (
            <div className="generic-form-tabbed localized">
                <Nav tabs>
                    {tabs.map((tab, ind) => {
                        const { inputFieldCount } = this.countFields(ind); // Count input fields for this tab
                        return (
                            <NavItem key={ind}>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === ind,
                                        [`locale-${tab.name}`]: locales.some((locale: any) => tab.name === locale.name),
                                    })}
                                    onClick={this.handleTabClick(ind)}
                                >
                                    <span className="tab-name">{tab.name as string}</span>
                                    <span className="field-count">
                                        {this.countFields(ind).filledFieldCount}|{inputFieldCount}
                                    </span>
                                </NavLink>
                            </NavItem>
                        );
                    })}
                </Nav>
                <TabContent activeTab={activeTab}>
                    {tabs.map((tab, tabInd) => (
                        <TabPane key={tabInd} tabId={tabInd}>
                            <div className="copy-language-container">
                                {isCopy && (
                                    <div className="check-all space-mb-m">
                                        <Input
                                            key={tabInd + "All"}
                                            type="checkbox"
                                            id={"copyAll" + tabInd}
                                            onChange={this.handleSelectAllCheckbox.bind(this, tabInd)}
                                            onClick={this.handleCheckboxClick}
                                        />
                                        <label htmlFor={"copyAll" + tabInd}>{getI18nLocaleString(namespaceList.admin, "selectAllLanguagesCopy")}</label>
                                    </div>
                                )}
                                <div role="group" className={"button-group form-group d-flex justify-content-start space-mb-l"}>
                                    {!isCopy && (
                                        <Button className="btn copy-language" color="success" onClick={this.handleCopyEnable}>
                                            <FontAwesome name="files-o" />
                                            {getI18nLocaleString(namespaceList.admin, "copyLanguage")}
                                        </Button>
                                    )}
                                    <div className="copy-buttons mcms-tooltip-wrap">
                                        {isCopy &&
                                            tabs.map(
                                                (tab2, ind2) =>
                                                    activeTab !== ind2 && (
                                                        <Button
                                                            key={tabInd + "-" + ind2}
                                                            outline
                                                            className={`mr-2 ${classnames({
                                                                active: activeTab === ind2,
                                                                [`locale-${tab2.name}`]: locales.some((locale: any) => tab2.name === locale.name),
                                                            })}`}
                                                            color="primary"
                                                            onClick={this.handleCopyClick.bind(this, ind2)}
                                                        >
                                                            <span className="mcms-tooltip top">{getI18nLocaleString(namespaceList.admin, "copyFrom")}</span>
                                                        </Button>
                                                    )
                                            )}
                                    </div>
                                    {isCopy && (
                                        <span className="btn cancel" color="secondary" onClick={this.handleCopyEnable}>
                                            <a className="hover-link">{getI18nLocaleString(namespaceList.admin, "cancel")}</a>
                                        </span>
                                    )}
                                </div>
                            </div>
                            {tabContent.map((fieldSpec: InputSpec<TE, keyof TE>, fieldInd) => (
                                <div className={"form-element" + isCopy ? "form-element__copy" : ""} key={tabInd + " " + fieldInd}>
                                    {isCopy && (
                                        <div className="selection-check">
                                            <Input
                                                key={tabInd + " " + fieldInd}
                                                type="checkbox"
                                                id={"copy" + fieldInd}
                                                checked={this.isCheckboxSelected(tabInd, fieldInd)}
                                                onChange={this.handleCheckboxChange.bind(this, tabInd, fieldInd)}
                                                onClick={this.handleCheckboxClick}
                                            />
                                        </div>
                                    )}
                                    <GenericInput
                                        spec={fieldSpec as any}
                                        mode={mode}
                                        permission={permission}
                                        root={root}
                                        item={value as any}
                                        initialItem={initialItem}
                                        value={fieldSpec.variable !== undefined ? value[tabInd][fieldSpec.variable] : undefined}
                                        onChange={this.handleNewVal}
                                        tabLocale={tab.value as any}
                                        localeCode={locales[tabInd].code}
                                        friendlyUrl={this.props.friendlyUrl}
                                    />
                                </div>
                            ))}
                        </TabPane>
                    ))}
                </TabContent>
            </div>
        );
    }

    private cancelTabs: () => void = () => undefined;
    private cancelLocales: () => void = () => undefined;

    private updateValue(tabs: Array<Tab<TE[TP]>>, props: Readonly<TabbedProps<E, P, TE, TP>>): void {
        const { spec } = props;
        const rawValue: TE[] = ((props.value as any) as TE[]) || [];
        const value: TE[] = tabs.map(
            (tab: Tab<TE[TP]>): TE => {
                const val = rawValue.find((rawVal) => rawVal[spec.tabVariable] === tab.value);
                if (val !== undefined) {
                    return val;
                }
                return ({ [spec.tabVariable as string]: tab.value } as any) as TE;
            }
        );
        const selectAll = tabs.map(() => false);
        this.setState(() => ({ tabs, value, selectAll, copyMatrix: this.createMatrix(tabs, spec) }));
    }

    private createMatrix(tabs: Array<Tab<TE[TP]>>, spec: InputSpecTabbed<E, P, TE, TP>): Array<Array<{ checked: boolean; variable: keyof TE | undefined }>> {
        const arr: Array<Array<{ checked: boolean; variable: keyof TE | undefined }>> = [];
        for (let i = 0; i < tabs.length; ++i) {
            const columns: Array<{ checked: boolean; variable: keyof TE | undefined }> = [];
            for (let j = 0; j < spec.tabContent.length; ++j) {
                columns[j] = { checked: false, variable: spec.tabContent[j].variable };
            }
            arr[i] = columns;
        }
        return arr;
    }

    private handleCopyEnable = () => {
        this.setState((state) => ({ isCopy: !state.isCopy }));
    };

    private handleCopyClick = (copyFromLocaleIndex: number) => {
        const { value, activeTab, copyMatrix } = this.state;
        const { spec } = this.props;
        const clonedValue = JSON.parse(JSON.stringify(value));
        copyMatrix![activeTab].forEach((item) => {
            if (item.checked) {
                clonedValue[activeTab][item.variable] = (value[copyFromLocaleIndex] as any)[item.variable];
            }
        });
        this.setState((state) => ({ isCopy: !state.isCopy, value: clonedValue }));
        localStorage.setItem("copyClicked", activeTab.toString());
        this.props.onChange(clonedValue, spec);
    };

    private handleCheckboxClick = (e: React.MouseEvent<HTMLInputElement>) => {
        e.stopPropagation();
    };

    private isCheckboxSelected = (tabInd: number, fieldInd: number): boolean => {
        if (this.state.copyMatrix) {
            const value = this.state.copyMatrix[tabInd][fieldInd];
            return value && value.checked ? value.checked : false;
        }
        return false;
    };

    private handleCheckboxChange = (tabInd: number, fieldInd: number) => {
        if (this.state.copyMatrix) {
            const updatedCopyMatrix = JSON.parse(JSON.stringify(this.state.copyMatrix));
            const cell = updatedCopyMatrix[tabInd][fieldInd];
            updatedCopyMatrix[tabInd][fieldInd] = { ...cell, checked: !cell.checked };
            this.setState(() => ({ copyMatrix: updatedCopyMatrix }));
        }
    };

    private handleSelectAllCheckbox = (tabInd: number) => {
        if (this.state.copyMatrix) {
            const updatedCopyMatrix: Array<Array<{ checked: boolean; variable: keyof TE | undefined }>> = JSON.parse(JSON.stringify(this.state.copyMatrix));
            updatedCopyMatrix[tabInd] = updatedCopyMatrix[tabInd].map((item) => ({ ...item, checked: !this.state.selectAll[tabInd] }));
            const updatedSelectAll = [...this.state.selectAll];
            updatedSelectAll[tabInd] = !this.state.selectAll[tabInd];
            this.setState(() => ({ selectAll: updatedSelectAll, copyMatrix: updatedCopyMatrix }));
        }
    };

    private handleTabClick(tabIndex: number): () => void {
        return () => {
            this.setState(() => ({ activeTab: tabIndex }));
        };
    }

    private handleNewVal = (newPropVal: any, fieldSpec: InputSpec<TE, keyof TE>) => {
        const { spec } = this.props;
        const { value, activeTab } = this.state;
        const newValue = [...value];
        newValue[activeTab] = { ...(value[activeTab] as any), [fieldSpec.variable!]: newPropVal };
        this.props.onChange(newValue, spec);
    };
    private localizedTabsLabels = () => {
        const generalWebContentImage = ["localizedImageContent0", "localizedImageContent1", "localizedImageContent2"];
        const generalWebContentTitle = ["title0", "title1", "title2"];
        const generalrichTextContent = ["localizedContent0", "localizedContent1", "localizedContent2"];
        const seoPageItems = ["localizedContents0", "localizedContents1", "localizedContents2"];
        const structuredPageData = ["Structured Data0", "Structured Data1", "Structured Data2"];
        const specialWebcontentCard = ["localizedSpecialCard0", "localizedSpecialCard1", "localizedSpecialCard2"];
        const cardWebcontentLayout = ["localizedCardContent0", "localizedCardContent1", "localizedCardContent2"];
        const webContentPageSpec = [];
        webContentPageSpec.push([generalWebContentImage, generalWebContentTitle, specialWebcontentCard, generalrichTextContent, cardWebcontentLayout]);
        webContentPageSpec.push([seoPageItems, structuredPageData]);

        return webContentPageSpec;
    };

    private setLocalizedTabsPageWebContent = () => {
        const undoRedoInfo = JSON.parse(localStorage.getItem("undoRedoInfo") || "[]");
        if (undoRedoInfo && undoRedoInfo.length > 0 && undoRedoInfo[0]) {
            let tabIndex: number;
            const { labels } = this.state;
            const spec = undoRedoInfo[undoRedoInfo.length - 1].spec;
            const label = undoRedoInfo[undoRedoInfo.length - 1].variable;
            if (spec === "webcontent") {
                if (
                    labels[0][0][0].indexOf(label) !== -1 ||
                    labels[0][1][0].indexOf(label) !== -1 ||
                    labels[0][2][0].indexOf(label) !== -1 ||
                    labels[0][3][0].indexOf(label) !== -1 ||
                    labels[0][4][0].indexOf(label) !== -1
                ) {
                    tabIndex = 0;
                    this.setState({ activeTab: tabIndex });
                }
                if (
                    labels[0][0][1].indexOf(label) !== -1 ||
                    labels[0][1][1].indexOf(label) !== -1 ||
                    labels[0][2][1].indexOf(label) !== -1 ||
                    labels[0][3][1].indexOf(label) !== -1 ||
                    labels[0][4][1].indexOf(label) !== -1
                ) {
                    tabIndex = 1;
                    this.setState({ activeTab: tabIndex });
                }
                if (
                    labels[0][0][2].indexOf(label) !== -1 ||
                    labels[0][1][2].indexOf(label) !== -1 ||
                    labels[0][2][2].indexOf(label) !== -1 ||
                    labels[0][3][2].indexOf(label) !== -1 ||
                    labels[0][4][2].indexOf(label) !== -1
                ) {
                    tabIndex = 2;
                    this.setState({ activeTab: tabIndex });
                }
            }
            if (spec === "page") {
                if (labels[1][0][0].indexOf(label) !== -1 || labels[1][1][0].indexOf(label) !== -1) {
                    tabIndex = 0;
                    this.setState({ activeTab: tabIndex });
                }
                if (labels[1][0][1].indexOf(label) !== -1 || labels[1][1][1].indexOf(label) !== -1) {
                    tabIndex = 1;
                    this.setState({ activeTab: tabIndex });
                }
                if (labels[1][0][2].indexOf(label) !== -1 || labels[1][1][2].indexOf(label) !== -1) {
                    tabIndex = 2;
                    this.setState({ activeTab: tabIndex });
                }
            }
        }
    };
}

export const TabbedInput = wrapProps<TabbedBaseProps<any, any, any, any>>(TabbedInputBase);
