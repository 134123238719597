import * as React from "react";

import { SiteGroup, WithId } from "@maxxton/cms-api";

import { SelectOption } from "../../../../form-specs/formSpec.types";
import { SiteGroupRow } from "./SiteGroupRow";
import { Table } from "reactstrap";

export interface SiteGroupTableProps extends SiteGroupCrudProps {
    siteGroups: Array<SiteGroup & WithId>;
    siteOptions: Array<SelectOption<string>>;
    addNewGroup: boolean;
    enabled?: boolean;
}

export interface SiteGroupCrudProps {
    updateSiteGroup?: (id: string, updatedSiteGroup: SiteGroup) => Promise<void>;
    createSiteGroup?: (newSiteGroup: SiteGroup) => Promise<void>;
    cancelCreateSiteGroup?: () => void;
    deleteSiteGroup?: (id: string) => Promise<void>;
}

export const SiteGroupTable: React.FunctionComponent<SiteGroupTableProps> = (props: SiteGroupTableProps): React.ReactElement => {
    const { siteGroups, siteOptions, addNewGroup, enabled } = props;

    return (
        <Table hover bordered className="site-groups">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Sites</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {siteGroups.map((siteGroup: SiteGroup & WithId) => (
                    <SiteGroupRow
                        key={siteGroup._id}
                        siteGroup={siteGroup}
                        siteSelectOptions={siteOptions}
                        selectedSites={siteOptions.filter((options: SelectOption<string>) => siteGroup.sites.includes(options.value))}
                        updateSiteGroup={props.updateSiteGroup}
                        deleteSiteGroup={props.deleteSiteGroup}
                        enabled={enabled}
                    />
                ))}
                {addNewGroup && (
                    <SiteGroupRow
                        key="newSiteGroup"
                        enabled={enabled}
                        siteSelectOptions={siteOptions}
                        addNewSiteGroup
                        createSiteGroup={props.createSiteGroup}
                        cancelCreateSiteGroup={props.cancelCreateSiteGroup}
                    />
                )}
            </tbody>
        </Table>
    );
};
