import * as React from "react";

import { Check, Close, DeleteOutline, Edit } from "@mui/icons-material";

import { Input } from "reactstrap";
import type { InputGroupItem } from "../inputGroup.types";
import { getI18nLocaleString } from "../../../../i18n";
import namespaceList from "../../../../i18n/namespaceList";

export interface InputGroupCrudProps {
    updateInputGroup?: (id: string, inputGroupValue: string) => void;
    createInputGroup?: (newInputGroup: InputGroupItem) => void;
    cancelCreateInputGroup?: () => void;
    deleteInputGroup?: (inputGroupItem: InputGroupItem) => void;
}
export interface InputGroupRowProps extends InputGroupCrudProps {
    inputGroupItem?: InputGroupItem;
    enabled?: boolean;
}

// eslint-disable-next-line max-lines-per-function
export const InputGroupRow: React.FunctionComponent<InputGroupRowProps> = (props: InputGroupRowProps): React.ReactElement | null => {
    const { inputGroupItem, enabled } = props;
    const [groupName, setGroupName] = React.useState<string>(inputGroupItem?.value || "");
    const [editing, setEditing] = React.useState<boolean>(false);

    if (inputGroupItem && editing) {
        return (
            <tr>
                <td className="input-group__edit">
                    <Input
                        disabled={!enabled}
                        id={inputGroupItem.id}
                        name={inputGroupItem.value}
                        type="text"
                        value={groupName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setGroupName(event.target.value)}
                    />
                </td>
                <td className="input-group__action-buttons">
                    <button
                        className="btn btn-check"
                        disabled={!enabled}
                        onClick={() => {
                            props.updateInputGroup?.(inputGroupItem.id, groupName);
                            setGroupName(inputGroupItem?.value || "");
                            setEditing(false);
                        }}
                        title={getI18nLocaleString(namespaceList.admin, "done")}
                        type="button"
                    >
                        <Check />
                    </button>
                    <button
                        className="btn btn-cancel"
                        disabled={!enabled}
                        onClick={() => {
                            setGroupName(inputGroupItem?.value || "");
                            setEditing(false);
                        }}
                        title={getI18nLocaleString(namespaceList.admin, "cancel")}
                        type="button"
                    >
                        <Close />
                    </button>
                </td>
            </tr>
        );
    } else if (inputGroupItem) {
        return (
            <tr>
                <td className="input-group__item">{inputGroupItem.value}</td>
                <td className="input-group__action-buttons">
                    <button className="btn btn-edit" disabled={!enabled} onClick={() => setEditing(true)} title={getI18nLocaleString(namespaceList.admin, "edit")} type="button">
                        <Edit />
                    </button>
                    <button
                        className="btn btn-delete"
                        disabled={!enabled}
                        onClick={() => props.deleteInputGroup?.(inputGroupItem)}
                        title={getI18nLocaleString(namespaceList.admin, "delete")}
                        type="button"
                    >
                        <DeleteOutline />
                    </button>
                </td>
            </tr>
        );
    }
    return null;
};
