import * as React from "react";

import { Chip, MenuItem, Select } from "@mui/material";
import { CompositeDecorator, ContentState, Editor, EditorState, Modifier } from "draft-js";

import { CurrentLocale } from "../../app.types";
import { GenericInputProps } from "./input.types";
import { InputSpecWithTags } from "../../form-specs";
import { canOnlyRead } from "../../utils/permissions.utils";
import { getI18nLocaleString } from "../../i18n";
import { isEqual } from "lodash";
import namespaceList from "../../i18n/namespaceList";

type InputWithTagsProps<S, P extends keyof S> = GenericInputProps<S, P, InputSpecWithTags<S, P>>;

interface TagProps {
    entityKey: string;
    children: React.ReactNode;
    decoratedText: string;
    start: number;
    end: number;
    blockKey: string;
    onDelete: (blockKey: string, start: number, end: number) => void;
}
// eslint-disable-next-line max-lines-per-function
export const InputWithTags = <S, P extends keyof S>(props: InputWithTagsProps<S, P>) => {
    const tagsRef = React.useRef("");
    const { tabLocale, spec, item, value, label, permission } = props;
    const { variable, inheritFrom, tagOptions, widgetLabel } = spec;
    const getCurrentLocaleData = (field: string) => {
        const localeData = Array.isArray(item) && item.find((localizedData: CurrentLocale) => localizedData.locale === tabLocale);
        return localeData?.[field];
    };
    const isReadOnly = canOnlyRead(permission);

    const [isFocused, setIsFocused] = React.useState<boolean>(false);

    const [editorState, setEditorState] = React.useState(() => EditorState.createWithContent(ContentState.createFromText(getCurrentLocaleData(String(variable)) || "")));

    React.useEffect(() => {
        if (!value && inheritFrom) {
            const result = getCurrentLocaleData(inheritFrom);
            if (result) {
                setEditorState(EditorState.createWithContent(ContentState.createFromText(result)));
            }
        }
    }, []);

    React.useEffect(() => {
        const { value } = props;
        const currentContent = editorState.getCurrentContent().getPlainText();
        if (value && !isEqual(currentContent, value)) {
            setEditorState(EditorState.createWithContent(ContentState.createFromText(String(value))));
        }
    }, [value]);

    const handleDelete = (blockKey: string, start: number, end: number) => {
        const contentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();
        const updatedContentState = Modifier.removeRange(
            contentState,
            selectionState.merge({
                anchorKey: blockKey,
                anchorOffset: start,
                focusKey: blockKey,
                focusOffset: end,
            }),
            "backward"
        );

        const newEditorState = EditorState.push(editorState, updatedContentState, "remove-range");
        setEditorState(newEditorState);
    };

    const decorator = new CompositeDecorator([
        {
            strategy: (contentBlock, callback) => {
                const text = contentBlock.getText();
                const regex = new RegExp(`(${tagOptions?.join("|")})`, "g");
                let match;

                while ((match = regex.exec(text)) !== null) {
                    callback(match.index, match.index + match[0]?.length);
                }
            },
            component: TagComponent,
            props: {
                onDelete: handleDelete, // Pass handleDelete as onDelete prop
            },
        },
    ]);

    const handleEditorChange = (newEditorState: EditorState) => {
        setEditorState(EditorState.set(newEditorState, { decorator }));
        props.onChange(newEditorState.getCurrentContent().getPlainText(), spec);
    };

    const handleTagInsertion = (event: React.ChangeEvent<HTMLInputElement>) => {
        const tag = event.target.value;
        let currentContent = editorState.getCurrentContent();
        const selection = editorState.getSelection();
        const contentStateWithEntity = currentContent.createEntity("TAG", "IMMUTABLE", { text: tag });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        currentContent = Modifier.replaceText(currentContent, selection, `${tag} `, undefined, entityKey);
        const newEditorState = EditorState.push(editorState, currentContent, "insert-characters");
        setEditorState(() => {
            props.onChange(newEditorState.getCurrentContent().getPlainText(), spec);
            return newEditorState;
        });
        tagsRef.current = "";
    };
    return (
        <div className="seo-tag-wrap">
            <div className="seo-dropdown-label">
                {widgetLabel && <label>{getI18nLocaleString(widgetLabel?.namespace, widgetLabel?.key)}</label>}
                <div className="seo-dropdown">
                    {tagOptions && (
                        <Select
                            value={tagsRef.current}
                            onChange={handleTagInsertion}
                            fullWidth
                            className="seo-dropdown-item"
                            displayEmpty={true}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                // getContentAnchorEl: null,
                            }}
                            disabled={isReadOnly}
                            renderValue={() => <div>{getI18nLocaleString(namespaceList.admin, "dynamicTags")}</div>}
                        >
                            {tagOptions.map((tag: string) => (
                                <MenuItem className="seo-menu-item" key={tag} value={tag}>
                                    {tag}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                </div>
            </div>
            <div className="editor-wrap">
                <div className={isFocused ? "editor-with__focused focused" : "focused"}>
                    <Editor editorState={editorState} onChange={handleEditorChange} onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} placeholder={label} readOnly={isReadOnly} />
                </div>
                <div className="bar"></div>
            </div>
        </div>
    );
};

const TagComponent = (props: TagProps) => (
    <Chip contentEditable={false} color="primary" className="tag-close-icon" label={props?.children} onDelete={() => props.onDelete?.(props.blockKey, props.start, props.end)} />
);
