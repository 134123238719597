import * as React from "react";

import { Tag as ReactTag, WithContext as ReactTags } from "react-tag-input";

import { Tag as CmsApiTag } from "@maxxton/cms-api";
import { GenericInputProps } from "./input.types";
import { InputSpecTag } from "../../form-specs";
import { getDynamicTags } from "./util/tags.util";
import { wrapProps } from "../../i18n";

type TagsProps<S, P extends keyof S> = GenericInputProps<S, P, InputSpecTag<S, P>>;

interface TagsState {
    tags: CmsApiTag[];
    suggestions: CmsApiTag[];
    dynamicTags: CmsApiTag[];
}

type ExtendedReactTag = ReactTag & {
    dynamicType?: string;
    className?: string;
};

export class TagsBase<S, P extends keyof S> extends React.PureComponent<TagsProps<S, P>, TagsState> {
    constructor(props: TagsProps<S, P>) {
        super(props);
        this.state = {
            tags: [],
            suggestions: [],
            dynamicTags: [],
        };
    }

    public async componentDidMount() {
        const { spec, item } = this.props;
        const { tags, suggestions } = spec;
        this.setTags(tags(item));
        const apiSuggestions: CmsApiTag[] = await suggestions();
        this.setState({
            suggestions: apiSuggestions,
        });
        const dynamicTags = await getDynamicTags();
        this.setState({ dynamicTags });
    }

    public UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (nextProps && nextProps.spec.type === "tag" && nextProps.item && nextProps.item.tags) {
            this.setState(() => ({ tags: nextProps.item.tags }));
        } else {
            this.setState(() => ({ tags: [] }));
        }
    }

    public render(): JSX.Element | null {
        const { tags, suggestions } = this.state;
        const typedTags: ExtendedReactTag[] = tags.map((tag: CmsApiTag) => this.convertCmsApiTagToReactTag(tag));
        const typedSuggestions: ExtendedReactTag[] = suggestions.map((tag: CmsApiTag) => this.convertCmsApiTagToReactTag(tag));

        return (
            <div>
                <ReactTags tags={typedTags} suggestions={typedSuggestions} handleDelete={this.handleDelete} handleAddition={this.handleAddition} handleDrag={this.handleDrag} />
            </div>
        );
    }

    private getLabelForDynamicTag = (tag: CmsApiTag) => this.state?.dynamicTags?.find((dynamicTag) => dynamicTag?.id === tag?.id)?.text || "";

    private convertCmsApiTagToReactTag = (tag: CmsApiTag): ExtendedReactTag => {
        const { dynamicType, id, text } = tag;
        const label = dynamicType ? this.getLabelForDynamicTag(tag) : text || "";
        return {
            id: id?.toString() || "",
            text: label,
            className: dynamicType ? "dynamic-tag" : "tag",
            dynamicType,
        };
    };

    private convertReactTagToCmsApiTag = (tag: ExtendedReactTag): CmsApiTag => ({
        id: +tag.id,
        text: tag.text,
        dynamicType: tag?.dynamicType,
    });

    private setTags(tags: CmsApiTag[]): void {
        this.setState(() => ({ tags: tags || [] }));
    }

    private handleDelete = (i: number) => {
        const { tags } = this.state;
        tags.splice(i, 1);
        this.handleChange(tags);
    };

    private handleAddition = (newTag: ExtendedReactTag) => {
        const { tags } = this.state;
        const tagExists = !!tags.find((tag) => tag.text === newTag.text);
        if (!tagExists) {
            let newid = 0;
            if (tags.length) {
                newid =
                    Math.max.apply(
                        Math,
                        tags.map((tag: CmsApiTag) => tag.id)
                    ) + 1;
            }
            tags.push({
                id: newTag?.dynamicType ? +newTag?.id : newid || 1,
                text: newTag.text,
                dynamicType: newTag?.dynamicType,
            });
        }
        this.handleChange(tags);
    };

    private handleDrag = (tag: ExtendedReactTag, currPos: number, newPos: number) => {
        const cmsApiTag: CmsApiTag = this.convertReactTagToCmsApiTag(tag);
        const tags = this.state.tags;
        tags.splice(currPos, 1);
        tags.splice(newPos, 0, cmsApiTag);
        this.handleChange(tags);
    };

    private handleChange = (tags: CmsApiTag[]) => {
        this.setState((state) => {
            const { onChange, spec } = this.props;
            onChange(tags, spec);
            return { ...state, tags };
        });
    };
}

export const TagInput = wrapProps<GenericInputProps<any, any, InputSpecTag<any, any>>>(TagsBase);
