import { ApiCallOptions, DocumentType, MxtsApiWrapper, PagedResult } from "@maxxton/cms-mxts-api";

// import { MXTS } from "./constants";
import { getRemainingPageIndexes } from "./mxtsPage.util";

// const { PAGE_REQUEST_SIZE } = MXTS;

export const lazyLoadAllDocuments = async ({
    mxtsApi,
    env,
    headingId,
    callback,
    abortSignal,
}: {
    mxtsApi: MxtsApiWrapper;
    env: ApiCallOptions;
    headingId: number;
    callback: ({ documents, done }: { documents: DocumentType[]; done: boolean }) => void;
    abortSignal: AbortSignal;
}): Promise<DocumentType[]> => {
    // TODO: Replace 7 with PAGE_REQUEST_SIZE as getDocuments fails with 50 items.
    // This needs to be removed when MXTS side changes are done to make this API faster
    const intialDocumentsResponse = await mxtsApi.documents(env, { size: 7, headingId }, undefined, abortSignal).then((documentResponse: PagedResult<DocumentType>) => documentResponse);
    const { number: pageIndex, totalPages } = intialDocumentsResponse;
    callback({ documents: intialDocumentsResponse.content, done: pageIndex === totalPages - 1 });
    const remainingDocumentPageIndexes = getRemainingPageIndexes({ pageIndex, totalPages });
    const remainingDocuments: DocumentType[][] = [];
    for (const pageIndex of remainingDocumentPageIndexes) {
        //  each promise are now waiting on the previous promise to resolve as it is a gallery
        const documents = await mxtsApi
            .documents(env, { size: 7, page: pageIndex, headingId }, undefined, abortSignal)
            .then((documentResponse: PagedResult<DocumentType>) => documentResponse.content)
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.error(`Couldn't load documents from page ${pageIndex} with error: `, err);
                return [];
            });
        callback({ documents, done: pageIndex === totalPages - 1 });
        remainingDocuments.push(documents);
    }

    const allDocuments = [...intialDocumentsResponse.content, ...remainingDocuments.flat()];
    return allDocuments;
};
