import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classnames from "classnames";

import { SortEnd, arrayMove } from "react-sortable-hoc";

import { Badge } from "reactstrap";
import { GenericInputProps } from "./input.types";
import { InputSpecJSONSelector } from "../../form-specs";
import Inspector from "react-json-inspector";
import { SortableList } from "./";

type JSONSelectorProps<S, P extends keyof S> = GenericInputProps<S, P, InputSpecJSONSelector<S, P>>;

interface JSONSelectorState {
    data?: any;
    isLoading?: boolean;
}

export class JSONSelector<S, P extends keyof S> extends React.PureComponent<JSONSelectorProps<S, P>, JSONSelectorState> {
    constructor(props: JSONSelectorProps<S, P>) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    public componentDidMount() {
        const { loadData } = this.props.spec;
        const { item, tabLocale } = this.props;
        if (loadData) {
            this.setState({ isLoading: true });
            loadData(item, tabLocale).then((data) => {
                this.setState({ data, isLoading: false });
            });
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: JSONSelectorProps<S, P>) {
        const { loadData } = nextProps.spec;
        const { item, tabLocale } = nextProps;
        const oldUrl = (this.props.item as any).find((lc: any) => lc.locale === tabLocale).agendaLink;
        const newUrl = (nextProps.item as any).find((lc: any) => lc.locale === tabLocale).agendaLink;
        if (loadData && oldUrl !== newUrl) {
            this.setState({ isLoading: true });
            loadData(item, tabLocale).then((data) => {
                this.setState({ data, isLoading: false });
            });
        }
    }

    public render(): JSX.Element | null {
        const { data, isLoading } = this.state;
        const { value } = this.props;
        return isLoading ? (
            <FontAwesome name="spinner" className={classnames("searchfacet-progress", isLoading ? "in-progress" : "no-progress")} />
        ) : data ? (
            <div>
                {value && (
                    <SortableList
                        items={(value as any).map((term: any, index: number) => (
                            <Badge key={index} className="json-term-badge" color="primary">
                                {term.split(".")[term.split(".").length - 1] + " "}
                                <FontAwesome onClick={this.removeSelected.bind(this, term)} name="times-circle" />
                            </Badge>
                        ))}
                        onSortEnd={this.onSortEnd}
                        axis="x"
                        distance={10}
                        transitionDuration={500}
                        helperClass="sortableDiv"
                    />
                )}
                <Inspector data={data} onClick={this.handleClick} />
            </div>
        ) : null;
    }

    private onSortEnd = (sort: SortEnd): void => {
        const { spec, value } = this.props;
        const items = arrayMove(value as any, sort.oldIndex, sort.newIndex);
        this.props.onChange(items, spec);
    };

    private removeSelected = (term: string) => {
        const { spec, value } = this.props;
        const newSelectedTerms = (value as any).filter((val: any) => val !== term);
        this.props.onChange(newSelectedTerms, spec);
    };

    private handleClick = (args: any) => {
        const { spec, value } = this.props;
        const olderSelectedTerms = value ? [...(value as any)] : [];
        const pathWithoutIndices = olderSelectedTerms.map((term) => term.replace(/.[0-9]./g, "."));
        if (typeof args.value !== "object" && !(args.value instanceof Array)) {
            if (pathWithoutIndices.indexOf(args.path.replace(/.[0-9]./g, ".")) === -1) {
                olderSelectedTerms.push(args.path);
            }
            this.props.onChange(olderSelectedTerms, spec);
        }
    };
}
