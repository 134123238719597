import * as React from "react";

import {
    AddTwoTone,
    Close,
    CloudUpload,
    ContentCopy,
    DeleteOutline,
    DesktopWindowsOutlined,
    Edit,
    KeyboardArrowDown,
    KeyboardArrowUp,
    Laptop,
    OpenInNew,
    Smartphone,
    Tablet,
    Visibility,
    VisibilityOff,
} from "@mui/icons-material";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { CmsApi, Options, Page, PageApi, PostApi, Template, TemplateApi, WithId } from "@maxxton/cms-api";
import { FormSpec, HierarchyOptions, HierarchyTemplate, InputSpecHierarchy } from "../../form-specs";
import { LinkedToWidgetIndicator, applyLinkedWidgetsOfEditedItem } from "./hierarchyLinkedWidget.util";
import { generateRandomKey, sanitizeWidgetItems } from "../utils";
import { getI18nLocaleString, getI18nLocaleStringFromParams, wrapProps } from "../../i18n";
import { isEqual, set, uniqueId } from "lodash";

import { Alerts } from "../../alerts";
import { CMSAware } from "../../containers/CmsProvider";
import { CMSProvidedProperties } from "../../containers/cmsProvider.types";
import { ContextSlice } from "../../plugins";
import { ErrorBoundary } from "../ErrorBoundary";
import { FlexboxOptions } from "../../plugins/page/flexbox";
import { GenericInputProps } from "./input.types";
import { cancelable } from "../../promise";
import loadable from "@loadable/component";
import { loadableRetry } from "../../utils/loadableComponents.util";
import namespaceList from "../../i18n/namespaceList";

const GenericFormModal = loadable(() => loadableRetry(() => import("./modal")), {
    resolveComponent: ({ GenericFormModal }) => GenericFormModal,
});

export interface TreeItem<E> {
    options?: Options;
    item?: any;
    title?: string;
    subtitle?: string;
    additionalInfo?: string;
    expanded?: boolean;
    children: Array<TreeItem<E>>;
    className?: string;
    isPageRedirect?: boolean;
    isPagePost?: boolean;

    type: "root" | "templates" | "template" | "item";
}

interface TemplateWidgetTreeItem<E> {
    item: E;
    hierarchy: Array<ItemTreeItem<E>>;
    children: Array<TemplateWidgetTreeItem<E>>;
}
interface SiteMapSplit<E> {
    sitemapPages: Array<TreeItem<E>>;
    redirectPages: Array<TreeItem<E>>;
    postPages: Array<TreeItem<E>>;
}

interface ItemTreeItem<E> extends TreeItem<E> {
    type: "item";
    item: E;
}

interface TemplateTreeItem<E> extends TreeItem<E> {
    type: "template";
    template: HierarchyTemplate<E>;
}

function isItemTreeItem<E>(item: TreeItem<E>): item is ItemTreeItem<E> {
    return item.type === "item";
}

function isTemplateTreeItem<E>(item: TreeItem<E>): item is TemplateTreeItem<E> {
    return item.type === "template";
}

type HierarchyProps<E, P extends keyof E> = GenericInputProps<E, P, InputSpecHierarchy<E, P>> & CMSProvidedProperties;

interface HierarchyState<E> {
    templates: TreeItem<E> | null;
    treeData0: Array<TreeItem<E>> | null;
    treeData1: Array<TreeItem<E>> | null;
    editItem: E | null;
    newTemplateRoot: TreeItem<E> | null;
    newTemplateName: string;
    searchStringTreeData0?: string;
    searchTreeData0FocusIndex: number;
    searchTreeData0FoundCount: number;
    searchStringTreeData1?: string;
    searchTreeData1FocusIndex: number;
    searchTreeData1FoundCount: number;
    isOpenModal: boolean;
    node: TreeItem<E> | null;
    loadForm: string;
    isChecked: boolean;
    isOpen: boolean;
    hierarchyOptionsItemOptions: FormSpec<any> | null;
}

// SortableTree must be required dynamically
let SortableTree: any = null;
const showMomentMobile = ["dk-hidden-s", "show-xs-only"];
const showMomentType: Record<string, string> = {
    "dk-hidden-s": getI18nLocaleString(namespaceList.widgetFlexbox, "hiddenSmall"),
    "dk-hidden-m": getI18nLocaleString(namespaceList.widgetFlexbox, "hiddenMedium"),
    "dk-hidden-l": getI18nLocaleString(namespaceList.widgetFlexbox, "hiddenLarge"),
    "dk-hidden-from-l": getI18nLocaleString(namespaceList.widgetFlexbox, "hiddenFromLarge"),
    "dk-hidden-from-desktop": getI18nLocaleString(namespaceList.widgetFlexbox, "hiddenFromDesktop"),
    "show-xs-only": getI18nLocaleString(namespaceList.widgetFlexbox, "showForSmall"),
    "show-tablet-only": getI18nLocaleString(namespaceList.widgetFlexbox, "showForTablet"),
    "hidden-till-tablet": getI18nLocaleString(namespaceList.widgetFlexbox, "hiddenTillTablet"),
};
const showMomentTablet = ["show-tablet-only", "dk-hidden-l", "dk-hidden-from-desktop"];
const showMomentTv = ["dk-hidden-from-l"];
export enum WidgetType {
    PAGE = "page",
    RESULTPANEL = "resultsPanel",
    FLOW = "flow",
    SITEMAP = "sitemap",
}

function mapTree<E>(treeData: TreeItem<E>, mapping: (item: TreeItem<E>) => TreeItem<E>): TreeItem<E> {
    return mapping({
        ...treeData,
        children: treeData.children.filter((item) => item !== null).map((child) => mapTree(child, mapping)),
    });
}

async function asyncMapTree<E>(treeData: TreeItem<E>, mapping: (item: TreeItem<E>) => Promise<TreeItem<E>>): Promise<TreeItem<E>> {
    return mapping({
        ...treeData,
        // eslint-disable-next-line max-len
        children: await Promise.all(treeData.children.filter((item) => item !== null).map((child) => asyncMapTree(child, mapping))),
    });
}

function copyTree<E>(treeData: TreeItem<E>): TreeItem<E> {
    return mapTree(treeData, (item) => item);
}

function copyTreeState<E>(from: TreeItem<E>, to: TreeItem<E>): TreeItem<E> {
    const stateProps: Array<keyof TreeItem<E>> = ["expanded"];
    for (const prop of stateProps) {
        (to as any)[prop] = from[prop];
    }
    if (from.children.length === to.children.length) {
        from.children.forEach((fromChild, ind) => copyTreeState(fromChild, to.children[ind]));
    }
    return to;
}

function valueFromTreeData<E>(treeData: ItemTreeItem<E> | null, options: HierarchyOptions<E>): E | null {
    if (treeData == null) {
        return null;
    }
    const item: E = { ...(treeData.item as any) };
    (item as any)[options.childVariable] = treeData.children.filter((child) => child !== null).map((child: TreeItem<E>) => valueFromTreeData(child as ItemTreeItem<E>, options));
    return item;
}

async function treeDataFromValue<E>(item: E & WithId & { type?: string; options?: FlexboxOptions }, options: HierarchyOptions<E>, context: ContextSlice): Promise<ItemTreeItem<E>> {
    const children: E[] = item[options.childVariable] as any;
    const randomKey = generateRandomKey();
    return {
        type: "item",
        title: getI18nLocaleStringFromParams(await options.itemTitle({ item, context })),
        subtitle: options.itemSubtitle !== undefined ? getI18nLocaleStringFromParams(await options.itemSubtitle({ item, context })) : "",
        additionalInfo: options.itemAdditionalInfo ? await options.itemAdditionalInfo({ item, context }) : "",
        expanded: true,
        // eslint-disable-next-line max-len
        children: await Promise.all(children.filter((item) => item !== null).map((child: E & WithId) => treeDataFromValue(child, options, context))),
        item,
        className:
            item.options?.showMoment && Object.keys(showMomentType).includes(item.options?.showMoment) && !item._id
                ? "template-node device-wrapper device-wrapper_" + randomKey
                : !item._id
                ? "template-node device-wrapper_" + randomKey
                : item?.type === "flexbox"
                ? "device-wrapper_" + item._id
                : "",
    };
}

function mapTemplateItem<E>(tmpl: HierarchyTemplate<E>): TemplateTreeItem<E> {
    return {
        type: "template",
        template: tmpl,
        title: getI18nLocaleStringFromParams(tmpl.title),
        subtitle: getI18nLocaleStringFromParams(tmpl.description),
        children: tmpl.children.filter((child) => child !== null).map(mapTemplateItem),
    };
}

async function rootItemFromValue<E>(item: E & WithId, options: HierarchyOptions<E>, context: ContextSlice, title?: string): Promise<TreeItem<E>> {
    let children: Array<TreeItem<E>> = [];
    if (options.rootType === "single" && item != null) {
        children = [await treeDataFromValue(item, options, context)];
    } else if (options.rootType === "multiple" && Array.isArray(item)) {
        // eslint-disable-next-line max-len
        children = await Promise.all(item.filter((child) => child !== null).map((child) => treeDataFromValue(child, options, context)));
    }
    const rootTitle = getI18nLocaleStringFromParams(options.rootTitle);
    return {
        type: "root",
        title: rootTitle === getI18nLocaleString(namespaceList.admin, "sitemap") || rootTitle === getI18nLocaleString(namespaceList.admin, "pageRedirect") ? title : rootTitle,
        subtitle: options.rootSubtitle ? getI18nLocaleStringFromParams(options.rootSubtitle) : undefined,
        expanded: true,
        children,
    };
}
async function rootItemFromValueSplit<E>(item: E & WithId, options: HierarchyOptions<E>, alerts: Alerts | Array<{ color: string; message: string }>): Promise<SiteMapSplit<E>> {
    const sitemapPages: Array<TreeItem<E>> = [];
    let redirectPages: Array<TreeItem<E>> = [];
    const postPages: Array<TreeItem<E>> = [];
    const clonePages: Array<TreeItem<E>> = [];
    const pageIds: String[] = [];
    let pageCount = 0;
    if (item && Array.isArray(item)) {
        const postApiPages = await PostApi.find({ projection: { pageId: 1 } });
        const siteMapData =
            !item[0]?.hasOwnProperty("isPageRedirect") &&
            !item[1]?.hasOwnProperty("isPageRedirect") &&
            (await PageApi.find({ query: { enableRedirect: true }, projection: { enableRedirect: 1, name: 1 } }));
        await Promise.all(
            item.map(async (child, index: number) => {
                const isRedirectEnabled: boolean = siteMapData
                    ? siteMapData.find((element) => element._id === child.options?.pageId && !element?.enableRedirect)
                    : !child.hasOwnProperty("isPageRedirect") && !child.hasOwnProperty("_id")
                    ? child.options?.pageId && !(await checkPageRedirect(child.options.pageId))
                    : !child.isPageRedirect;
                if (isRedirectEnabled) {
                    child.isPageRedirect = false;
                    if (postApiPages.find((post) => post.pageId === child.options?.pageId)) {
                        postPages[index] = child;
                        child.isPagePost = true;
                    } else {
                        child.isPagePost = false;
                        sitemapPages[index] = child;
                    }
                } else {
                    if (siteMapData && !siteMapData.find((element) => element._id === child.options?.pageId)) {
                        child.isPageRedirect = false;
                        if (postApiPages.find((post) => post.pageId === child.options?.pageId)) {
                            postPages[index] = child;
                            child.isPagePost = true;
                        } else {
                            sitemapPages[index] = child;
                        }
                        return false;
                    }
                    child.isPageRedirect = true;
                    if (child) {
                        pageCount++;
                        pageIds[index] = child.options?.pageId;
                        if ((!child.hasOwnProperty("_id") && child.hasOwnProperty("__v")) || child?.hasOwnProperty("updatedAt")) {
                            clonePages[index] = child;
                        } else {
                            redirectPages[index] = child;
                        }
                    }
                }
            })
        );
    }

    redirectPages = redirectPages.filter((child, index) => !pageIds.includes(child.options?.pageId, index + 1));
    redirectPages.push(...clonePages);
    redirectPages = redirectPages.filter((element) => element !== undefined);
    if (pageCount > redirectPages.length) {
        const alerted = localStorage.getItem("showRedirectMovedNotice") || "";
        if (alerted !== "yes") {
            alerts.push({ color: "info", message: getI18nLocaleString(namespaceList.admin, "pageRedirectMessage") });
            localStorage.setItem("showRedirectMovedNotice", "yes");
        }
    }
    return {
        sitemapPages,
        redirectPages,
        postPages,
    };
}

function isSiteMapHierarchy(spec: InputSpecHierarchy<any, any>): boolean {
    return getI18nLocaleStringFromParams(spec.hierarchyOptions.rootTitle) === getI18nLocaleString(namespaceList.admin, "sitemap");
}
async function getTemplateWidgetTreeItem<E>(child: TreeItem<E[]>, options: HierarchyOptions<E>, context: ContextSlice): Promise<TemplateWidgetTreeItem<E>> {
    return {
        item: (child as any).item,
        hierarchy: await replaceTemplateWidgetToHierarchy((child as any).item, options, context),
        children: await Promise.all(child.children.filter((item) => item !== null).map((childItem: TreeItem<E[]>) => getTemplateWidgetTreeItem(childItem, options, context))),
    };
}

async function checkPageRedirect(_id: string): Promise<boolean> {
    const page: (Page & WithId) | null = await PageApi.findById({ id: _id });
    return !!page?.enableRedirect;
}
function updateTemplateWidgetToHierarchy<E>(item: TreeItem<E>, result: Array<ItemTreeItem<any>>): Array<TreeItem<E>> {
    if ((item as any).item.type === "template" && (item as any).item.options.layHierarchy) {
        return result;
    }
    if (item.children && item.children.length > 0) {
        let finalChildrens: Array<TreeItem<E>> = [];
        item.children.forEach((child) => {
            const data = updateTemplateWidgetToHierarchy(child, result);
            finalChildrens = finalChildrens.concat((child as any).item.type === "template" && (child as any).item.options.layHierarchy ? result : data);
        });
        item.children = finalChildrens;
        return [item];
    }
    return [item];
}

async function replaceTemplateWidgetToHierarchy<E>(value: E, options: HierarchyOptions<E>, context: ContextSlice): Promise<Array<ItemTreeItem<E>>> {
    if (value && (value as any).type === "template" && (value as any).options.layHierarchy) {
        const arr: Array<Promise<ItemTreeItem<E>>> = [];
        const itemTemplate = await TemplateApi.findById({ id: (value as any).options.templateId });
        const clone = { ...itemTemplate };
        if (clone && (clone as any).root) {
            for (const widget of (clone as any).root) {
                const sanitzedWidget = sanitizeWidgetItems(widget);
                arr.push(treeDataFromValue(sanitzedWidget, options, context));
            }
        }
        return Promise.all(arr);
    }
    return [];
}

function getTemplateHierarchy<E>(item: TemplateWidgetTreeItem<E>): Array<ItemTreeItem<E>> {
    if (item.hierarchy.length > 0) {
        return item.hierarchy;
    }
    let hierarchy: Array<ItemTreeItem<E>> = [];
    if (item.children && item.children.length > 0) {
        item.children.forEach((child) => {
            const result = getTemplateHierarchy(child);
            if (result.length > 0) {
                hierarchy = result;
                return;
            }
        });
    }
    return hierarchy;
}

/**
 * returns either the parent of item, null if it has no parent, or undefined if the item cannot be found
 * @param node the node in which the item parent is searched for
 * @param item the item of which the parent is requested
 */
function findParent<E>(node: TreeItem<E>, item: E): E | null | undefined {
    const nodeItem = isItemTreeItem(node) ? node.item : null;
    for (const child of node.children) {
        if (isItemTreeItem(child)) {
            if (child.item === item) {
                return nodeItem;
            }
            const found = findParent(child, item);
            if (found !== undefined) {
                return found;
            }
        }
    }
}

class HierarchyBase<E, P extends keyof E> extends React.PureComponent<HierarchyProps<E, P>, HierarchyState<E[P]>> {
    private _isMovedPage = false;
    constructor(props: HierarchyProps<E, P>) {
        super(props);
        this.state = {
            templates: null,
            treeData0: null,
            treeData1: null,
            editItem: null,
            newTemplateRoot: null,
            newTemplateName: "",
            searchStringTreeData0: "",
            searchTreeData0FocusIndex: 0,
            searchTreeData0FoundCount: 0,
            searchStringTreeData1: "",
            searchTreeData1FocusIndex: 0,
            searchTreeData1FoundCount: 0,
            isOpenModal: false,
            node: null,
            loadForm: "listOpen",
            isChecked: false,
            isOpen: false,
            hierarchyOptionsItemOptions: null,
        };
    }

    public componentDidMount() {
        const { currentLocale, site } = this.props;
        const context = { currentLocale, site, cmsApi: CmsApi };
        if (SortableTree === null) {
            SortableTree = require("react-sortable-tree").default;
        }
        const [templateProm, cancelTemplates] = cancelable(this.createTemplates({ context }));
        this.cancelTemplates = cancelTemplates;
        templateProm.then((templates) => {
            this.setState({ templates }, () => {
                this.updateTreeFromValue(this.props);
            });
        });
    }

    public componentWillUnmount() {
        this.cancelTemplates();
    }

    public UNSAFE_componentWillReceiveProps(nextProps: HierarchyProps<E, P>) {
        const { spec, value } = this.props;
        if (!isEqual(spec, nextProps.spec) || !isEqual(value, nextProps.value)) {
            this.updateTreeFromValue(nextProps);
        }
    }
    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const defaultPlaceholder = "Please Drag-Drop widgets to create the page";
        const { spec, item, tabLocale, permission } = this.props;
        const {
            treeData0,
            editItem,
            newTemplateRoot,
            newTemplateName,
            searchStringTreeData0,
            searchStringTreeData1,
            searchTreeData0FocusIndex,
            searchTreeData1FocusIndex,
            searchTreeData0FoundCount,
            searchTreeData1FoundCount,
            isChecked,
            isOpen,
            hierarchyOptionsItemOptions,
        } = this.state;
        if (treeData0 === null || SortableTree === null) {
            return <div />;
        }
        const slash = "/";
        return (
            <ErrorBoundary>
                <div className="hierarchy-element">
                    <GenericFormModal
                        isOpen={isOpen}
                        toggle={this.handleEditToggle}
                        title={getI18nLocaleString(namespaceList.admin, "edit")}
                        spec={editItem !== null ? hierarchyOptionsItemOptions : (null as any)}
                        mode="admin_edit"
                        value={editItem !== null ? (editItem as any)[spec.hierarchyOptions.optionsVariable] : null}
                        rootValue={item}
                        onSave={this.handleItemSave}
                        onCancel={this.handleEditCancel}
                        specType={spec.widgetType}
                        editItemType={editItem}
                        tabLocale={tabLocale}
                        permission={permission}
                    />
                    <Modal className="admin-side-modal" isOpen={newTemplateRoot != null} toggle={this.handleTemplateToggle} size="md" backdrop="static">
                        <ModalHeader tag="h4" toggle={this.handleTemplateToggle}>
                            {getI18nLocaleString(namespaceList.admin, "createTemplate")}
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-elements">
                                <div className="row form-group mt-2">
                                    <Input type="text" id="tempId" value={newTemplateName} onChange={this.handleNewTemplateNameChange} />
                                    <div className="bar" />
                                    <label htmlFor="tempId" className="input-label text-labels  form-control-label">
                                        {getI18nLocaleString(namespaceList.admin, "name")}
                                    </label>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button className="publish" color="primary" onClick={this.handleTemplateSave}>
                                <CloudUpload />
                                {getI18nLocaleString(namespaceList.admin, "save")}
                            </Button>
                            <Button className="cancel" color="secondary" onClick={this.handleTemplateCancel}>
                                <Close />
                                {getI18nLocaleString(namespaceList.admin, "cancel")}
                            </Button>
                        </ModalFooter>
                    </Modal>
                    <div className="widget-searchbox row">
                        <div className="col-sm-12 widget-searchbox-wrap">
                            <span className="search-input">
                                <Input id="find-box" type="text" value={searchStringTreeData0} onChange={this.handleTreeData0SearchTextChange} />
                                {this.state.searchStringTreeData0 && <Close onClick={this.clearSearchText0 as any} />}
                            </span>
                            <Button type="button" color="primary" disabled={!searchTreeData0FoundCount} onClick={this.selectPrevMatch.bind(this, true)}>
                                <KeyboardArrowUp />
                            </Button>

                            <Button type="button" color="primary" onClick={this.selectNextMatch.bind(this, true)}>
                                <KeyboardArrowDown />
                            </Button>
                            <span>
                                {searchTreeData0FoundCount > 0 ? searchTreeData0FocusIndex + 1 : 0}
                                {slash}
                                {searchTreeData0FoundCount || 0}
                            </span>
                        </div>
                    </div>
                    {treeData0[0].children && treeData0[0].children.length < 1 && (
                        <div className="widget-draggable-area">
                            <span>{defaultPlaceholder}</span>
                        </div>
                    )}
                    <SortableTree
                        treeData={this.state.treeData0}
                        onChange={this.handleChange}
                        generateNodeProps={this.nodePropGenerator}
                        canDrag={this.canDrag}
                        canDrop={this.canDrop}
                        onDragStateChanged={this.onDragEnd}
                        dndType="mcms"
                        shouldCopyOnOutsideDrop={true}
                        searchMethod={this.handleTreeDataCustomSearch}
                        searchQuery={searchStringTreeData0}
                        searchFocusOffset={searchTreeData0FocusIndex}
                        searchFinishCallback={this.handleTreeData0SearchFinish}
                        className="tree-page"
                    />
                    <div className="wrap-collapse wrap-collapse__widgets">
                        <input onChange={this.toggleChanged} checked={isChecked} id="collapseWidgets" className="toggle" type="checkbox" />
                        <label htmlFor="collapseWidgets" className="label-toggle">
                            <span>{getI18nLocaleString(namespaceList.admin, "collapseWidgets")}</span>
                        </label>
                        <div className="collapse-toggle-content">
                            <div className="widget-searchbox row">
                                <div className="col-sm-12 widget-searchbox-wrap">
                                    <span className="search-input">
                                        <Input id="find-box" type="text" value={searchStringTreeData1} onChange={this.handleTreeData1SearchTextChange} />
                                        {this.state.searchStringTreeData1 && <Close onClick={this.clearSearchText1 as any} />}
                                    </span>
                                    <Button type="button" color="primary" disabled={!searchTreeData1FoundCount} onClick={this.selectPrevMatch.bind(this, false)}>
                                        <KeyboardArrowUp />
                                    </Button>

                                    <Button type="button" color="primary" onClick={this.selectNextMatch.bind(this, false)}>
                                        <KeyboardArrowDown />
                                    </Button>
                                    <span>
                                        {searchTreeData1FoundCount > 0 ? searchTreeData1FocusIndex + 1 : 0}
                                        {slash}
                                        {searchTreeData1FoundCount || 0}
                                    </span>
                                </div>
                            </div>
                            <Modal isOpen={this.state.isOpenModal} className="confirmation-modal" toggle={this.toggle} size="lg" backdrop="static">
                                <ModalHeader tag="h4" toggle={this.toggle}>
                                    {getI18nLocaleString(namespaceList.admin, "warning")}
                                </ModalHeader>
                                <ModalBody>
                                    <div>{getI18nLocaleString(namespaceList.admin, "confirmationMessage")}</div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button className="publish" color="primary" onClick={this.removeNode(this.state.node)}>
                                        <DeleteOutline />
                                        {getI18nLocaleString(namespaceList.admin, "delete")}
                                    </Button>
                                    <Button className="cancel" color="secondary" onClick={this.toggle}>
                                        <Close />
                                        {getI18nLocaleString(namespaceList.admin, "cancel")}
                                    </Button>
                                </ModalFooter>
                            </Modal>
                            <SortableTree
                                treeData={this.state.treeData1!}
                                onChange={this.handleChange}
                                generateNodeProps={this.nodePropGenerator}
                                canDrag={this.canDrag}
                                canDrop={this.canDrop}
                                onDragStateChanged={this.onDragEnd}
                                dndType="mcms"
                                shouldCopyOnOutsideDrop={true}
                                searchMethod={this.handleTreeDataCustomSearch}
                                searchQuery={searchStringTreeData1}
                                searchFocusOffset={searchTreeData1FocusIndex}
                                searchFinishCallback={this.handleTreeData1SearchFinish}
                                className="tree-widgets"
                            />
                        </div>
                    </div>
                </div>
            </ErrorBoundary>
        );
    }
    private toggleChanged = () => {
        this.setState({ isChecked: !this.state.isChecked });
    };
    private getReactElementText = (parent: any) => {
        if (typeof parent === "string") {
            return parent;
        }

        if (typeof parent !== "object" || !parent.props || !parent.props.children || (typeof parent.props.children !== "string" && typeof parent.props.children !== "object")) {
            return "";
        }

        if (typeof parent.props.children === "string") {
            return parent.props.children;
        }

        return parent.props.children.map((child: any) => this.getReactElementText(child)).join("");
    };

    // Search for a query string inside a node property
    private stringSearch = (key: any, searchQuery: string, node: any, path: any, treeIndex: number) => {
        if (typeof node[key] === "function") {
            // Search within text after calling its function to generate the text
            return String(node[key]({ node, path, treeIndex })).toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
        } else if (typeof node[key] === "object") {
            // Search within text inside react elements
            return this.getReactElementText(node[key]).toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
        } else if (searchQuery === "") {
            return false;
        }

        // Search within string
        return node[key] && String(node[key]).toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
    };

    // eslint-disable-next-line max-len
    private handleTreeDataCustomSearch = ({ node, path, treeIndex, searchQuery }: { node: any; path: any; treeIndex: number; searchQuery: string }) =>
        this.stringSearch("title", searchQuery, node, path, treeIndex) ||
        this.stringSearch("subtitle", searchQuery, node, path, treeIndex) ||
        this.stringSearch("additionalInfo", searchQuery, node, path, treeIndex);

    private cancelTemplates: () => void = () => undefined;

    private handleEditToggle = (isSaved: boolean) => {
        const { spec } = this.props;
        localStorage.removeItem("editWidgetPopUp");
        let editItem = this.state.editItem;
        if (!isSaved) {
            editItem = null;
        }
        this.setState({ isOpen: !this.state.isOpen, editItem }, () => {
            if (!isSaved) {
                if (isSiteMapHierarchy(spec) && this.state.treeData0![1] && this.state.treeData0![2]) {
                    this.handleChange([this.state.treeData0![0], this.state.treeData0![1], this.state.treeData0![2], this.state.treeData1![0]], "formEditCancelled");
                } else {
                    this.handleChange([this.state.treeData0![0], this.state.treeData1![0]], "formEditCancelled");
                }
            }
        });
    };

    private handleEditCancel = () => {
        const { spec } = this.props;
        if (spec.widgetType === WidgetType.PAGE) {
            this.handleChange([this.state.treeData0![0], this.state.treeData1![0]], "formEditCancelled");
        } else {
            this.setState({ editItem: null, isOpen: false }, () => {
                // Make sure Alert popup not visible in case user hit cancel
                if (isSiteMapHierarchy(spec) && this.state.treeData0![1]) {
                    this.handleChange([this.state.treeData0![0], this.state.treeData0![1], this.state.treeData0![2], this.state.treeData1![0]], "formEditCancelled");
                } else {
                    this.handleChange([this.state.treeData0![0], this.state.treeData1![0]], "formEditCancelled");
                }
            });
        }
    };

    private handleTemplateToggle = () => {
        this.setState({ newTemplateRoot: null });
    };

    private handleTemplateCreate(node: TreeItem<E[P]>): () => void {
        return () => {
            this.setState({ newTemplateRoot: node });
        };
    }
    private handleCreateInterlinking(node: TreeItem<E[P]>, specType?: string) {
        const protocol = window.location.hostname.indexOf("local") < 0 ? "https" : "http";
        let pageId = "";
        let pageName = "";

        if (node.title?.toLowerCase() === getI18nLocaleString(namespaceList.admin, "webcontent").toLowerCase() || node?.item?.type === "webcontent") {
            pageId = node.item.options.webContentId;
            pageName = "webcontent";
        } else if (node.title?.toLowerCase() === getI18nLocaleString(namespaceList.admin, "templates").toLowerCase() || node?.item?.type === "template") {
            pageId = node.item.options.templateId;
            pageName = "template";
        } else if (node.title?.toLowerCase() === getI18nLocaleString(namespaceList.admin, "page").toLowerCase() || node.title === "Page" || specType === "flow") {
            pageId = node.item.options.pageId;
            pageName = "page";
        } else if (node.title?.toLowerCase() === getI18nLocaleString(namespaceList.admin, "post").toLowerCase() || node.item?.isPagePost || node.item?.options.postId) {
            pageId = node.item?.options?.postId || node.item.options.pageId;
            pageName = "post";
        }
        const redirectUrl = pageId && `${protocol}://${window.location.hostname}/webmanager/${pageName}/edit/${pageId}`;
        window.open(redirectUrl, "_blank");
    }

    private handleTemplateSave = () => {
        this.createTemplate();
        this.handleTemplateToggle();
    };

    private handleTemplateCancel = () => {
        this.handleTemplateToggle();
    };

    private handleTreeData0SearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value || value === "") {
            this.setState({ searchStringTreeData0: value });
        }
    };

    private clearSearchText0 = () => {
        this.setState({ searchStringTreeData0: "" });
    };

    private handleTreeData1SearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value || value === "") {
            this.setState({ searchStringTreeData1: value });
        }
    };

    private clearSearchText1 = () => {
        this.setState({ searchStringTreeData1: "" });
    };

    private handleTreeData0SearchFinish = (matches: any) => {
        this.setState({
            searchTreeData0FoundCount: matches.length,
            searchTreeData0FocusIndex: matches.length > 0 ? this.state.searchTreeData0FocusIndex % matches.length : 0,
        });
    };

    private handleTreeData1SearchFinish = (matches: any) => {
        this.setState({
            searchTreeData1FoundCount: matches.length,
            searchTreeData1FocusIndex: matches.length > 0 ? this.state.searchTreeData1FocusIndex % matches.length : 0,
        });
    };

    private selectPrevMatch = (isTree0: boolean) => {
        if (isTree0) {
            this.setState({
                searchTreeData0FocusIndex:
                    this.state.searchTreeData0FocusIndex !== null
                        ? // eslint-disable-next-line max-len
                          (this.state.searchTreeData0FoundCount + this.state.searchTreeData0FocusIndex - 1) % this.state.searchTreeData0FoundCount
                        : this.state.searchTreeData0FoundCount - 1,
            });
        } else {
            this.setState({
                searchTreeData1FocusIndex:
                    this.state.searchTreeData1FocusIndex !== null
                        ? // eslint-disable-next-line max-len
                          (this.state.searchTreeData1FoundCount + this.state.searchTreeData1FocusIndex - 1) % this.state.searchTreeData1FoundCount
                        : this.state.searchTreeData1FoundCount - 1,
            });
        }
    };

    private selectNextMatch = (isTree0: boolean) => {
        if (isTree0) {
            this.setState({
                searchTreeData0FocusIndex: this.state.searchTreeData0FocusIndex !== null ? (this.state.searchTreeData0FocusIndex + 1) % this.state.searchTreeData0FoundCount : 0,
            });
        } else {
            this.setState({
                searchTreeData1FocusIndex: this.state.searchTreeData1FocusIndex !== null ? (this.state.searchTreeData1FocusIndex + 1) % this.state.searchTreeData1FoundCount : 0,
            });
        }
    };

    private async createTemplates({ context }: { context: ContextSlice }): Promise<TreeItem<E[]>> {
        const { spec, item } = this.props;
        const options = spec.hierarchyOptions;
        const excludeIds = item?._id ? [item._id] : undefined;
        return {
            type: "templates",
            title: getI18nLocaleStringFromParams(options.templatesTitle),
            subtitle: options.templatesSubtitle ? options.templatesSubtitle : undefined,
            expanded: true,
            children: (await options.templates({ context, excludeIds })).map(mapTemplateItem),
        };
    }

    private async updateTreeFromValue(props: HierarchyProps<E, P>) {
        const { templates, treeData0, treeData1 } = this.state;
        const { spec, value, currentLocale, site, alerts } = props;
        const context = { currentLocale, site, cmsApi: CmsApi };
        let sitemapRoot;
        let pageRedirectRoot;
        let postPages;
        if (templates == null) {
            return;
        }
        if (spec?.hierarchyOptions && isSiteMapHierarchy(spec)) {
            const pagesObj = await rootItemFromValueSplit(value, spec.hierarchyOptions, alerts);
            [sitemapRoot, pageRedirectRoot, postPages] = await Promise.all([
                rootItemFromValue(pagesObj.sitemapPages, spec.hierarchyOptions, context, getI18nLocaleString(namespaceList.admin, "sitemap")),
                rootItemFromValue(pagesObj.redirectPages, spec.hierarchyOptions, context, getI18nLocaleString(namespaceList.admin, "pageRedirect")),
                rootItemFromValue(pagesObj.postPages, spec.hierarchyOptions, context, getI18nLocaleString(namespaceList.admin, "pagePost")),
            ]);
        } else {
            sitemapRoot = await rootItemFromValue(value, spec.hierarchyOptions, context);
        }

        let newTreeData: Array<TreeItem<E[P]>>;
        let isChecked = this.state.isChecked;
        if (treeData0 && treeData0[0] && treeData1 && treeData1[0]) {
            const root = treeData0[0];
            const templateNodes = treeData1[0];
            newTreeData =
                isSiteMapHierarchy(spec) && pageRedirectRoot && postPages
                    ? [copyTreeState(root, sitemapRoot), copyTreeState(root, pageRedirectRoot), copyTreeState(root, postPages), copyTreeState(templateNodes, copyTree(templates))]
                    : [copyTreeState(root, sitemapRoot), copyTreeState(templateNodes, copyTree(templates))];
        } else {
            newTreeData = isSiteMapHierarchy(spec) && pageRedirectRoot && postPages ? [sitemapRoot, pageRedirectRoot, postPages, copyTree(templates)] : [sitemapRoot, copyTree(templates)];
            isChecked = newTreeData[0].children.length < 1 ? true : false;
        }
        isSiteMapHierarchy(spec) && pageRedirectRoot
            ? this.setState({ treeData0: [newTreeData[0], newTreeData[1], newTreeData[2]], treeData1: [newTreeData[3]], isChecked })
            : this.setState({ treeData0: [newTreeData[0]], treeData1: [newTreeData[1]], isChecked });
    }

    private removeNode(node: TreeItem<E[P]> | null): () => void {
        const { spec } = this.props;
        return async () => {
            let isRedirectPage = false;
            if (isSiteMapHierarchy(spec) && node?.item?.options?.pageId) {
                isRedirectPage = await checkPageRedirect(node?.item.options.pageId);
            }
            const tree = isRedirectPage ? this.state.treeData0![1] : node?.item?.isPagePost ? this.state.treeData0![2] : this.state.treeData0![0];
            const newTree = mapTree(
                tree,
                (item: TreeItem<E[P]>): TreeItem<E[P]> => ({
                    ...item,
                    children: item.children.filter((child) => (child as ItemTreeItem<E[P]>).item !== (node as ItemTreeItem<E[P]>).item && (child as ItemTreeItem<E[P]>).item !== null),
                })
            );
            if (newTree && newTree.children.length >= 0) {
                this.setState({ isOpenModal: false }, () => {
                    if (spec.widgetType === WidgetType.PAGE && node) {
                        localStorage.setItem("widgetName", JSON.stringify(node.title));
                    }
                    if (isSiteMapHierarchy(spec)) {
                        this.handleChange(
                            isRedirectPage
                                ? [this.state.treeData0![0], newTree, this.state.treeData0![2], this.state.treeData1![0]]
                                : node?.item?.isPagePost
                                ? [this.state.treeData0![0], this.state.treeData0![1], newTree, this.state.treeData1![0]]
                                : [newTree, this.state.treeData0![1], this.state.treeData0![2], this.state.treeData1![0]],
                            "formEditedTrue",
                            true,
                            isRedirectPage,
                            node?.item?.isPagePost
                        );
                    } else {
                        this.handleChange([newTree, this.state.treeData1![0]], "formEditedTrue", true);
                    }
                });
            }
        };
    }

    private cloneNode(node: TreeItem<E[P]>): () => void {
        const { spec } = this.props;
        return async () => {
            let isRedirectPage = false;
            if (isSiteMapHierarchy(spec) && node?.item?.options.pageId) {
                isRedirectPage = await checkPageRedirect(node?.item.options.pageId);
            }
            const tree = isRedirectPage ? this.state.treeData0![1] : node?.item?.isPagePost ? this.state.treeData0![2] : this.state.treeData0![0];

            // clone object pointing to new memory location
            let clone = JSON.parse(JSON.stringify(node));
            const newTree = mapTree(
                tree,
                (item: TreeItem<E[P]>): TreeItem<E[P]> => {
                    item.children.forEach((child, ind) => {
                        if ((child as ItemTreeItem<E[P]>).item === (node as ItemTreeItem<E[P]>).item) {
                            clone = sanitizeWidgetItems(clone);
                            item.children.splice(ind + 1, 0, clone);
                        }
                    });
                    return { ...item, children: item.children.filter((child) => (child as ItemTreeItem<E[P]>).item !== null) };
                }
            );
            if (newTree && newTree.children.length >= 1) {
                if (spec?.widgetType === WidgetType.PAGE && node?.type && node.type === "item" && node.subtitle && node.className && node.title) {
                    localStorage.setItem("widgetName", JSON.stringify(node.title));
                }
                if (isSiteMapHierarchy(spec)) {
                    this.handleChange(
                        isRedirectPage
                            ? [this.state.treeData0![0], newTree, this.state.treeData0![2], this.state.treeData1![0]]
                            : node?.item?.isPagePost
                            ? [this.state.treeData0![0], this.state.treeData0![1], newTree, this.state.treeData1![0]]
                            : [newTree, this.state.treeData0![1], this.state.treeData0![2], this.state.treeData1![0]],
                        "formEditedTrue",
                        true,
                        isRedirectPage,
                        node?.item?.isPagePost
                    );
                } else {
                    this.handleChange([newTree, this.state.treeData1![0]], "formEditedTrue");
                }
            }
        };
    }

    private handleEdit(node: TreeItem<E[P]>): () => void {
        return () => this.handleHierarchyItemOptions(node);
    }

    private handleHierarchyItemOptions = async (node: TreeItem<E[P]>) => {
        const { spec, context } = this.props;
        let editItemParent: E[P] | null | undefined = null;
        if ((node as ItemTreeItem<E[P]>).item !== null) {
            editItemParent = findParent(this.state.treeData0![0], (node as ItemTreeItem<E[P]>).item);
        }
        let hierarchyOptionsItemOptions = null;
        if (typeof spec.hierarchyOptions.itemOptions === "function") {
            hierarchyOptionsItemOptions = await spec.hierarchyOptions.itemOptions({ item: (node as ItemTreeItem<E[P]>).item, parent: editItemParent, context: context as any });
        }
        this.setState({ editItem: (node as ItemTreeItem<E[P]>).item, isOpen: true, hierarchyOptionsItemOptions });
    };

    private handleNewTemplateNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value) {
            this.setState({ newTemplateName: value });
        }
    };

    private createTemplate() {
        const { newTemplateRoot, newTemplateName } = this.state;
        let editItemParent: E[P] | null | undefined = null;
        if (newTemplateRoot !== null) {
            editItemParent = findParent(this.state.treeData0![0], (newTemplateRoot as ItemTreeItem<E[P]>).item);
        }
        const id = uniqueId("template");
        if (newTemplateRoot && newTemplateName && newTemplateName.length > 0) {
            const item: Template = {
                name: newTemplateName,
                // eslint-disable-next-line max-len
                root: editItemParent
                    ? (editItemParent as any).children.filter((child: any) => child._id === ((newTemplateRoot as ItemTreeItem<E[P]>).item as any)._id)
                    : ((newTemplateRoot as ItemTreeItem<E[P]>).item as any).children,
                metaRobots: "",
                localizedContents: [
                    {
                        metaTitleSeo: "",
                        metaDescriptionSeo: "",
                        metaKeywordsSeo: "",
                        imageStructuredSeo: "",
                        titleStructuredSeo: "",
                        urlStructuredSeo: "",
                        descriptionStructuredSeo: "",
                        authorStructuredSeo: "",
                        structuredDataMarkup: "",
                        locale: "",
                    },
                ],
                structuredData: false,
                enableRedirect: false,
                useExternalLink: false,
                redirect: "",
                siteId: "",
                pageId: "",
                deletedDate: undefined,
                postId: "",
            };
            TemplateApi!
                .clone({ id, item })
                .then(() => {
                    // eslint-disable-next-line no-console
                    console.log("Created new template " + newTemplateName);
                })
                .catch(() => {
                    throw new Error("Unable to create new template " + newTemplateName);
                });
        }
    }

    private handleItemSave = (val: any) => {
        const { editItem, treeData0, treeData1 } = this.state;
        const newEditItem = editItem;
        const { spec } = this.props;
        const opsVar = spec.hierarchyOptions.optionsVariable;
        (newEditItem as any)[opsVar] = val;
        applyLinkedWidgetsOfEditedItem(editItem, treeData0);
        this.setState({ editItem: newEditItem }, () =>
            this.handleChange(isSiteMapHierarchy(spec) ? [treeData0![0], treeData0![1], treeData0![2], treeData1![0]] : [treeData0![0], treeData1![0]], "formSavedTrue")
        );
    };

    private handleVisibility = (node: TreeItem<E[P]>) => {
        const nodeItem: any = { ...(node as ItemTreeItem<E[P]>).item };
        set(nodeItem, "options.showDetail", !nodeItem.options.showDetail);
        this.setState({ editItem: nodeItem }, () => this.handleItemSave(nodeItem));
    };

    // eslint-disable-next-line max-lines-per-function
    private nodePropGenerator = (data: any): { [index: string]: any } => {
        const { spec } = this.props;
        const node = data.node;
        const deleteBtn = (
            <Button outline className="hierarchy btn-delete" size="sm" color="danger" onClick={this.state.isOpenModal ? this.toggle : this.toggle.bind(this, node)}>
                <DeleteOutline />
                <span className="mcms-tooltip top">{getI18nLocaleString(namespaceList.admin, "tooltipDelete")}</span>
            </Button>
        );
        const editBtn = (
            <Button outline className="hierarchy btn-edit" size="sm" color="primary" onClick={this.handleEdit(node)}>
                <Edit />
                <span className="mcms-tooltip top">{getI18nLocaleString(namespaceList.admin, "tooltipEdit")}</span>
            </Button>
        );
        const cloneBtn = (
            // eslint-disable-next-line max-len
            <Button outline className="hierarchy btn-clone" size="sm" color="success" onClick={this.cloneNode(node)}>
                <ContentCopy />
                <span className="mcms-tooltip top">{getI18nLocaleString(namespaceList.admin, "tooltipClone")}</span>
            </Button>
        );
        const templateBtn = (
            <Button outline className="hierarchy btn-template" size="sm" color="info" onClick={this.handleTemplateCreate(node)}>
                <AddTwoTone />
                <span className="mcms-tooltip top">{getI18nLocaleString(namespaceList.admin, "tooltipCreateTemplate")}</span>
            </Button>
        );
        const redirectBtn = (
            <Button outline className="hierarchy btn-template" size="sm" color="info" onClick={() => this.handleCreateInterlinking(node, spec.widgetType)}>
                <OpenInNew />
                <span className="mcms-tooltip top">{getI18nLocaleString(namespaceList.admin, "tooltipCreateInterlinking") + " " + node?.title}</span>
            </Button>
        );
        const itemType = ["card", "breadcrumb", "language", "columns", "prevnext"];
        const hideBtn = (spec.widgetType === WidgetType.PAGE || spec.widgetType === WidgetType.RESULTPANEL) && node.item && itemType.indexOf(node.item.type) === -1 && (
            <Button outline className="hierarchy btn-template" size="sm" color="info" onClick={() => this.handleVisibility(node)}>
                {node.type === "item" && node.item.options?.showDetail ? <Visibility /> : <VisibilityOff />}
                <span className="mcms-tooltip top">
                    {node.type === "item" && node.item.options?.showDetail
                        ? getI18nLocaleString(namespaceList.admin, "tooltipShowWidget")
                        : getI18nLocaleString(namespaceList.admin, "tooltipHideWidget")}
                </span>
            </Button>
        );
        const isMobileTabletBtn = node.item?.options && Object.keys(showMomentType).includes(node.item.options?.showMoment) && (
            <Button
                className={`${
                    node.item.options?.showMoment === "dk-hidden-m" || node.item.options?.showMoment === "dk-hidden-from-desktop"
                        ? "hierarchy btn-template mobile-tablet-view mobile-tablet-landscape device-btn"
                        : showMomentTablet.includes(node.item.options?.showMoment) || showMomentTv.includes(node.item.options?.showMoment) || showMomentMobile.includes(node.item.options?.showMoment)
                        ? "hierarchy btn-template mobile-tablet-view device-btn"
                        : "hierarchy btn-template device-btn"
                }`}
                size="sm"
            >
                {node.item.options?.showMoment === "hidden-till-tablet" ? (
                    <div className="device-identify">
                        <DesktopWindowsOutlined className="1" />
                        <Smartphone className="mobile" />
                        <span className="mcms-tooltip device-tooltip top"> {showMomentType[node.item.options?.showMoment]} </span>
                    </div>
                ) : showMomentTv.includes(node.item.options?.showMoment) ? (
                    <Laptop />
                ) : showMomentTablet.includes(node.item.options?.showMoment) ? (
                    <Tablet />
                ) : (
                    (showMomentMobile.includes(node.item.options?.showMoment) || node.item.options?.showMoment === "dk-hidden-m") && <Smartphone className="mobile" />
                )}
                {node.item.options?.showMoment !== "hidden-till-tablet" && <span className="mcms-tooltip device-tooltip top"> {showMomentType[node.item.options?.showMoment]} </span>}
            </Button>
        );
        const subtitle = (
            <span>
                <span>{node.subtitle}</span>
                {node.additionalInfo && (
                    <div className="page-link-additional">
                        <span>{node.additionalInfo}</span>
                    </div>
                )}
            </span>
        );
        let buttons;
        if (node.type === "item" && spec.widgetType && spec.widgetType === WidgetType.PAGE) {
            buttons = [deleteBtn, editBtn, hideBtn, cloneBtn, templateBtn];
        } else if (spec.widgetType === WidgetType.RESULTPANEL) {
            buttons = [deleteBtn, editBtn, hideBtn, cloneBtn];
        } else {
            buttons = spec.widgetType === WidgetType.FLOW ? [deleteBtn, editBtn] : [deleteBtn, editBtn, cloneBtn];
        }
        if (
            ((node?.title === getI18nLocaleString(namespaceList.admin, "webcontent") ||
                node?.title === getI18nLocaleString(namespaceList.widgetWebContent, "webcontentWidget") ||
                node?.item?.type === "webcontent") &&
                node?.subtitle !== getI18nLocaleString(namespaceList.widgetWebContent, "noContent")) ||
            ((node?.title === getI18nLocaleString(namespaceList.admin, "templates") || node?.item?.type === "template") &&
                node?.subtitle !== getI18nLocaleString(namespaceList.widgetTemplate, "noTemplate")) ||
            (node.type === "item" && spec.widgetType === WidgetType.SITEMAP) ||
            spec.widgetType === WidgetType.FLOW ||
            node.item?.options?.postId
        ) {
            buttons.push(redirectBtn);
        } else if (node?.item?.type === "flexbox") {
            const deviceClassName = node?.className.split(" ")?.[1] || node?.className;
            if (isMobileTabletBtn) {
                !document.querySelector("." + deviceClassName)?.classList.contains("device-wrapper") && document.querySelector("." + deviceClassName)?.classList.add("device-wrapper");
                buttons.unshift(isMobileTabletBtn);
            } else {
                document.querySelector("." + deviceClassName)?.classList.remove("device-wrapper");
            }
            if (node.item?.options?.showConditionalContent) {
                document.querySelector("." + deviceClassName)?.classList.add("conditional-wrapper");
                const newElement = document.createElement("div");
                newElement.className = "conditionalContentStatus";
                const conditionalWrapperElement = document.querySelector("." + deviceClassName + ".conditional-wrapper");
                const conditionalWrapperChild = document.querySelector("." + deviceClassName + ".conditional-wrapper")?.firstChild;
                if (!conditionalWrapperElement?.querySelector(".conditionalContentStatus")) {
                    conditionalWrapperChild?.before(newElement);
                }
            } else {
                document.querySelector("." + deviceClassName)?.classList.remove("conditional-wrapper");
            }
        }

        if (node?.item?.options?.linkedWidgetIds?.length) {
            buttons.unshift(<LinkedToWidgetIndicator node={node}></LinkedToWidgetIndicator>);
        }

        let classNames;
        if (node.type === "item" && spec.widgetType && (spec.widgetType === WidgetType.PAGE || spec.widgetType === WidgetType.RESULTPANEL) && node.item.options?.showDetail) {
            classNames = "disableWidget";
        } else if (node.className) {
            classNames = node.className;
        } else {
            classNames = "";
        }
        return {
            subtitle,
            buttons: node.type === "item" ? buttons : [],
            className: classNames,
        };
    };

    private toggle = (node?: any) => {
        this.setState({ isOpenModal: !this.state.isOpenModal, node });
    };

    // eslint-disable-next-line max-lines-per-function
    private handleChange = (treeDatax: Array<TreeItem<E[P]>>, modalFormEdited?: string, deleted?: boolean, isRedirectPage?: boolean, isPagePost?: boolean): void => {
        const { spec, currentLocale, site } = this.props;
        const context = { currentLocale, site, cmsApi: CmsApi };
        treeDatax[0].type !== "templates" && localStorage.setItem("showRedirectMovedNotice", "no");
        const treeData: Array<TreeItem<E[P]>> =
            treeDatax.length === 1
                ? treeDatax[0].type === "templates"
                    ? isSiteMapHierarchy(spec)
                        ? [this.state.treeData0![0], this.state.treeData0![1], this.state.treeData0![2], treeDatax[0]!]
                        : [this.state.treeData0![0], treeDatax[0]!]
                    : [treeDatax[0]!, this.state.treeData1![0]]
                : treeDatax;
        const root = isRedirectPage ? treeData[1] : isPagePost ? treeData[2] : treeData[0];
        const { editItem } = this.state;
        if (editItem && (editItem as any).type === "template" && (editItem as any).options.layHierarchy) {
            let finalChildrens: Array<TreeItem<E[P]>> = [];
            const templateWidgetTreeItems: Array<Promise<TemplateWidgetTreeItem<E>>> = [];
            root.children.forEach((child) => {
                templateWidgetTreeItems.push(getTemplateWidgetTreeItem(child, spec.hierarchyOptions, context));
            });

            templateWidgetTreeItems.forEach(async (er) => {
                const res = await er;
                if (res) {
                    const result = getTemplateHierarchy(res);
                    if (result.length > 0) {
                        this.setState({ editItem: null });
                        root.children.forEach((item) => {
                            if ((item as any).item === res.item) {
                                finalChildrens = finalChildrens.concat(updateTemplateWidgetToHierarchy(item, result));
                            } else {
                                finalChildrens.push(item);
                            }
                        });
                        root.children = finalChildrens;
                        const newRootProm: Promise<TreeItem<E[P]>> = asyncMapTree(root, (treeItem: TreeItem<E[P]>) => {
                            if (isTemplateTreeItem(treeItem)) {
                                const item = treeItem.template.create();
                                return treeDataFromValue(item, spec.hierarchyOptions, context);
                            }
                            return Promise.resolve(treeItem);
                        });
                        this.setState({ treeData0: [treeData[0]], treeData1: [treeData[1]] }, () => {
                            newRootProm.then((newRoot) => {
                                if (newRoot && newRoot.children.length >= 1) {
                                    const newValue =
                                        spec.hierarchyOptions.rootType === "single"
                                            ? valueFromTreeData(newRoot!.children.length > 0 ? (newRoot!.children[0] as ItemTreeItem<E[P]>) : null, spec.hierarchyOptions)
                                            : newRoot.children.map((child) => valueFromTreeData(child as ItemTreeItem<E[P]>, spec.hierarchyOptions));
                                    this.props.onChange(newValue, spec, "formEditedTrue");
                                }
                            });
                        });
                    }
                }
            });
        } else {
            const newRootProm: Promise<TreeItem<E[P]>> = asyncMapTree(root, (treeItem: TreeItem<E[P]>) => {
                if (isTemplateTreeItem(treeItem)) {
                    const item = treeItem.template.create();
                    return treeDataFromValue(item, spec.hierarchyOptions, context);
                }
                return Promise.resolve(treeItem);
            });
            const arr = treeData[3] || this.state.treeData1![0];
            this.setState(
                isSiteMapHierarchy(spec)
                    ? {
                          treeData0: [treeData[0], treeData[1], treeData[2]],
                          treeData1: [arr],
                      }
                    : {
                          treeData0: [treeData[0]],
                          treeData1: [treeData[1]],
                      },
                () => {
                    newRootProm.then((newRoot) => {
                        if ((newRoot && newRoot.children.length >= 1) || deleted) {
                            let newValue =
                                spec.hierarchyOptions.rootType === "single"
                                    ? valueFromTreeData(newRoot.children[0] as ItemTreeItem<E[P]>, spec.hierarchyOptions)
                                    : newRoot.children.map((child) => valueFromTreeData(child as ItemTreeItem<E[P]>, spec.hierarchyOptions));
                            let widgetModalFormEditType;
                            if (modalFormEdited === "formEditCancelled") {
                                widgetModalFormEditType = "formEditCancelled";
                            } else {
                                widgetModalFormEditType = modalFormEdited === "formEditedTrue" || this.state.loadForm === "formEditedTrue" ? "formEditedTrue" : "listOpen";
                            }
                            if (isSiteMapHierarchy(spec)) {
                                if (isRedirectPage) {
                                    treeData[0]?.children?.map((child) => (newValue = newValue.concat(child.item)));
                                    treeData[2]?.children?.map((child) => (newValue = child.item ? newValue.concat(child.item) : newValue.concat(child)));
                                } else if (isPagePost) {
                                    treeData[0]?.children?.map((child) => (newValue = child.item ? newValue.concat(child.item) : newValue.concat(child)));
                                    treeData[1]?.children?.map((child) => (newValue = child.item ? newValue.concat(child.item) : newValue.concat(child)));
                                } else {
                                    treeData[1]?.children?.map((child) => (newValue = child.item ? newValue.concat(child.item) : newValue.concat(child)));
                                    treeData[2]?.children?.map((child) => (newValue = child.item ? newValue.concat(child.item) : newValue.concat(child)));
                                }
                            }
                            this.props.onChange(newValue, spec, widgetModalFormEditType);
                        }
                    });
                }
            );
        }
        if (spec.widgetType !== WidgetType.PAGE) {
            this.setState({ editItem: null });
        }
    };

    private canDrag = ({ node }: { node: TreeItem<E[P]>; prevParent: TreeItem<E[P]>; nextParent: TreeItem<E[P]> }) => {
        const { mode, enabled } = this.props;
        if (mode === "readonly" || !enabled) {
            return false;
        }
        if (isTemplateTreeItem(node)) {
            if (node.children.length > 0) {
                return false;
            }
        }
        if (node.type === "templates" || node.type === "root") {
            return false;
        }
        return true;
    };

    private canDrop = (data: { node: TreeItem<E[P]>; prevParent: TreeItem<E[P]>; nextParent: TreeItem<E[P]> }) => {
        const { spec } = this.props;
        const { rootType } = spec.hierarchyOptions;
        const node: TreeItem<E[P]> = data.node;
        const prevParent: TreeItem<E[P]> = data.prevParent;
        const nextParent: TreeItem<E[P]> = data.nextParent;
        if (
            nextParent === null ||
            prevParent === null ||
            nextParent.type === "templates" ||
            nextParent.type === "template" ||
            (isItemTreeItem(nextParent) && !spec.hierarchyOptions.itemContainer({ item: nextParent.item })) ||
            (nextParent.type === "root" && rootType === "single" && nextParent.children.length > 1) ||
            nextParent.title === getI18nLocaleString(namespaceList.admin, "pageRedirect") ||
            nextParent.title === getI18nLocaleString(namespaceList.admin, "pagePost")
        ) {
            if (nextParent?.title === getI18nLocaleString(namespaceList.admin, "pageRedirect") || nextParent?.title === getI18nLocaleString(namespaceList.admin, "pagePost")) {
                this._isMovedPage = true;
            }
            return false;
        }

        switch (node.type) {
            case "templates":
            case "root":
                return false;
        }
        if (spec.widgetType === WidgetType.PAGE && data.node) {
            localStorage.setItem("widgetName", JSON.stringify(data.node.title));
        }
        return true;
    };
    private onDragEnd = ({ isDragging }: { isDragging: boolean; draggedNode: TreeItem<E[P]> }): void => {
        const { alerts } = this.props;
        if (this._isMovedPage && !isDragging) {
            alerts.push({ color: "info", message: getI18nLocaleString(namespaceList.admin, "normalPageRedirectMessage") });
            this._isMovedPage = false;
        }
    };
}

export const Hierarchy = wrapProps<GenericInputProps<any, any, InputSpecHierarchy<any, any>>>(CMSAware(HierarchyBase));
