import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classnames from "classnames";
import * as moment from "moment";

import { FormGroup, Label } from "reactstrap";
import { GenericInputProps, GenericInputStoreProps } from "./input.types";
import { InputSpec, InputSpecLazyLoadAuto, InputSpecLazyLoadMulti } from "../../form-specs";
import { LocalizedContentBase, SiteApi } from "@maxxton/cms-api";
import { WithPermissionsProps, withPermissions } from "../../containers/PermissionsProvider";
import { canCreate, canEdit } from "../../utils/permissions.utils";
import { getI18nLocaleString, getI18nLocaleStringFromParams } from "../../i18n";
import loadable, { LoadableComponent } from "@loadable/component";

import { ConditionalSetGroupInput } from "./ConditionalSetGroupInput";
import { CreatableGroupInput } from "./CreatableGroupInput";
import { DATE_FORMAT } from "../../utils/constants";
import { DateSelectInput } from "./DateSelectInput";
import { DocumentPicker } from "../media/DocumentPicker";
import { Hierarchy } from "./hierarchy";
import { InputWithTags } from "./InputWithTags";
import { JSONSelector } from "./jsonSelector";
import { SimpleInput } from "./simple";
import { SiteGroupInput } from "./SiteGroupInput";
import { State } from "../../redux";
import { TabbedInput } from "./tabbed";
import { TagInput } from "./tags";
import { connect } from "react-redux";
import { loadableRetry } from "../../utils/loadableComponents.util";
import namespacesList from "../../i18n/namespaceList";

const AnchorInput = loadable(() => loadableRetry(() => import("./anchor")), {
    resolveComponent: ({ AnchorInput }) => AnchorInput,
});
const AsyncSelect = loadable(() => loadableRetry(() => import("./asyncSelect")), {
    resolveComponent: ({ AsyncSelect }) => AsyncSelect,
});
const AutocompleteInput = loadable(() => loadableRetry(() => import("./autocomplete")), {
    resolveComponent: ({ AutocompleteInput }) => AutocompleteInput,
});
const LazyLoadAutoCompleteInput: LoadableComponent<GenericInputProps<any, any, InputSpecLazyLoadAuto<any, any>>> = loadable(() => loadableRetry(() => import("./LazyLoadAutoComplete")), {
    resolveComponent: ({ LazyLoadAutoCompleteInput }) => LazyLoadAutoCompleteInput,
});
const LazyLoadMultiSelectInput: LoadableComponent<GenericInputProps<any, any, InputSpecLazyLoadMulti<any, any>>> = loadable(() => loadableRetry(() => import("./LazyLoadMultiSelect")), {
    resolveComponent: ({ LazyLoadMultiSelectInput }) => LazyLoadMultiSelectInput,
});
const LinkedWidgetsSelect: LoadableComponent<GenericInputProps<any, any, any>> = loadable(() => loadableRetry(() => import("./linkedWidgets/LinkedWidgetsSelect")), {
    resolveComponent: ({ LinkedWidgetsSelect }) => LinkedWidgetsSelect,
});
const BreadCrumb = loadable(() => loadableRetry(() => import("./breadcrumb")), {
    resolveComponent: ({ BreadCrumb }) => BreadCrumb,
});
const CategoryInput = loadable(() => loadableRetry(() => import("./category")), {
    resolveComponent: ({ CategoryInput }) => CategoryInput,
});
const ImageGallery = loadable(() => loadableRetry(() => import("./imageGallery")), {
    resolveComponent: ({ ImageGallery }) => ImageGallery,
});
const ImageInput = loadable(() => loadableRetry(() => import("./image")), {
    resolveComponent: ({ ImageInput }) => ImageInput,
});
const MapInput = loadable(() => loadableRetry(() => import("./map")), {
    resolveComponent: ({ MapInput }) => MapInput,
});
const MarkerInput = loadable(() => loadableRetry(() => import("./marker")), {
    resolveComponent: ({ MarkerInput }) => MarkerInput,
});
const MultiSelectInput = loadable(() => loadableRetry(() => import("./multiselect")), {
    resolveComponent: ({ MultiSelectInput }) => MultiSelectInput,
});
const ReferenceInput = loadable(() => loadableRetry(() => import("./reference")), {
    resolveComponent: ({ ReferenceInput }) => ReferenceInput,
});
const RichTextInput = loadable(() => loadableRetry(() => import("./richText")), {
    resolveComponent: ({ RichTextInput }) => RichTextInput,
});
const StaticTabsInput = loadable(() => loadableRetry(() => import("./statictabs")), {
    resolveComponent: ({ StaticTabsInput }) => StaticTabsInput,
});
const ThemeInput = loadable(() => loadableRetry(() => import("./themeinput")), {
    resolveComponent: ({ ThemeInput }) => ThemeInput,
});
const GroupInput = loadable(() => loadableRetry(() => import("./groupInput")), {
    resolveComponent: ({ GroupInput }) => GroupInput,
});

interface GenericInputState {
    noFriendlyUrl?: boolean;
    noLocalizedFriendlyUrl?: boolean;
    visible: boolean;
    passwordVisibility: boolean;
}

export class GenericInputBase<E, P extends keyof E> extends React.Component<GenericInputProps<E, P, InputSpec<E, P>> & WithPermissionsProps, GenericInputState> {
    constructor(props: GenericInputProps<E, P, InputSpec<E, P>> & WithPermissionsProps) {
        super(props);
        this.state = {
            visible: true,
            passwordVisibility: false,
        };
    }

    public componentDidMount() {
        this.checkFriendlyUrl();
        this.checkForVisibility(this.props);
        this.checkFieldRequired(this.props);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: GenericInputProps<E, P, InputSpec<E, P>> & WithPermissionsProps) {
        this.checkFriendlyUrl();
        this.checkForVisibility(nextProps);
        this.checkFieldRequired(nextProps);
    }

    /* jscpd:ignore-start */
    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const { item, spec, tabLocale, dynamicData, permission, mode } = this.props;
        const { noFriendlyUrl, noLocalizedFriendlyUrl } = this.state;
        if (noLocalizedFriendlyUrl && spec.variable === "localizedFriendlyUrl" && "home" in item) {
            return null;
        }
        if (noFriendlyUrl && spec.variable === "friendlyUrl" && "home" in item) {
            return null;
        }
        if (!this.state.visible) {
            return null;
        }
        let enabled = true;
        if (typeof spec.enabled === "function") {
            enabled = spec.enabled(item);
        }
        if (enabled && mode !== "edit" && !((mode === "admin_edit" && canEdit(permission)) || (mode === "create" && canCreate(permission)))) {
            enabled = false;
        }
        const label = getI18nLocaleStringFromParams(spec.label || "");
        if (spec.type === "checkbox") {
            const id = tabLocale ? spec.variable + tabLocale : spec.variable;
            return (
                <FormGroup row disabled={!enabled} className={"checkbox" + classnames({ required: spec.required })}>
                    {this.renderContent(enabled, label)}
                    {label && (
                        <Label className="style-checkbox-label" for={id as any}>
                            <span />
                        </Label>
                    )}
                    {label && (
                        <Label className="input-label" for={id as any}>
                            {label}
                        </Label>
                    )}
                </FormGroup>
            );
        } else if (spec.type === "paragraph") {
            return (
                <p className="admin-paragraph">
                    <FontAwesome name="bookmark-o" size="2x" />
                    {label}
                </p>
            );
        } else if (spec.type === "animate") {
            return (
                <FormGroup row disabled={!enabled} className={classnames({ required: spec.required })}>
                    {label && (
                        <Label className="input-label select-label" for={spec.variable as any}>
                            {label}
                        </Label>
                    )}
                    {this.renderContent(enabled, label)}
                </FormGroup>
            );
        } else if (spec.type === "seo") {
            return <div className={`group-${spec.variable}`}>{this.renderContent(enabled, label)}</div>;
        } else if (spec.type === "icons") {
            return (
                <FormGroup row disabled={!enabled} className={classnames({ required: spec.required })}>
                    {label && (
                        <Label className="input-label select-label" for={spec.variable as any}>
                            {label}
                        </Label>
                    )}
                    {this.renderContent(enabled, label)}
                </FormGroup>
            );
        } else if (spec.type === "select") {
            return (
                <FormGroup row disabled={!enabled} className={classnames({ required: spec.required })}>
                    {label && (
                        <Label className="input-label select-label" for={spec.variable as any}>
                            {label}
                        </Label>
                    )}
                    {this.renderContent(enabled, label)}
                </FormGroup>
            );
        } else if (spec.type === "range") {
            return (
                <FormGroup row disabled={!enabled} className={classnames({ required: spec.required })}>
                    <div className="range-slider">
                        {label && (
                            <Label className="input-label select-label" for={spec.variable as any}>
                                {label}
                            </Label>
                        )}
                        {this.renderContent(enabled, label)}
                    </div>
                </FormGroup>
            );
        } else if (spec.type === "date") {
            return (
                <FormGroup row disabled={!enabled} className={classnames({ required: spec.required })}>
                    {label && (
                        <Label className="input-label select-label" for={spec.variable as any}>
                            {label}
                        </Label>
                    )}
                    {this.renderContent(enabled, label)}
                </FormGroup>
            );
        } else if (spec.type === "text" || spec.type === "directText" || spec.type === "email" || spec.type === "password") {
            return (
                <FormGroup row disabled={!enabled} className={classnames({ required: spec.required })}>
                    {this.renderContent(enabled, label)}
                    <div className="bar" />
                    {label && (
                        <Label className="input-label text-labels" for={spec.variable as any}>
                            {label}
                        </Label>
                    )}
                    {(spec.type === "password" || spec.useAsConfirmPassword) && (
                        <FontAwesome name={this.state.passwordVisibility ? "eye" : "eye-slash"} className="password-visibility" onClick={this.togglePasswordVisibility} />
                    )}
                </FormGroup>
            );
        } else if (spec.message && spec.type === "number") {
            return (
                <FormGroup row disabled={!enabled} className={classnames({ required: spec.required })}>
                    {this.renderContent(enabled, label)}
                    <div className="bar" />
                    {label && (
                        <Label className="input-label text-labels" for={spec.variable as any}>
                            {label}
                        </Label>
                    )}
                </FormGroup>
            );
        } else if (spec.type === "button") {
            return (
                <FormGroup row disabled={!enabled} className={classnames({ required: spec.required }) + `${spec.classname ? " " + spec.classname(item) : ""}`}>
                    {this.renderContent(enabled, label)}
                </FormGroup>
            );
        } else if (spec.type === "dynamicData" && dynamicData) {
            const dateFormat = dynamicData.isMyEnv ? DATE_FORMAT.ELASTIC : DATE_FORMAT.DEFAULT;
            return (
                <div className="dynamic-data-list">
                    {dynamicData.startDate && dynamicData.endDate ? (
                        <>
                            <label>{`${moment(dynamicData.startDate, dateFormat).format("DD/MM/YYYY")} - ${moment(dynamicData.endDate, dateFormat).format("DD/MM/YYYY")}`}</label>
                            <br />
                        </>
                    ) : dynamicData.startDate ? (
                        <>
                            <label>
                                {getI18nLocaleString(namespacesList.priceMatrixWidget, "arrivalDateSummary")} {moment(dynamicData.startDate, dateFormat).format("DD/MM/YYYY")}
                            </label>
                            <br />
                        </>
                    ) : dynamicData.endDate ? (
                        <>
                            <label>
                                {getI18nLocaleString(namespacesList.priceMatrixWidget, "departureDateSummary")} {moment(dynamicData.endDate, dateFormat).format("DD/MM/YYYY")}
                            </label>
                            <br />
                        </>
                    ) : null}
                    {dynamicData.resortName && (
                        <>
                            <label>{dynamicData.resortName}</label>
                            <br />
                        </>
                    )}
                    {dynamicData.typeName && (
                        <>
                            <label>{dynamicData.typeName}</label>
                            <br />
                        </>
                    )}
                    {dynamicData.unitName && (
                        <>
                            <label>{dynamicData.unitName}</label>
                            <br />
                        </>
                    )}
                    {dynamicData.accoKindName && (
                        <>
                            <label>{dynamicData.accoKindName}</label>
                            <br />
                        </>
                    )}
                    {dynamicData.duration && (
                        <>
                            <label>{`Duration: ${dynamicData.duration}`}</label>
                            <br />
                        </>
                    )}
                    {dynamicData.subjects?.map((subject: any) => (
                        <>
                            <label key={subject.subjectId}>{`${subject.subjectCount} ${subject.subjectName}`}</label>
                            <br />
                        </>
                    ))}
                </div>
            );
        }
        return (
            <FormGroup row disabled={!enabled} className={classnames({ required: spec.required })}>
                {label && (
                    <Label className="input-label other-labels" for={spec.variable as any}>
                        {label}
                    </Label>
                )}
                {this.renderContent(enabled, label)}
            </FormGroup>
        );
    }
    /* jscpd:ignore-end */

    // eslint-disable-next-line max-lines-per-function
    protected renderContent(enabled: boolean, label: string | undefined): JSX.Element {
        const { root, mode, item, initialItem, value, onChange, tabLocale, localeCode, friendlyUrl, alerts, permissions, permission, validate } = this.props;
        const spec: any = { ...this.props.spec, passwordVisibility: this.state.passwordVisibility };
        const props = {
            key: spec.variable,
            spec,
            mode,
            root,
            item,
            value,
            onChange,
            enabled,
            label,
            initialItem,
            tabLocale,
            friendlyUrl,
            localeCode,
            alerts,
            permissions,
            permission,
            validate,
        };
        switch (spec.type) {
            case "paragraph":
            case "icons":
            case "animate":
            case "colorFromSelect":
            case "email":
            case "password":
            case "text":
            case "directText":
            case "textarea":
            case "number":
            case "select":
            case "placeholder":
            case "checkbox":
            case "radio":
            case "radioImage":
            case "radioForms":
            case "file":
            case "range":
            case "seo":
            case "date":
            case "datetime":
            case "button":
            case "color":
            case "dual-color":
                return <SimpleInput {...props} />;
            case "inputWithTags":
                return <InputWithTags {...props} />;
            case "json-selector":
                return <JSONSelector {...props} />;
            case "reference":
                return <ReferenceInput {...props} />;
            case "richtext":
                return <RichTextInput {...props} />;
            case "hierarchy":
                return <Hierarchy {...props} />;
            case "tabbed":
                return <TabbedInput {...props} />;
            case "group":
                return <GroupInput {...props} />;
            case "creatablegroup":
                return <CreatableGroupInput {...props} />;
            case "theme":
                return <ThemeInput {...props} />;
            case "statictabs":
                return <StaticTabsInput {...props} />;
            case "image":
                return <ImageInput {...props} />;
            case "imagegallery":
                return <ImageGallery {...props} />;
            case "tag":
                return <TagInput {...props} />;
            case "category":
                return <CategoryInput {...props} />;
            case "autocomplete":
                return <AutocompleteInput {...props} />;
            case "lazyLoadAutoComplete":
                return <LazyLoadAutoCompleteInput {...props} />;
            case "lazyLoadMultiSelect":
                return <LazyLoadMultiSelectInput {...props} />;
            case "anchor":
                return <AnchorInput {...props} />;
            case "multiselect":
                return <MultiSelectInput {...props} />;
            case "asyncSelect":
                return <AsyncSelect {...props} />;
            case "map":
                return <MapInput {...props} />;
            case "breadcrumb":
                return <BreadCrumb {...props} />;
            case "marker":
                return <MarkerInput {...props} />;
            case "document":
                return <DocumentPicker {...props} />;
            case "siteGroup":
                return <SiteGroupInput {...props} />;
            case "dateSelect":
                return <DateSelectInput {...props} />;
            case "conditionalSetGroup":
                return <ConditionalSetGroupInput {...props} />;
            case "linkedWidgetsSelect":
                return <LinkedWidgetsSelect {...props} />;

            default:
                throw new Error(`Unknown spec type "${spec.type}"`);
        }
    }

    private checkFieldRequired(props: GenericInputProps<E, P, InputSpec<E, P>> & WithPermissionsProps) {
        const { guestFormState, spec } = props;
        if (spec.variable && guestFormState && guestFormState.minimalMandatoryFields) {
            spec.required = guestFormState.minimalMandatoryFields.some((field) => field === spec.variable);
        }
        return spec;
    }

    private checkFriendlyUrl = () => {
        const { spec, item } = this.props;
        if (typeof item === "object" && "home" in item && (spec.variable === "friendlyUrl" || spec.variable === "localizedFriendlyUrl")) {
            const array = window.location.href.indexOf("/webmanager/site/edit/") ? window.location.href.split("/") : [];
            const siteId = array.length > 0 ? array[array.length - 1].replace("#", "") : "";
            if (siteId && siteId.length === 24) {
                SiteApi.findById({ id: siteId, projection: { sitemap: 0 } }).then((site) => {
                    let noFriendlyUrl = false;
                    let noLocalizedFriendlyUrl = false;
                    if (site && site.enableMultiLanguage) {
                        noFriendlyUrl = true;
                    } else {
                        noLocalizedFriendlyUrl = true;
                    }
                    this.setState({ noFriendlyUrl, noLocalizedFriendlyUrl });
                });
            }
        }
    };

    private togglePasswordVisibility = () => {
        this.setState({ passwordVisibility: !this.state.passwordVisibility });
    };

    private checkForVisibility = (props: any): void => {
        const { spec, tabLocale, initialItem, root, item } = props;
        if (Array.isArray(item) && item.some((localizedItem: LocalizedContentBase) => localizedItem?.locale) && typeof spec.visible === "function") {
            const tabContent: E = item.find((localeItem: E) => (localeItem as any).locale === tabLocale);
            {
                this.setState({ visible: !!spec.visible(tabContent, tabLocale, root, initialItem) });
            }
        } else if (typeof spec.visible === "function" && !spec.visible(item, tabLocale, root, initialItem)) {
            this.setState({
                visible: false,
            });
        } else if (typeof spec.visible === "function" && spec.visible(item, tabLocale, root, initialItem).then && typeof spec.visible(item, tabLocale, root, initialItem).then === "function") {
            spec.visible(item, tabLocale, root, initialItem).then((res: boolean) => {
                this.setState({
                    visible: !!res,
                });
            });
        } else {
            this.setState({
                visible: true,
            });
        }
    };
}

function mapStateToProps(state: State): GenericInputStoreProps {
    return { guestFormState: state.guestFormState };
}

const GenericInputBaseType = connect<GenericInputStoreProps, GenericInputState>(mapStateToProps)(GenericInputBase);

export const GenericInput = withPermissions<GenericInputProps<any, any, InputSpec<any, any>>>(GenericInputBaseType);
