import * as React from "react";

import { Input, Table } from "reactstrap";
import type { InputGroup, InputGroupItem } from "./inputGroup.types";
import { getI18nLocaleString, getI18nLocaleStringFromParams, wrapProps } from "../../../i18n";

import { GenericInputProps } from "../input.types";
import { InputGroupRow } from "./InputGroupRow";
import { InputSpecCreatableGroup } from "../../../form-specs";
import namespacesList from "../../../i18n/namespaceList";
import { uniqueId } from "lodash";

type InputGroupProps<S, P extends keyof S> = GenericInputProps<S, P, InputSpecCreatableGroup<S, P>>;

interface InputGroupState {
    inputGroup: InputGroup;
    addNewGroup: boolean;
    groupName: string;
}

const transformInputToGroupItem = (value: string): InputGroupItem => ({
    value,
    id: uniqueId(),
});

export class CreatableGroupBase<S, P extends keyof S> extends React.PureComponent<InputGroupProps<S, P>, InputGroupState> {
    constructor(props: InputGroupProps<S, P>) {
        super(props);
        this.state = {
            inputGroup: ((props.value as unknown) as string[])?.map((item) => transformInputToGroupItem(item)) || [],
            addNewGroup: false,
            groupName: "",
        };
    }

    public render(): JSX.Element | null {
        const { enabled, spec } = this.props;
        const { inputGroup, groupName } = this.state;

        return (
            <div className="generic-form whitelist-form col-12 p-0">
                <Input
                    name="newInputGroup"
                    type="text"
                    value={groupName}
                    disabled={!enabled}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ groupName: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            this.createInputGroup?.(transformInputToGroupItem(groupName));
                        }
                    }}
                    placeholder={(spec.placeholder && getI18nLocaleStringFromParams(spec.placeholder)) || ""}
                />
                <button className="btn btn-primary whitelist-btn" disabled={!enabled} onClick={() => this.createInputGroup?.(transformInputToGroupItem(groupName))} type="button">
                    {spec.buttonLabel && getI18nLocaleStringFromParams(spec.buttonLabel)}
                </button>
                <div className="whitelist-table-wrap">
                    {inputGroup && (
                        <Table className="input-groups__list">
                            {inputGroup?.length > 0 && (
                                <tr>
                                    <th>
                                        <span className="whitelist-sites__name">{getI18nLocaleString(namespacesList.admin, "name")}</span>
                                    </th>
                                    <th>
                                        <span className="whitelist-sites__action-buttons">{getI18nLocaleString(namespacesList.admin, "actionButtons")}</span>
                                    </th>
                                </tr>
                            )}
                            <tbody>
                                {inputGroup.map((inputGroupItem: InputGroupItem) => (
                                    <InputGroupRow
                                        key={inputGroupItem.id}
                                        inputGroupItem={inputGroupItem}
                                        updateInputGroup={this.updateInputGroup}
                                        deleteInputGroup={this.deleteInputGroup}
                                        enabled={enabled}
                                    />
                                ))}
                            </tbody>
                        </Table>
                    )}
                </div>
            </div>
        );
    }

    private createInputGroup = (newInputGroup: InputGroupItem): void => {
        const { spec, onChange, alerts } = this.props;
        if (newInputGroup.value) {
            const groupExists = this.state.inputGroup.some((groupItem) => groupItem.value === newInputGroup.value);
            if (!groupExists) {
                const validSpec = !spec.validation || spec.validation.test(newInputGroup.value);
                if (validSpec) {
                    const newGroup = [...this.state.inputGroup, newInputGroup];
                    onChange(
                        newGroup.map((groupItem) => groupItem.value),
                        spec
                    );
                    alerts?.push({ color: "success", message: getI18nLocaleString(namespacesList.admin, "inputGroupAdded")?.replace(/\[x]/g, `${newInputGroup.value}`) });
                    this.setState({ inputGroup: newGroup, groupName: "" });
                } else {
                    alerts?.push({ color: "danger", message: getI18nLocaleString(namespacesList.admin, "inputGroupNotValid")?.replace(/\[x]/g, `${newInputGroup.value}`) });
                }
            } else {
                alerts?.push({ color: "warning", message: getI18nLocaleString(namespacesList.admin, "inputGroupAlreadyExists")?.replace(/\[x]/g, `${newInputGroup.value}`) });
            }
        }
    };

    private deleteInputGroup = (inputGroupItem: InputGroupItem): void => {
        const newGroup: InputGroup = this.state.inputGroup.filter((groupItem: InputGroupItem) => groupItem.id !== inputGroupItem.id);
        this.props.onChange(
            newGroup.map((groupItem) => groupItem.value),
            this.props.spec
        );
        this.props.alerts?.push({ color: "success", message: getI18nLocaleString(namespacesList.admin, "inputGroupRemoved")?.replace(/\[x]/g, `${inputGroupItem.value}`) });
        this.setState({ inputGroup: newGroup });
    };

    private updateInputGroup = (id: string, inputGroupValue: string): void => {
        const { spec, onChange, alerts } = this.props;
        if (inputGroupValue) {
            const groupExists = this.state.inputGroup.some((groupItem) => groupItem.value === inputGroupValue);
            if (!groupExists) {
                const validSpec = !spec.validation || spec.validation.test(inputGroupValue);
                if (validSpec) {
                    const newGroup: InputGroup = [...this.state.inputGroup];
                    const targetGroupItem = newGroup.find((groupItem) => groupItem.id === id);
                    const oldValue = targetGroupItem?.value;
                    if (oldValue && targetGroupItem) {
                        targetGroupItem.value = inputGroupValue;
                    }
                    onChange(
                        newGroup.map((groupItem) => groupItem.value),
                        spec
                    );
                    alerts?.push({ color: "success", message: getI18nLocaleString(namespacesList.admin, "inputGroupNotValid")?.replace(/\[x]/g, `${oldValue}`) });
                    this.setState({ inputGroup: newGroup });
                } else {
                    alerts?.push({ color: "danger", message: getI18nLocaleString(namespacesList.admin, "inputGroupNotValid")?.replace(/\[x]/g, `${inputGroupValue}`) });
                }
            } else {
                alerts?.push({ color: "warning", message: getI18nLocaleString(namespacesList.admin, "inputGroupAlreadyExists")?.replace(/\[x]/g, `${inputGroupValue}`) });
            }
        }
    };
}

export const CreatableGroupInput = wrapProps<GenericInputProps<any, any, InputSpecCreatableGroup<any, any>>>(CreatableGroupBase);
