import * as React from "react";

import { SiteGroup, SiteGroupApi, WithId } from "@maxxton/cms-api";
import { getI18nLocaleString, wrapProps } from "../../../i18n";

import { GenericInputProps } from "../input.types";
import { InputSpecSiteGroup } from "../../../form-specs";
import { SelectOption } from "../../../form-specs/formSpec.types";
import { SiteGroupTable } from "./SiteGroupTable";
import { getSiteOptions } from "../../../utils/site.util";
import namespacesList from "../../../i18n/namespaceList";

type SiteGroupProps<S, P extends keyof S> = GenericInputProps<S, P, InputSpecSiteGroup<S, P>>;

interface SiteGroupState {
    siteGroups: Array<SiteGroup & WithId>;
    siteMultiSelectOptions: Array<SelectOption<string>>;
    loading: boolean;
    addNewGroup: boolean;
}

export class SiteGroupBase<S, P extends keyof S> extends React.PureComponent<SiteGroupProps<S, P>, SiteGroupState> {
    constructor(props: SiteGroupProps<S, P>) {
        super(props);
        this.state = {
            siteGroups: [],
            siteMultiSelectOptions: [],
            loading: false,
            addNewGroup: false,
        };
    }

    public async componentDidMount() {
        await this.refreshSiteGroups();
    }

    public render(): JSX.Element | null {
        const { enabled } = this.props;
        const { loading, siteMultiSelectOptions, siteGroups, addNewGroup } = this.state;

        return (
            <div className="generic-form col-12 p-0">
                <div>
                    {!loading && (
                        <SiteGroupTable
                            siteGroups={siteGroups}
                            siteOptions={siteMultiSelectOptions}
                            addNewGroup={addNewGroup}
                            createSiteGroup={this.createSiteGroup}
                            cancelCreateSiteGroup={this.toggleAddNewGroup}
                            updateSiteGroup={this.updateSiteGroup}
                            deleteSiteGroup={this.deleteSiteGroup}
                            enabled={enabled}
                        />
                    )}
                </div>
                <button className="btn btn-primary" disabled={!enabled} onClick={this.toggleAddNewGroup} type="button">
                    {getI18nLocaleString(namespacesList.genericCrud, "addSiteGroup")}
                </button>
            </div>
        );
    }

    private refreshSiteGroups = async (): Promise<void> => {
        this.setState({ loading: true });
        const siteMultiSelectOptions = await getSiteOptions();

        SiteGroupApi.find().then((siteGroupsResult) => {
            this.setState({
                siteGroups: siteGroupsResult,
                siteMultiSelectOptions,
                loading: false,
                addNewGroup: false,
            });
        });
    };

    private createSiteGroup = async (newSiteGroup: SiteGroup): Promise<void> => {
        if (newSiteGroup.name) {
            if (!this.state.siteGroups.some((group: SiteGroup) => group.name === newSiteGroup.name)) {
                await SiteGroupApi.create({ item: newSiteGroup }).then(async () => {
                    await this.refreshSiteGroups();
                });
            }
        }
    };

    private deleteSiteGroup = async (id: string): Promise<void> => {
        await SiteGroupApi.deleteById({ id }).then(async () => {
            await this.refreshSiteGroups();
        });
    };

    private updateSiteGroup = async (id: string, updatedSiteGroup: SiteGroup): Promise<void> => {
        if (updatedSiteGroup.name) {
            await SiteGroupApi.update({ id, item: updatedSiteGroup }).then(async () => {
                await this.refreshSiteGroups();
            });
        }
    };

    private toggleAddNewGroup = (): void => this.setState({ addNewGroup: !this.state.addNewGroup });
}

export const SiteGroupInput = wrapProps<GenericInputProps<any, any, InputSpecSiteGroup<any, any>>>(SiteGroupBase);
