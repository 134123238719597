import * as React from "react";

import type { InputGroup, InputGroupItem } from "./inputGroup.types";
import { conditionalOperators, dynamicFilterList, logicalOperators, transformInputToGroupItem } from "./utils/conditionalSet.util";
import { getI18nLocaleString, wrapProps } from "../../../i18n";

import { ConditionalSetContent } from "@maxxton/cms-api";
import { GenericInputProps } from "../input.types";
import { InputGroupRow } from "./InputGroupRow";
import { InputSpecConditionalSetGroup } from "../../../form-specs";
import { SelectOption } from "../../../form-specs/formSpec.types";
import { Table } from "reactstrap";
import namespacesList from "../../../i18n/namespaceList";

type InputGroupProps<S, P extends keyof S> = GenericInputProps<S, P, InputSpecConditionalSetGroup<S, P>>;

interface InputGroupState {
    inputGroup: InputGroup;
}

export class ConditionalSetGroupBase<S, P extends keyof S> extends React.PureComponent<InputGroupProps<S, P>, InputGroupState> {
    constructor(props: InputGroupProps<S, P>) {
        super(props);
        this.state = {
            inputGroup:
                ((props.value as unknown) as ConditionalSetContent[])?.map((item) =>
                    transformInputToGroupItem(item.logicalOperators, item.dynamicFilter, item.conditionalOperators, item.dynamicFilterData, item.dynamicFilterMultiData)
                ) || [],
        };
    }

    public render(): JSX.Element | null {
        const { enabled } = this.props;
        const { inputGroup } = this.state;

        return (
            <div className="generic-form whitelist-form col-12 p-0">
                <div className="inputGroups-table-wrap">
                    {
                        <Table className="input-groups__list conditional-set">
                            {
                                <tr>
                                    <th>
                                        <span className="input-groups__logicalOperator">{getI18nLocaleString(namespacesList.admin, "if")}</span>
                                    </th>
                                    <th>
                                        <span className="input-groups__dynamicFilter">{getI18nLocaleString(namespacesList.admin, "dynamicFilter")}</span>
                                    </th>
                                    <th>
                                        <span className="input-groups__condition">{getI18nLocaleString(namespacesList.admin, "condition")}</span>
                                    </th>
                                    <th>
                                        <span className="input-groups__dynamicFilterData">{getI18nLocaleString(namespacesList.admin, "dynamicFilterData")}</span>
                                    </th>
                                    <th>
                                        <span className="input-groups__action-buttons">{getI18nLocaleString(namespacesList.admin, "actionButtons")}</span>
                                    </th>
                                </tr>
                            }
                            <tbody>
                                {inputGroup.map((inputGroupItem: InputGroupItem) => (
                                    <InputGroupRow
                                        key={inputGroupItem.id}
                                        inputGroupItem={inputGroupItem}
                                        selectedDynamicFilter={dynamicFilterList.find((options: SelectOption<string>) => inputGroupItem.dynamicFilter === options.value)}
                                        selectedCondition={conditionalOperators.find((options: SelectOption<string>) => inputGroupItem.conditionalOperators === options.value)}
                                        logicalOperators={logicalOperators.find((options: SelectOption<string>) => inputGroupItem.logicalOperators === options.value)}
                                        selectedDynamicFilterData={inputGroupItem.dynamicFilterData}
                                        selectedDynamicFilterMultiData={inputGroupItem.dynamicFilterMultiData}
                                        updateInputGroup={this.updateInputGroup}
                                        deleteInputGroup={this.deleteInputGroup}
                                        enabled={enabled}
                                        inputGroup={inputGroup}
                                    />
                                ))}
                            </tbody>
                        </Table>
                    }
                </div>
                <button className="btn btn-primary btn-add-condition" disabled={!enabled} onClick={this.createInputGroup.bind(this, transformInputToGroupItem("", "", ""))} type="button">
                    {getI18nLocaleString(namespacesList.admin, "addNewCondition")}
                </button>
            </div>
        );
    }

    private createInputGroup = (newInputGroup: InputGroupItem): void => {
        const { spec, onChange } = this.props;
        if (newInputGroup) {
            const newGroup = [...this.state.inputGroup, newInputGroup];
            onChange(
                newGroup.map((groupItem) => groupItem),
                spec
            );
            this.setState({ inputGroup: newGroup });
        }
    };

    private deleteInputGroup = (inputGroupItem: InputGroupItem): void => {
        const newGroup: InputGroup = this.state.inputGroup.filter((groupItem: InputGroupItem) => groupItem.id !== inputGroupItem.id);
        this.props.onChange(
            newGroup.map((groupItem) => groupItem),
            this.props.spec
        );
        this.props.alerts?.push({ color: "success", message: getI18nLocaleString(namespacesList.admin, "inputGroupRemoved") });
        this.setState({ inputGroup: newGroup });
    };

    private updateInputGroup = (id: string, updatedInputGroupItem: InputGroupItem): void => {
        const { spec, onChange } = this.props;
        if (updatedInputGroupItem) {
            const groupExists = this.state.inputGroup.some((groupItem) => groupItem.id === id);
            if (groupExists) {
                const newGroup: InputGroup = [...this.state.inputGroup];
                const targetGroupItem = newGroup.find((groupItem) => groupItem.id === id);
                const oldValue = targetGroupItem;
                if (oldValue && targetGroupItem) {
                    targetGroupItem.conditionalOperators = updatedInputGroupItem.conditionalOperators;
                    targetGroupItem.dynamicFilter = updatedInputGroupItem.dynamicFilter;
                    targetGroupItem.dynamicFilterData = updatedInputGroupItem.dynamicFilterData;
                    targetGroupItem.dynamicFilterMultiData = updatedInputGroupItem.dynamicFilterMultiData;
                    targetGroupItem.logicalOperators = updatedInputGroupItem.logicalOperators;
                }
                onChange(
                    newGroup.map((groupItem) => groupItem),
                    spec
                );
                this.setState({ inputGroup: newGroup });
            }
        }
    };
}

export const ConditionalSetGroupInput = wrapProps<GenericInputProps<any, any, InputSpecConditionalSetGroup<any, any>>>(ConditionalSetGroupBase);
