import * as React from "react";

import { SiteGroup, WithId } from "@maxxton/cms-api";
import { getSiteNamesFromSelectOptions, transformInputToSiteGroup } from "../../utils/siteGroup.util";

import { Input } from "reactstrap";
import Select from "../../../multiselect-component";
import { SelectOption } from "../../../../../form-specs/formSpec.types";
import { SiteGroupCrudProps } from "../";
import { getI18nLocaleString } from "../../../../../i18n";
import namespacesList from "../../../../../i18n/namespaceList";

export interface SiteGroupRowProps extends SiteGroupCrudProps {
    siteGroup?: SiteGroup & WithId;
    siteSelectOptions: Array<SelectOption<string>>;
    selectedSites?: Array<SelectOption<string>>;
    addNewSiteGroup?: boolean;
    enabled?: boolean;
}

// eslint-disable-next-line max-lines-per-function
export const SiteGroupRow: React.FunctionComponent<SiteGroupRowProps> = (props: SiteGroupRowProps): React.ReactElement | null => {
    const { siteGroup, siteSelectOptions, addNewSiteGroup, enabled } = props;
    const [groupName, setGroupName] = React.useState<string>(siteGroup?.name || "");
    const [selectedSites, setSelectedSites] = React.useState<Array<SelectOption<string>>>(props.selectedSites || []);
    const [editing, setEditing] = React.useState<boolean>(false);
    // If a new site group must be created, render empty inputs
    if (addNewSiteGroup) {
        return (
            <tr>
                <td className="dk-pl-s">
                    <Input
                        id="newSiteGroup"
                        name="newSiteGroup"
                        type="text"
                        value={groupName}
                        disabled={!enabled}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setGroupName(event.target.value)}
                        placeholder={getI18nLocaleString(namespacesList.genericCrud, "siteGroupNamePlaceholder")}
                    />
                </td>
                <td>
                    <Select
                        name={"newSiteGroup-sites"}
                        value={selectedSites}
                        onChange={(value: Array<SelectOption<string>>) => setSelectedSites(value)}
                        options={siteSelectOptions}
                        clearable={false}
                        multi={true}
                        disabled={!enabled}
                        instanceId="new-site-group-sites"
                    />
                </td>
                <td className="actions">
                    <button className="btn btn-primary" disabled={!enabled} onClick={() => props.createSiteGroup?.(transformInputToSiteGroup({ groupName, selectedSites }))} type="button">
                        <span className="fa fa-check" />
                    </button>
                    <button className="btn btn-danger dk-ml-s" disabled={!enabled} onClick={() => props.cancelCreateSiteGroup?.()} type="button">
                        <span className="fa fa-times" />
                    </button>
                </td>
            </tr>
        );
    }

    // Render an existing site group in edit mode
    if (siteGroup && editing) {
        return (
            <tr>
                <td className="dk-pl-s">
                    <Input
                        disabled={!enabled}
                        id={siteGroup._id}
                        name={siteGroup.name}
                        type="text"
                        value={groupName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setGroupName(event.target.value)}
                    />
                </td>
                <td>
                    <Select
                        name={`${siteGroup.name}-sites`}
                        value={selectedSites}
                        onChange={(value: Array<SelectOption<string>>) => setSelectedSites(value)}
                        options={siteSelectOptions}
                        clearable={false}
                        multi={true}
                        disabled={!enabled}
                        instanceId="site-group-sites"
                    />
                </td>
                <td className="actions">
                    <button
                        className="btn btn-primary"
                        disabled={!enabled}
                        onClick={() => props.updateSiteGroup?.(siteGroup._id, transformInputToSiteGroup({ groupName, selectedSites }))}
                        type="button"
                    >
                        <span className="fa fa-check" />
                    </button>
                    <button className="btn btn-danger dk-ml-s" disabled={!enabled} onClick={() => setEditing(false)} type="button">
                        <span className="fa fa-times" />
                    </button>
                </td>
            </tr>
        );
        // Render an existing site group in read-only mode
    } else if (siteGroup) {
        return (
            <tr>
                <td className="dk-pl-s">{siteGroup.name}</td>
                <td>{getSiteNamesFromSelectOptions(selectedSites).join(", ")}</td>
                <td className="actions">
                    <button className="btn btn-primary" disabled={!enabled} onClick={() => setEditing(true)} type="button">
                        <span className="fa fa-edit" />
                    </button>
                    <button className="btn btn-danger dk-ml-s" disabled={!enabled} onClick={() => props.deleteSiteGroup?.(siteGroup._id)} type="button">
                        <span className="fa fa-trash" />
                    </button>
                </td>
            </tr>
        );
    }
    return null;
};
