import * as React from "react";
import * as moment from "moment";

import { cloneDeep, range } from "lodash";
import { getI18nLocaleString, wrapProps } from "../../../i18n";

import { DATE_FORMAT } from "../../../utils/constants";
import { GenericInputProps } from "../input.types";
import { InputSpecSimple } from "../../../form-specs";
import Select from "../multiselect-component";
import { SelectOption } from "../../../form-specs/formSpec.types";
import namespacesList from "../../../i18n/namespaceList";

type DateSelectInputProps<S, P extends keyof S> = GenericInputProps<S, P, InputSpecSimple<S, P>>;

interface DateSelectInputState {
    selectedDay?: number;
    selectedMonth?: number; // Stores months from 1 to 12, subtract 1 when used as Date or Moment
    selectedYear?: number;
    selectedDate?: moment.Moment;
    maxDate: moment.Moment;
    minDate: moment.Moment | undefined;
}

enum DateInputType {
    day = "day",
    month = "month",
    year = "year",
}

export class DateSelectInputBase<S, P extends keyof S> extends React.PureComponent<DateSelectInputProps<S, P>, DateSelectInputState> {
    constructor(props: DateSelectInputProps<S, P>) {
        super(props);

        this.state = {
            maxDate: props.spec.maxDate ? moment(props.spec.maxDate, DATE_FORMAT.MXTS) : moment().clone(),
            minDate: props.spec.options?.useForFutureDates ? moment().clone() : undefined,
        };
    }

    public componentDidUpdate(prevProps: Readonly<DateSelectInputProps<S, P>>, prevState: Readonly<DateSelectInputState>): void {
        const { value, item } = this.props;
        if (value) {
            this.setState({ selectedDay: +moment(value, DATE_FORMAT.MXTS).date(), selectedMonth: +moment(value, DATE_FORMAT.MXTS).month() + 1, selectedYear: +moment(value, DATE_FORMAT.MXTS).year() });
        }
        if (!Object.keys(item).length) {
            this.setState({ selectedDay: undefined, selectedMonth: undefined, selectedYear: undefined });
        }
    }

    public render(): JSX.Element | null {
        const { selectedDay: day, selectedMonth: month, selectedYear: year } = this.state;
        return (
            <div className="generic-form col-12 p-0 form-group__dates flex">
                <div className="col-4 p-1 d-inline-block">
                    <Select
                        name="numericDateInputDay"
                        value={day}
                        options={this.getDayOptions()}
                        onChange={(selectedDay: SelectOption<number> | null) => this.onDateInputChange({ dateInputType: DateInputType.day, dateInputValue: selectedDay?.value })}
                        placeholder={getI18nLocaleString(namespacesList.admin, "day")}
                        required
                        clearable={false}
                    />
                </div>
                <div className="col-4 p-1 d-inline-block">
                    <Select
                        name="numericDateInputMonth"
                        options={this.getMonthOptions()}
                        value={month}
                        onChange={(selectedMonth: SelectOption<number> | null) => this.onDateInputChange({ dateInputType: DateInputType.month, dateInputValue: selectedMonth?.value })}
                        placeholder={getI18nLocaleString(namespacesList.admin, "month")}
                        required
                        clearable={false}
                    />
                </div>
                <div className="col-4 p-1 d-inline-block">
                    <Select
                        name="numericDateInputYear"
                        value={year}
                        options={this.getYearOptions()}
                        onChange={(selectedYear: SelectOption<number> | null) => this.onDateInputChange({ dateInputType: DateInputType.year, dateInputValue: selectedYear?.value })}
                        placeholder={getI18nLocaleString(namespacesList.admin, "year")}
                        required
                        clearable={false}
                    />
                </div>
            </div>
        );
    }

    // Update the selected day, month or year and then update or create the selected date if possible
    private onDateInputChange = ({ dateInputType, dateInputValue }: { dateInputType: DateInputType; dateInputValue?: number }): void => {
        let selectedDate = cloneDeep(this.state.selectedDate);
        if (selectedDate && !dateInputValue) {
            selectedDate = undefined;
        }

        if (dateInputValue) {
            if (dateInputType === DateInputType.day) {
                selectedDate = this.updateSelectedDay({ selectedDate, newSelectedDay: dateInputValue });
            }

            if (dateInputType === DateInputType.month) {
                selectedDate = this.updateSelectedMonth({ selectedDate, newSelectedMonth: dateInputValue - 1 });
            }

            if (dateInputType === DateInputType.year) {
                selectedDate = this.updateSelectedYear({ selectedDate, newSelectedYear: dateInputValue });
            }
        }

        this.validateDate(selectedDate);
        this.props.onChange(selectedDate, this.props.spec);
    };

    // Update the selected day based on the day input value
    private updateSelectedDay = ({ newSelectedDay, selectedDate }: { newSelectedDay: number; selectedDate?: moment.Moment }): moment.Moment | undefined => {
        const { selectedMonth, selectedYear } = this.state;

        if (selectedDate) {
            selectedDate.set("date", newSelectedDay); // Note, in moment "date" is used to set the "day" of a moment
        } else if (selectedMonth && selectedYear) {
            selectedDate = moment()
                .date(newSelectedDay)
                .month(selectedMonth - 1)
                .year(selectedYear);
        }

        this.setState({
            selectedDate,
            selectedDay: newSelectedDay,
        });

        return selectedDate;
    };

    // Update the selected month based on the month input value.
    private updateSelectedMonth = ({ newSelectedMonth, selectedDate }: { newSelectedMonth: number; selectedDate?: moment.Moment }): moment.Moment | undefined => {
        const { selectedDay, selectedYear } = this.state;

        // Make sure the selected day fits in the days of the selected month
        const selectedDayIsValid: boolean = !!selectedDay && selectedDay <= this.getDaysPerMonth({ month: newSelectedMonth, year: selectedYear });
        if (selectedDayIsValid) {
            if (selectedDate) {
                selectedDate.set("month", newSelectedMonth);
            } else if (selectedDay && selectedYear) {
                selectedDate = moment().date(selectedDay).month(newSelectedMonth).year(selectedYear);
            }
        } else {
            selectedDate = undefined;
        }

        this.setState({
            selectedDate,
            selectedDay: (selectedDayIsValid && selectedDay) || undefined,
            selectedMonth: newSelectedMonth + 1, // We store the selected month from 1-12, not from 0-11, for UX reasons.
        });

        return selectedDate;
    };

    // Update the selected year based on the year input value
    private updateSelectedYear = ({ newSelectedYear, selectedDate }: { newSelectedYear: number; selectedDate?: moment.Moment }): moment.Moment | undefined => {
        const { minDate, maxDate, selectedDay, selectedMonth } = this.state;
        const useForFutureDates = this.props.spec.options?.useForFutureDates;
        const isSelectedYearWithInRange = useForFutureDates && minDate ? minDate.year() === newSelectedYear : maxDate.year() === newSelectedYear;
        const isBeforeMinMonth = useForFutureDates && minDate && selectedMonth ? minDate.month() + 1 >= selectedMonth : false;
        const isBeforeMinDay = minDate && selectedDay ? minDate.date() >= selectedDay : false;
        const isBeforeMaxMonth = maxDate && selectedMonth ? maxDate.month() + 1 <= selectedMonth : false;
        const isBeforeMaxDay = maxDate && selectedDay ? maxDate.date() <= selectedDay : false;

        const isMonthWithInRange = isSelectedYearWithInRange && (isBeforeMinMonth || isBeforeMaxMonth);
        const isDayWithInRange = isSelectedYearWithInRange && (isBeforeMinDay || isBeforeMaxDay);

        if (selectedDate) {
            selectedDate.set("year", newSelectedYear);
        } else if (selectedDay && selectedMonth && !isMonthWithInRange) {
            selectedDate = moment()
                .date(selectedDay)
                .month(selectedMonth - 1)
                .year(newSelectedYear);
        }
        selectedDate = isMonthWithInRange || isDayWithInRange ? undefined : selectedDate;
        this.setState({
            selectedDate,
            selectedYear: newSelectedYear,
            ...(isMonthWithInRange && { selectedMonth: undefined, selectedDay: undefined }),
            ...(isDayWithInRange && { selectedDay: undefined }),
        });

        return selectedDate;
    };

    // Determine the possible days that can be selected in the day input, considering the selected month and maxDate
    private getDayOptions = (): Array<SelectOption<number>> => {
        const { minDate, maxDate, selectedMonth, selectedYear } = this.state;
        const useForFutureDates = this.props.spec.options?.useForFutureDates;
        let minDay = 1;
        let maxDay = 31;

        if (selectedMonth) {
            if (useForFutureDates && minDate && selectedYear === minDate.year() && selectedMonth - 1 === minDate.month()) {
                minDay = minDate.date();
            }
            if (!useForFutureDates && selectedYear === maxDate.year() && selectedMonth - 1 === maxDate.month()) {
                maxDay = maxDate.date();
            } else {
                maxDay = this.getDaysPerMonth({ month: selectedMonth - 1, year: selectedYear });
            }
        }

        return range(minDay, maxDay + 1).map((day: number) => ({
            label: day.toString(),
            value: day,
        }));
    };

    // Determine the possible months that can be selected in the month input, considering the selected year and maxDate
    private getMonthOptions = (): Array<SelectOption<number>> => {
        const { minDate, maxDate, selectedYear } = this.state;
        const useForFutureDates = this.props.spec.options?.useForFutureDates;
        let minMonth = 1;
        let maxMonth = 12;

        if (selectedYear) {
            if (useForFutureDates && minDate && selectedYear === minDate.year()) {
                minMonth = minDate.month() + 1;
            }
            if (!useForFutureDates && selectedYear === maxDate.year()) {
                maxMonth = maxDate.month() + 1;
            }
        }

        return range(minMonth, maxMonth + 1).map((month: number) => ({
            label: moment.months()[month - 1],
            value: month,
        }));
    };

    // Get year options up and until 100 years before the maximum allowed year
    private getYearOptions = (): Array<SelectOption<number>> => {
        const { minDate, maxDate, selectedDay, selectedMonth } = this.state;
        const useForFutureDates = this.props.spec.options?.useForFutureDates;
        let maxYear: number = useForFutureDates && minDate ? minDate.clone().add(10, "years").year() : maxDate.year();
        let minYear: number = useForFutureDates && minDate ? minDate.year() : maxYear - 100;

        // Adjust minYear if the selected date falls before minDate
        if (useForFutureDates && selectedMonth && selectedDay && minDate) {
            const selectedDate = moment({ year: minDate.year(), month: selectedMonth - 1, date: selectedDay });
            if (selectedDate.isBefore(minDate, "day")) {
                minYear = minDate.year();
            }
        }
        if (!useForFutureDates && selectedDay && selectedMonth && (selectedMonth - 1 > maxDate.month() || (selectedMonth - 1 === maxDate.month() && selectedDay > maxDate.date()))) {
            maxYear = maxDate.year();
        }
        return (useForFutureDates ? range(minYear, maxYear + 1) : range(minYear, maxYear + 1).reverse()).map((year: number) => ({
            label: year.toString(),
            value: year,
        }));
    };

    // If there is spec.minDate or spec.maxDate, use a custom validation. These values are set in date.ts (in the async toInputSpec method).
    private validateDate = (selectedDate?: moment.Moment) => {
        const { spec, validate } = this.props;
        let selectedDateIsValid = true;

        if (validate) {
            if (selectedDate && spec.maxDate) {
                const maxDate: moment.Moment = moment(spec.maxDate, DATE_FORMAT.MXTS);
                selectedDateIsValid = selectedDateIsValid && selectedDate.isSameOrBefore(maxDate, "day");
            }

            if (selectedDate && spec.minDate) {
                const minDate: moment.Moment = moment(spec.minDate, DATE_FORMAT.MXTS);
                selectedDateIsValid = selectedDateIsValid && selectedDate.isSameOrAfter(minDate, "day");
            }

            // If the date is not required, but the user filled in a date, it still needs to adhere to the maxDate and minDate settings
            validate(spec.variable as string, spec.required ? !!selectedDate && selectedDateIsValid : !selectedDate || selectedDateIsValid);
        }
    };

    private getDaysPerMonth = ({ month, year }: { month: number; year?: number }): number => {
        if (month && year) {
            return moment().set("month", month).set("year", year).daysInMonth();
        }
        return moment().set("month", month).daysInMonth();
    };
}

export const DateSelectInput = wrapProps<GenericInputProps<any, any, InputSpecSimple<any, any>>>(DateSelectInputBase);
