import * as FontAwesome from "react-fontawesome";
import * as React from "react";

import { Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardTitle, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Close, CloudUpload } from "@mui/icons-material";
import { DocumentType, HeadingType, MxtsApi } from "@maxxton/cms-mxts-api";

import { GenericInputProps } from "../generic-form/input.types";
import { InputSpecDocument } from "../../form-specs";
import ProgressBar from "../ProgressBar";
import { getAdminMxtsEnv } from "../../plugins/mxts";
import { getI18nLocaleString } from "../../i18n";
import { getSelectedDocumentUrl } from "../../plugins/page/button/button.util";
import { isEqual } from "../utils";
import { lazyLoadAllDocuments } from "../../utils/document.util";
import namespaceList from "../../i18n/namespaceList";

interface DocumentPickerState {
    isOpen: boolean;
    selectedDocument: DocumentType | null;
    allDocuments: DocumentType[];
    allDocumentsFetched: boolean;
    documentTypes: HeadingType[];
    selectedDocumentType: HeadingType | null;
    selectedDocumentUrl: string;
}

interface DocumentGroupCardProps {
    group: HeadingType;
    onClick: (group: HeadingType) => void;
}

export const DocumentGroupCard = (props: DocumentGroupCardProps) => {
    const { group, onClick } = props;
    const handleClick = () => onClick(group);
    return (
        <div className="card-wrapper" onClick={handleClick}>
            <Card className="folder-wrapper">
                <div className="card-img-wrapper">
                    <FontAwesome size="lg" name="folder-open" />
                </div>
                <CardBody>
                    <CardTitle tag="h4">{group.name}</CardTitle>
                </CardBody>
            </Card>
        </div>
    );
};

class DocumentPickerBase<E, P extends keyof E> extends React.PureComponent<GenericInputProps<E, P, InputSpecDocument<E, P>>, DocumentPickerState> {
    private controller: AbortController = new AbortController();

    constructor(props: GenericInputProps<E, P, InputSpecDocument<E, P>>) {
        super(props);
        this.state = {
            isOpen: false,
            selectedDocument: props.value ? (props.value as any).document : null,
            allDocuments: [],
            allDocumentsFetched: false,
            documentTypes: [],
            selectedDocumentType: null,
            selectedDocumentUrl: "",
        };
    }

    public componentDidMount() {
        (async () => {
            const selectedDocumentUrl = await getSelectedDocumentUrl(this.props.context, this.state.selectedDocument);
            this.setState({ selectedDocumentUrl });
        })();
    }

    public UNSAFE_componentWillReceiveProps(nextProps: GenericInputProps<E, P, InputSpecDocument<E, P>>) {
        if (nextProps.value && !isEqual(this.props.value, nextProps.value)) {
            this.setState({ selectedDocument: nextProps.value ? (nextProps.value as any).document : null });
        }
    }

    public componentWillUnmount() {
        this.controller.abort();
    }

    public render(): JSX.Element | null {
        const { isOpen, selectedDocument, selectedDocumentUrl } = this.state;
        const { value } = this.props;
        return (
            <div className="doc-select-container">
                <Modal isOpen={isOpen} toggle={this.toggle} className="doc-picker-modal modal-lg">
                    <ModalHeader tag="h4">{getI18nLocaleString(namespaceList.admin, "documentPickerModalTitle")}</ModalHeader>
                    <ModalBody>{this.renderBody()}</ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleDocumentSave} disabled={selectedDocument === null}>
                            <CloudUpload />
                            {getI18nLocaleString(namespaceList.admin, "selectDocument")}
                        </Button>
                        <Button color="secondary" onClick={this.toggle}>
                            <Close />
                            {getI18nLocaleString(namespaceList.admin, "cancel")}
                        </Button>
                    </ModalFooter>
                </Modal>
                {value && (value as any).document && this.renderDocumentCard((value as any).document, false, true)}
                <div className="document-link">{value && (value as any).document && selectedDocument?.publicType && <a href={selectedDocumentUrl}>{selectedDocumentUrl}</a>}</div>
                <Button color="primary" onClick={this.showDocuments}>
                    <FontAwesome name="picture-o" />
                    {getI18nLocaleString(namespaceList.genericCrud, "selectDocument")}
                </Button>
            </div>
        );
    }

    private renderBody = () => {
        const { selectedDocument, allDocuments, allDocumentsFetched, documentTypes, selectedDocumentType } = this.state;
        return (
            <div className="document-picker-body">
                <div className="breadcrumb-search-section">
                    <Breadcrumb>
                        <BreadcrumbItem active>
                            <span className="doc-breadcrumbs" onClick={this.handleBreadcrumbClick}>
                                {"root"}
                            </span>
                        </BreadcrumbItem>
                        <BreadcrumbItem>{selectedDocumentType?.name}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                {!selectedDocumentType?.name ? (
                    <div className="doc-picker-main">
                        <div className="doc-picker-container">
                            <div className="doc-panel">
                                <div className="doc-picker-cards single-image">
                                    {documentTypes.map((group, index) => (
                                        <DocumentGroupCard key={`group-${index}`} group={group} onClick={this.handleGroupClick} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="doc-selector-wrap">
                        {allDocuments.reduce((acc: JSX.Element[], document: DocumentType) => {
                            if (document.headingid === selectedDocumentType.headingId) {
                                const isSelected = selectedDocument ? selectedDocument.fileId === document.fileId : false;
                                acc.push(this.renderDocumentCard(document, isSelected));
                            }
                            return acc;
                        }, [])}
                        {!allDocumentsFetched && <ProgressBar infinite />}
                    </div>
                )}
            </div>
        );
    };

    private renderDocumentCard = (document: DocumentType, applySelected = false, isRemovable = false) => {
        const extension = this.getExtension(document);
        return (
            <div className={`doc-selector ${applySelected ? "selected" : ""}`} onClick={this.handleSelectDocument.bind(this, document)} key={document.fileId}>
                {isRemovable && <FontAwesome onClick={this.removeDocument} name="times" />}
                {extension === "pdf" && <FontAwesome name="file-pdf-o" />}
                {extension === "txt" && <FontAwesome name="file-text-o" />}
                {(extension === "docx" || extension === "doc") && <FontAwesome name="file-word-o" />}
                {(extension === "xlsx" || extension === "xls") && <FontAwesome name="file-excel-o" />}
                <div className="doc-name">{document.fileName}</div>
            </div>
        );
    };

    private removeDocument = () => {
        const { onChange, spec } = this.props;
        onChange({ document: null }, spec);
    };

    private handleGroupClick = (group: HeadingType): void => {
        this.setState({ selectedDocumentType: group });
    };

    private handleBreadcrumbClick = () => {
        this.setState({ selectedDocumentType: null });
    };

    private getExtension = (document: DocumentType) => {
        const splitName = document ? document.fileName.split(".") : [];
        return splitName[splitName.length - 1];
    };

    private getAllDocuments = () => {
        getAdminMxtsEnv().then(async (env) => {
            const mxtsApi = this.props.context?.mxtsApi || MxtsApi;
            const headings: HeadingType[] = (await mxtsApi.getHeadings(env, {})).filter((heading) => heading.name.includes("Webmanager") || heading.name === "public");
            headings.forEach((heading) => lazyLoadAllDocuments({ mxtsApi, env, headingId: heading.headingId, callback: this.setAllDocuments, abortSignal: this.controller.signal }));
        });
    };

    private getAllDocumentTypes = () => {
        getAdminMxtsEnv().then(async (env) => {
            const mxtsApi = this.props.context?.mxtsApi || MxtsApi;
            const headings: HeadingType[] = (await mxtsApi.getHeadings(env, {})).filter((item) => item.name.includes("Webmanager") || item.name === "public");
            this.setState({ documentTypes: headings });
        });
    };

    private setAllDocuments = ({ documents, done }: { documents: DocumentType[]; done: boolean }) => {
        this.setState(({ allDocuments: prevDocuments }) => ({ allDocuments: [...prevDocuments, ...documents], allDocumentsFetched: done }));
    };

    private toggle = () => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen });
    };

    private showDocuments = () => {
        const { allDocumentsFetched } = this.state;
        this.toggle();
        this.getAllDocumentTypes();
        if (!allDocumentsFetched) {
            this.getAllDocuments();
        }
    };

    private handleDocumentSave = () => {
        const { onChange, spec } = this.props;
        const { selectedDocument } = this.state;
        const value = { document: selectedDocument };
        onChange(value, spec);
        this.toggle();
    };

    private handleSelectDocument = async (document: DocumentType) => {
        const selectedDocumentUrl = await getSelectedDocumentUrl(this.props.context, document);
        this.setState({ selectedDocument: document, selectedDocumentUrl });
    };
}

export const DocumentPicker = DocumentPickerBase;
