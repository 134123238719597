import { SelectOption } from "../../../../form-specs/formSpec.types";
import { SiteGroup } from "@maxxton/cms-api";
import { getI18nLocaleStringFromParams } from "../../../../i18n";

export const transformInputToSiteGroup = ({ groupName, selectedSites }: { groupName: string; selectedSites: Array<SelectOption<string>> }): SiteGroup => ({
    name: groupName,
    sites: getSiteIdsFromSelectOptions(selectedSites),
});

export const getSiteNamesFromSelectOptions = (options: Array<SelectOption<string>>): string[] => options.map((option) => option.label && getI18nLocaleStringFromParams(option.label));
export const getSiteIdsFromSelectOptions = (options: Array<SelectOption<string>>): string[] => options.map((option) => option.value);
